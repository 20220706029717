import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SurveyStatusComponent } from '@player/shared/components/survey-status/survey-status.component';

@Component({
  selector: 'identity-required',
  templateUrl: './identity-required-view.component.html',
  styleUrls: ['./identity-required-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SurveyStatusComponent],
})
export class IdentityRequiredView {}
