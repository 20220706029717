/**
 * Slider 1 abstract component.
 *
 * @unstable
 */

import { Observable, Subject } from 'rxjs';
import { take, takeUntil, takeWhile, throttleTime } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { getOS } from '@player/shared/models/player.model';
import { Question } from '@player/+survey/question/question-base';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { Questions } from '@shared/enums/questions.enum';
import { raceFromEvent } from '@shared/operators/race-from-event.operator';
import { QuestionData, SliderValuesData } from '@shared/models/survey.model';

@Directive()
export abstract class SliderAbstractComponent<T> extends Question<T> implements OnInit, AfterViewInit {
  abstract readonly knobs: number[];

  public answered: boolean = false;
  public dragging: boolean = false;
  public onThumb: boolean = false;

  public currentSmiley: string[] = [];
  public currentValue: number[] = [];
  public smileys: string[] = [];
  public percentLeft: number[] = [50, 50];
  public decimals: number = 0;

  public sliding: boolean[] = [false, false];
  public hasTouched: boolean[] = [false, false];
  public thumbHover: boolean[] = [false, false];

  public groupAnswers?: Observable<{ no: number; val: string }[]>;

  public startPercent: number;
  public thumbRadius: number;

  public get isRange(): boolean {
    return this.questionType === Questions.SLIDER_1R;
  }

  public get isSlider(): boolean {
    return this.questionType === Questions.SLIDER_1D;
  }

  public get isVolume(): boolean {
    return !!this.questionData && this.questionData.type === Questions.SLIDER_1V;
  }

  public get data(): QuestionData {
    return this.groupData && Questions.isNps(this.questionData)
      ? this.questionData || new QuestionData()
      : this.groupData || this.questionData || new QuestionData();
  }

  public get inScoredGroup(): boolean {
    return !!this.groupData && this.groupData.type === Questions.GROUP_SCORED;
  }

  protected overlapOffset: number;
  protected abstract readonly questionType: Questions;
  protected abstract readonly cdRef: ChangeDetectorRef;

  protected indexUpdate: Subject<number> = new Subject<number>();

  private blockReset: number = 0;
  private sliderRect: ClientRect;

  @Input() no: number;
  @Input() inList: boolean = false;
  @Input() showInputs: boolean = false;
  @Input() groupData: QuestionData;
  @Input() disableCheck: boolean = false;
  @Input() view?: string;

  @Input()
  set answerValue(value: T | null) {
    this._answerValue = value;
    this.answered = !!value;
    this.currentValue = [];

    if (value != null && value.toString() !== '') {
      let newValue = value as T | T[];

      if (!Array.isArray(value)) {
        newValue = [value];
      }

      this.currentValue = (newValue as T[])
        .filter((val) => val != null)
        .map((val) => Number.parseFloat(val as unknown as string));
    } else {
      this.initValue();
    }

    this.hasTouched = [!!value && value[0] != null, !!value && value[1] != null];
  }

  get answerValue(): T | null {
    return this._answerValue;
  }

  private _answerValue: T | null = null;

  @Output() dragChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('trackInner') trackInner: ElementRef;
  @ViewChild('sliderArea') sliderArea: ElementRef;

  @ViewChildren('valueText') valueTexts: QueryList<ElementRef>;
  @ViewChildren('thumbKnob', { read: ElementRef }) thumbKnobs: QueryList<ElementRef>;

  protected constructor(ss: SurveyStore) {
    super(ss);
  }

  ngOnInit(): void {
    this.indexUpdate.pipe(throttleTime(50), takeUntil(this.destroy)).subscribe((index: number) => {
      this.onIndexUpdate(index);
    });

    const { sliderLabels, sliderSmileys } = this.data;

    if (sliderSmileys) {
      this.smileys = sliderLabels.smileys || [];

      if (!Array.isArray(this.smileys)) {
        this.smileys = Object.values(this.smileys);
      }
    }

    this.setDecimals((this.data && this.data.sliderValues && this.data.sliderValues.step) || 1);
    this.initValue();
  }

  ngAfterViewInit(): void {
    this.valueTexts.changes.subscribe(() => {
      this.valueTexts.forEach((item: ElementRef, index: number) => this.createMouseDownSub(item, index));
    });

    this.thumbKnobs.changes.subscribe(() => {
      this.setThumbKnobSize();
    });

    this.createMouseDownSub(this.sliderArea);
    this.setThumbKnobSize();
  }

  onTouchMove(event: UIEvent): void {
    if (getOS() === 'ios') {
      event.preventDefault();
    }
  }

  getCurrentValue(value: number) {
    return value?.toFixed(this.decimals);
  }

  getCursorLineStyle(): { [key: string]: string } {
    const style: { [key: string]: string } = {
      width: Math.abs(this.percentLeft[0] - this.startPercent) + '%',
    };

    if (this.percentLeft[0] > this.startPercent) {
      style.left = `${this.startPercent}%`;
    } else {
      style.right = `${100 - this.startPercent}%`;
    }

    return style;
  }

  protected abstract setCurrentValue(currentValue: T): void;

  protected abstract emitAnswerChange(currentAnswer: number[]): void;

  private initValue(): void {
    if (!this.currentValue.length) {
      const { min, max, initial } = this.getMinMaxInitial();
      const range: number = Math.abs(max - min);
      const initValue = min + initial * range;

      if (this.isRange) {
        this.currentValue = [initValue - range * 0.19, initValue + range * 0.19];
      } else {
        this.currentValue = [initValue, initValue];
      }
    }

    this.percentLeft = this.currentValue.map((value) => this.getLeftFromAnswer(value));

    this.percentLeft.forEach((left, index) => this.setSmiley(index));
  }

  private setSmiley(index: number): void {
    const length = this.smileys.length;

    if (this.data.sliderSmileys && this.smileys.length) {
      this.currentSmiley[index] =
        this.smileys[Math.min(Math.floor((length / 100) * this.percentLeft[index]), length - 1)];
    }
  }

  private createMouseDownSub(element: ElementRef, index?: number): void {
    this.mergeEvents(element.nativeElement, ['touchstart', 'mousedown']).subscribe(
      (event: TouchEvent | MouseEvent | PointerEvent) => {
        if (this.inList) {
          this.dragChange.emit(true);
        }

        const x: number = this.isTouchEvent(event) ? event.touches[0].clientX : event.clientX;
        let i: number = index;

        if (i === undefined) {
          i = this.getIndexFromPosition(x);
        }

        if (!this.sliding[i]) {
          const offset: number = this.getOffsetFromEvent(event);
          this.onSlideStart(x, i, offset);
        }
      },
    );
  }

  private mergeEvents(element: Node, events: (keyof HTMLElementEventMap)[]): Observable<UIEvent> {
    return raceFromEvent(element, events).pipe(takeUntil(this.destroy));
  }

  private getLeftFromAnswer(answer: number): number {
    const { min, max } = this.getMinMaxInitial();

    return Math.max(0, Math.min(100, ((answer - min) / Math.abs(max - min)) * 100));
  }

  protected getMinMaxInitial(): { min: number; max: number; initial: number; step: number } {
    const data = this.data;

    const values: Partial<SliderValuesData> = data?.sliderValues || {};
    const defaultMax = Questions.isNps(data) ? 10 : 100;

    const range = (values.max || defaultMax) - (values.min || 0);
    const step = range > 100 ? Math.round(range / 1000) * 10 : range > 10 ? 1 : 0.1;
    const initial = this.isVolume ? 0 : values.initial == null || this.isRange ? 0.5 : values.initial;

    return {
      min: values.min == null ? 0 : values.min,
      max: values.max == null ? defaultMax : values.max,
      initial: Math.max(0, Math.min(1, initial)),
      step: values.step == null || values.step === 0 ? step : values.step,
    };
  }

  private getIndexFromPosition(x: number): number {
    let index: number = this.thumbHover.findIndex((th) => th);
    let distance: number;

    if (index !== -1) {
      return index;
    } else {
      index = 0;
    }

    if (this.knobs.length === 1) {
      return index;
    }

    this.thumbKnobs
      .map((tk) => tk.nativeElement)
      .forEach((tk: HTMLElement, newIndex: number) => {
        const tkRect: ClientRect = tk.getBoundingClientRect();

        const left: number = tkRect.left;
        const right: number = left + tkRect.width;

        const newDistance: number = x >= left && x <= right ? 0 : Math.min(Math.abs(left - x), Math.abs(right - x));

        if (distance === undefined || newDistance <= distance) {
          index = newIndex;
          distance = newDistance;
        }
      });

    return index;
  }

  private getOffsetFromEvent(event: TouchEvent | MouseEvent | PointerEvent): number {
    this.onThumb = false;
    let offset: number = 0;

    if (!this.isTouchEvent(event) && event.target instanceof HTMLElement) {
      const thumbs = this.thumbKnobs.map((tk) => tk.nativeElement);
      this.onThumb = thumbs.includes(event.target) || thumbs.includes(event.target.parentElement);

      if (this.onThumb) {
        const radius: number = event.target.offsetWidth / 2;
        offset = radius - event.offsetX;
      }
    }

    return offset;
  }

  private setThumbKnobSize(): void {
    if (!this.thumbRadius) {
      this.thumbRadius = this.thumbKnobs.first.nativeElement.offsetHeight / 2;

      this.cdRef.detectChanges();
    }
  }

  private onSlideStart(initX: number, index: number, offset: number): void {
    if (this.sliding.every((s) => !s)) {
      this.sliderRect = this.trackInner.nativeElement.getBoundingClientRect();
      this.sliding[index] = true;

      this.mergeEvents(document, ['mousemove', 'touchmove'])
        .pipe(takeWhile(() => this.sliding[index]))
        .subscribe((event: TouchEvent | MouseEvent | PointerEvent) => {
          const x: number = this.isTouchEvent(event) ? event.touches[0].clientX : event.clientX;
          this.onSlideMove(x, index, offset);
        });

      this.mergeEvents(document, ['mouseup', 'touchend'])
        .pipe(take(1))
        .subscribe(() => this.onSlideEnd(index));

      this.onSlideMove(initX, index, offset);
      this.dragging = false;
    }
  }

  private onSlideMove(newX: number, index: number, offset: number): void {
    const { step, min, max } = this.getMinMaxInitial();
    const range: number = max - min;

    this.setDecimals(step);

    let position: number = (newX + offset - this.sliderRect.left) / this.sliderRect.width;
    position = Math.max(0, Math.min(1, position));

    this.percentLeft[index] = position * 100;

    const curVal = Math.min(max, Math.max(min, Math.round((range * position + min) / step) * step));
    const calcDec = Math.pow(10, this.decimals);
    this.currentValue[index] = Math.round(curVal * calcDec) / calcDec;

    this.setSmiley(index);

    this.dragging = true;

    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  private onSlideEnd(index: number): void {
    if (this.inList) {
      this.dragChange.emit(false);
    }

    this.sliding[index] = false;

    this.indexUpdate.next(index);

    if (this.dragging || !this.onThumb || this.answerValue == null || this.blockReset) {
      window.clearTimeout(this.blockReset);
      this.blockReset = window.setTimeout(() => (this.blockReset = 0), 750);
      if (this.isRange) {
        this.percentLeft = [...this.percentLeft.sort()];
      }
      this.dragging = false;

      if (!Questions.isNps(this.data)) {
        this.percentLeft = this.currentValue.map((value) => this.getLeftFromAnswer(value));
      }
    } else {
      this.setCurrentValue(null);
      this.answered = false;

      this.knobs.forEach((k, i) => {
        this.hasTouched[i] = false;
        this.thumbHover[i] = false;
      });

      this.initValue();
      this.emitAnswerChange([]);
    }
  }

  private onIndexUpdate(index: number): void {
    this.setSmiley(index);
    this.hasTouched[index] = true;

    this.emitAnswerChange(this.currentValue);
  }

  private setDecimals(step: number): void {
    const afterDot = step.toString().split(/[^\d]/)[1];
    this.decimals = (afterDot && afterDot.length) || 0;
  }

  isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
  }
}
