/**
 * Guard for the deleted state.
 *
 * @stable
 */

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class DeletedState {
  constructor(
    readonly router: Router,
    readonly route: ActivatedRoute,
  ) {}

  canActivate(): boolean {
    if (
      (!window.ZEF || !window.ZEF.release) &&
      window.location.host.indexOf('192.168.') !== 0 &&
      window.location.host.indexOf('localhost') !== 0
    ) {
      this.router.navigate(['/deleted']);

      return false;
    }

    return true;
  }
}
