import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Question } from '@player/+survey/question/question-base';

const minHeight = 52;
const borderOffset = 4;

@Component({
  selector: 'input-other',
  templateUrl: './input-other.component.html',
  styleUrls: ['./input-other.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputOther extends Question implements AfterViewInit, OnDestroy {
  @Output()
  readonly addOther: EventEmitter<void> = new EventEmitter();

  @Output()
  readonly removeOther: EventEmitter<void> = new EventEmitter();

  @Input()
  waitFocus: number = 0;

  @HostBinding('style.height.px')
  height?: number;

  @ViewChild('input')
  inputRef?: ElementRef<HTMLInputElement>;

  iconTop?: number;

  private destroyed = false;

  constructor(ss: SurveyStore) {
    super(ss);
  }

  ngAfterViewInit(): void {
    this.updateHeight();

    setTimeout(() => {
      if (this.inputField) {
        this.inputField.nativeElement.focus();
      }
    }, this.waitFocus);
  }

  ngOnDestroy(): void {
    if (this.inputRef) {
      this.selectAnswer(this.inputRef.nativeElement.value);
    }

    super.ngOnDestroy();
    this.destroyed = true;
  }

  selectAnswer(value: string, removeOnEmpty: boolean = true) {
    if (value !== '') {
      this.answer = value;
      this.isAnswered = true;
      this.submitAnswer(`other=${value}`);
    } else if (removeOnEmpty) {
      this.isAnswered = false;
      this.removeOther.emit();
      this.submitAnswer(null);
    }
  }

  updateHeight() {
    if (!this.inputField) {
      return null;
    }

    const textarea = this.inputField.nativeElement;
    textarea.style.height = `auto`;

    const height =
      (textarea.value && 0 < textarea.value.length ? Math.max(minHeight, textarea.scrollHeight) : minHeight) +
      borderOffset;

    textarea.style.height = `${height}px`;

    this.height = height;
    this.iconTop = (height - 32) / 2 - borderOffset * 2 - 1;
  }
}
