<div
  class="z-fx"
  *ngIf="{ step: (step$ | async), colors: (ss.colors | async), isMobile: (ss.isMobile | async) } as asyncData"
  [ngSwitch]="asyncData.step"
>
  <ng-container *ngSwitchCase="Step.RATING">
    <div class="z-fx z-fx-grow z-fx-gap-16 z-fx-center-center">
      <strong class="z-texts-paragraph" [style.color]="asyncData.colors.text">
        {{ ratingQuestionText | translateText: 'zefSurveyUserRatingQuestion' }}
      </strong>
      <div class="z-fx">
        <button
          class="button-small"
          [style.background-color]="asyncData.colors.primary"
          [style.color]="asyncData.colors.primaryParity"
          (click)="rateDown()"
        >
          <mat-icon class="zef-icon-large">player_thumbs_down</mat-icon>
        </button>
        <button
          class="button-small"
          [style.background-color]="asyncData.colors.primary"
          [style.color]="asyncData.colors.primaryParity"
          (click)="rateUp()"
        >
          <mat-icon class="zef-icon-large">player_thumbs_up</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="Step.WAIT_UP">
    <button
      class="button-large thumbs-up"
      [style.background-color]="asyncData.colors.primary50"
      (click)="step$.next(Step.RATING)"
    >
      <div class="button-foreground" [style.background-color]="asyncData.colors.primary"></div>
      <mat-icon class="zef-icon-large" [style.color]="asyncData.colors.primaryParity">player_thumbs_up</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="Step.WAIT_DOWN">
    <button
      class="button-large thumbs-down"
      [style.background-color]="asyncData.colors.primary50"
      (click)="step$.next(Step.RATING)"
    >
      <div class="button-foreground" [style.background-color]="asyncData.colors.primary"></div>
      <mat-icon class="zef-icon-large" [style.color]="asyncData.colors.primaryParity">player_thumbs_down</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="Step.EMAIL_INPUT">
    <div class="z-fx-col z-fx-grow z-fx-gap-16">
      <div #el class="z-fx" [ngClass]="alignmentClass(el)">
        <strong class="z-texts-paragraph" [style.color]="asyncData.colors.text">
          {{ emailQuestionText | translateText: 'zefSurveyUserRatingEmailQuestion' }}
        </strong>

        <mat-icon
          class="zef-icon-large"
          [class.opacity-0]="input.value"
          fontSet="zefplayer"
          style="cursor: pointer"
          [style.color]="(ss.colors | async).text80"
          (click)="submit.next(undefined)"
        >
          close
        </mat-icon>
      </div>

      <div class="z-input-wrapper">
        <mat-icon class="z-type-icon zef-icon-large" [style.color]="asyncData.colors.primary">social_email</mat-icon>

        <input
          class="z-input"
          type="email"
          maxLength="1500000"
          #input
          [value]="email"
          [style.border-color]="isValid ? asyncData.colors.primary : '#E20046'"
          [style.color]="asyncData.colors.text80"
          [style.background-color]="asyncData.colors.mood20"
          [placeholder]="
            asyncData.isMobile
              ? (placeholderMobileText | translateText: 'zefSurveyUserRatingEmailPlaceholderMobile')
              : (placeholderDesktopText | translateText: 'zefSurveyUserRatingEmailPlaceholderDesktop')
          "
          (keyup.enter)="isValidEmail ? submitEmail(input.value) : checkValidity(input.value)"
          (focus)="resetValidity(input.value)"
          (blur)="checkValidity(input.value)"
          (input)="updateValidity(input.value)"
        />

        <span *ngIf="!isValid" class="z-error-text">{{ emailErrorText | translateText: 'inputEmailErrorText' }}</span>

        <button
          *ngIf="isValidEmail"
          class="button-submit"
          [style.background-color]="asyncData.colors.button"
          [style.color]="asyncData.colors.buttonContrast"
          (click)="submitEmail(input.value)"
        >
          Ok
        </button>
      </div>

      <a class="funnel-zeffi-link" href="https://zef.fi" target="_blank">
        <mat-icon class="zeffi_wordmark" [style.color]="asyncData.colors.text50">zeffi_wordmark</mat-icon>
      </a>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="Step.EMAIL_CONFIRM">
    <div class="z-fx z-fx-grow z-fx-gap-16" [ngClass]="alignmentClass(el)">
      <mat-icon class="zef-icon-large" [style.color]="asyncData.colors.primary">bullet_check</mat-icon>
      <strong class="z-fx-grow z-texts-paragraph" [style.color]="asyncData.colors.text">
        {{ emailConfirmationText | translateText: 'zefSurveyUserRatingConfirmationText' }}
      </strong>
      <button class="button-icon-transparent" (click)="submit.next(null)">
        <mat-icon class="zef-icon-large" [style.color]="asyncData.colors.text">close</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
