/**
 * Slider 1D component with labels and values showing.
 *
 * @unstable
 */

import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

import { SliderAbstractComponent } from '@player/+survey/question/slider-abstract/slider-abstract.component';

import { Questions } from '@shared/enums/questions.enum';
import { QuestionData, SliderValuesData } from '@shared/models/survey.model';
import { SurveyStore } from '@player/shared/services/survey-store.service';

@Directive()
export abstract class SliderNpsBaseComponent extends SliderAbstractComponent<string> implements OnInit, OnChanges {
  readonly knobs: number[] = [0];

  protected readonly questionType: Questions = Questions.SLIDER_NPS;

  @Input() showInput: boolean = false;

  constructor(
    readonly cdRef: ChangeDetectorRef,
    ss: SurveyStore,
  ) {
    super(ss);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const dataChanges: SimpleChange = changes.questionData || changes.groupData;

    if (dataChanges) {
      const current: QuestionData = dataChanges.currentValue || null;

      if (current) {
        current.sliderValues = current.sliderValues || ({} as SliderValuesData);
      }

      this.startPercent = (!current || current.sliderValues.initial == null ? 0.5 : current.sliderValues.initial) * 100;

      this.overlapOffset = undefined;

      this.cdRef.detectChanges();
    }
  }

  protected getMinMaxInitial(): { min: number; max: number; initial: number; step: number } {
    return {
      min: -0.5,
      max: 10.4,
      initial: 0.5,
      step: 0.1,
    };
  }

  getCurrentValue(value: number) {
    return `${Math.round(+value)}`;
  }

  getCursorLineStyle(): { [key: string]: string } {
    const style: { [key: string]: string } = {
      width: Math.abs(this.percentLeft[0] - this.startPercent) + '%',
    };

    if (this.percentLeft[0] > this.startPercent) {
      style.left = `${this.startPercent}%`;
    } else {
      style.right = `${100 - this.startPercent}%`;
    }

    return style;
  }

  protected setCurrentValue(currentValue: string): void {
    // const { min, max } = this.getMinMaxInitial();
    const min = -0.5;
    const max = 10.5;

    let value: number = parseFloat(currentValue);

    value = isNaN(value) ? min : Math.max(min, Math.min(max, value));

    this.currentValue = currentValue ? [value] : [];
  }

  protected emitAnswerChange(currentAnswer: number[]): void {
    const [first] = currentAnswer;
    this.answerChange.emit(first != null ? first.toString() : null);

    if (first != null) {
      this.answerReady.emit();
    }
  }
}
