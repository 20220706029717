import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { ViewState } from '@shared/models/survey.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { inIframe } from '@shared/utilities/browser.utilities';
import { NgScrollbar } from 'ngx-scrollbar';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { DIRECTION_VERTICAL } from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
class HammerConfig extends HammerGestureConfig {}

@Directive({
  selector: '[surveyScroller]',
  providers: [LifecycleHooks, { provide: HammerGestureConfig, useClass: HammerConfig }],
})
export class SurveyScroller implements OnInit, AfterViewInit, OnChanges {
  @Input() disabled = false;
  @Input() touchScreen = false;
  @Input() disabledInitialFocus?: boolean;

  @Output() scrollDown = new EventEmitter<void>();
  @Output() scrollUp = new EventEmitter<void>();

  private verticalSwipe = new Subject<any>();

  constructor(
    private renderer: Renderer2,
    private ss: SurveyStore,
    private hgc: HammerGestureConfig,
    private elRef: ElementRef<HTMLElement>,
    private lh: LifecycleHooks,
    private nz: NgZone,
    @Optional() private sb?: NgScrollbar,
  ) {}

  ngOnInit(): void {
    this.ss.playerDimensions.subscribe(() => this.sb.update());
    this.elRef.nativeElement.tabIndex = -1;

    if (!inIframe() && !this.disabledInitialFocus) {
      this.elRef.nativeElement.focus();
    }

    if (this.touchScreen) {
      this.hgc.overrides = {
        swipe: {
          direction: DIRECTION_VERTICAL,
          velocity: 0.4,
        },
        pan: {
          direction: DIRECTION_VERTICAL,
          threshold: 0,
        },
      };

      this.hgc.events = ['swipe', 'pan'];

      this.hgc.options = { cssProps: { userSelect: 'auto' } };

      this.nz.runOutsideAngular(() => {
        const hammer = this.hgc.buildHammer(this.elRef.nativeElement);
        hammer.on('swipeup', (event) => {
          const { scrolledBottom } = this.isScrollReached();

          if (scrolledBottom) {
            this.nz.run(() => this.scrollDown.emit());
          } else {
            this.verticalSwipe.next(event);
          }
        });
        hammer.on('swipedown', (event) => {
          const { scrolledTop } = this.isScrollReached();

          if (scrolledTop) {
            this.nz.run(() => this.scrollUp.emit());
          } else {
            this.verticalSwipe.next(event);
          }
        });

        let delta = 0;

        hammer.on('panstart', () => (delta = 0));

        hammer.on('panmove', (event) => {
          this.sb.scrollTo({ duration: 0, top: this.sb.viewport.scrollTop - (event.deltaY - delta) });
          delta = event.deltaY;
        });
      });
    }
  }

  ngOnChanges({ disabled }: SimpleChanges) {
    if (disabled && this.sb) {
      const { currentValue, firstChange } = disabled;
      this.checkScrollbar(firstChange || currentValue);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.checkScrollbar(), 10);

    this.nz.runOutsideAngular(() => {
      merge(
        merge(
          fromEvent<WheelEvent>(this.elRef.nativeElement, 'wheel', { passive: true, capture: false }).pipe(
            map(({ timeStamp, deltaY, deltaMode }) => ({ timeStamp, delta: deltaY, deltaMode })),
            startWith({ timeStamp: 0, delta: 0, deltaMode: WheelEvent.DOM_DELTA_PIXEL }),
            pairwise(),
            filter((events) => !this.checkElastic(events)),
            // tap(console.warn),
            map(([, newEvent]) => (newEvent.delta > 0 ? 1 : newEvent.delta < 0 ? -1 : 0)),
          ),
          this.verticalSwipe.pipe(
            map((event) => {
              const isScrollable = Boolean(this.ss.playerDimensions.value.scrollable);
              if (isScrollable) {
                const { distance, type, overallVelocityY } = event;
                const { scrollTop } = this.sb.viewport;

                const delta = distance * Math.abs(overallVelocityY);
                const top = scrollTop + (type === 'swipedown' ? -1 : 1) * delta;
                const duration = 250;

                if (!this.disabled) {
                  this.sb.scrollTo({ duration, top });
                }
              }

              return event.type === 'swipeup' ? 1 : -1;
            }),
          ),
        ).pipe(
          map((delta) => {
            const isScrollable = Boolean(this.ss.playerDimensions.value.scrollable);
            if (!isScrollable) {
              return delta;
            }

            const { scrolledTop, scrolledBottom } = this.isScrollReached();

            if (scrolledTop && delta < 0) {
              // scrolled to top and going to previous
              return -1;
            } else if (scrolledBottom && delta > 0) {
              // scrolled to bottom and going to next
              if (!this.disabled) {
                this.sb.scrollTo({ duration: 10, top: 0 });
              }
              return 1;
            } else {
              // scrolling content; don't change active index
              return 0;
            }
          }),
        ),
        fromEvent<KeyboardEvent>(this.elRef.nativeElement, 'keydown', { passive: true, capture: false }).pipe(
          map(this.checkKeyboard),
        ),
      )
        .pipe(
          debounceTime(1),
          // tap(console.warn),
          filter((dir) => dir !== 0 && !this.disabled),
          takeUntil(this.lh.destroy),
        )
        .subscribe((dir: 1 | -1) => {
          if (dir === -1) {
            this.nz.run(() => this.scrollUp.emit());
          } else if (dir === 1) {
            this.nz.run(() => this.scrollDown.emit());
          }
        });
    });
  }

  private isScrollReached(): { scrolledTop: boolean; scrolledBottom: boolean } {
    const { clientHeight, scrollHeight, scrollTop } = this.sb.viewport;

    return {
      scrolledTop: !scrollTop,
      scrolledBottom: scrollHeight - clientHeight === Math.round(scrollTop),
    };
  }

  private checkElastic = ([prevEvent, newEvent]) => {
    if (newEvent.timeStamp - prevEvent.timeStamp > 100) {
      return false;
    }

    if (newEvent.deltaMode !== WheelEvent.DOM_DELTA_PIXEL) {
      return false;
    }

    const absNewDelta = Math.abs(newEvent.delta);
    const absPrevDelta = Math.abs(prevEvent.delta);

    if (absNewDelta < 5 || absPrevDelta < 5) {
      return true;
    }

    if (prevEvent.delta * newEvent.delta < 0) {
      return false;
    }

    if (absPrevDelta > absNewDelta) {
      return true;
    }

    return absNewDelta - absPrevDelta <= 5 || (absNewDelta - absPrevDelta) / absNewDelta < 0.25;
  };

  private checkKeyboard = (event: KeyboardEvent) => {
    if (!(event.target instanceof HTMLElement)) {
      return 0;
    }

    if (['TEXTAREA', 'INPUT'].includes(event.target.nodeName)) {
      return 0;
    }

    if (event.key === ' ' || event.key === 'Spacebar') {
      return ['BUTTON', 'A'].includes(event.target.nodeName) ? 0 : 1;
    }

    if (['ArrowDown', 'PageDown'].includes(event.key)) {
      return 1;
    }

    if (['ArrowUp', 'PageUp'].includes(event.key)) {
      return -1;
    }

    return 0;
  };

  private checkScrollbar = (force?: boolean) => {
    const showingQuestions = this.ss.viewState.value === ViewState.Questions;

    if (showingQuestions && force) {
      this.renderer.setStyle(this.elRef.nativeElement, 'max-height', `${this.sb.viewport.scrollHeight}px`);
    } else {
      this.renderer.removeStyle(this.elRef.nativeElement, 'max-height');
    }

    this.sb.update();
  };
}
