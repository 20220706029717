import { Component, Input, OnInit } from '@angular/core';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';

import { PlayerData } from '@player/shared/models/player.model';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SharedModuleForPlayer } from '@player/shared/modules/shared.module';

import { BasicModule } from '@shared/modules/basic.module';

@Component({
  selector: 'survey-status',
  templateUrl: 'survey-status.component.html',
  styleUrls: ['survey-status.component.scss'],
  providers: [SurveyStore],
  standalone: true,
  imports: [AsyncPipe, BasicModule, NgStyle, SharedModuleForPlayer, NgIf],
})
export class SurveyStatusComponent implements OnInit {
  @Input() icon?: string;

  @Input() translationKey?: string;

  @Input() fallbackText?: string;

  private readonly queryParams = new HttpParams({
    fromString: location.search?.substring(1) || '',
  });

  constructor(
    readonly http: HttpClient,
    readonly ss: SurveyStore,
    private lm: LanguageManager,
  ) {}

  ngOnInit(): void {
    const revision = window.ZEF ? window.ZEF.revision : Math.random().toString();
    const release = window.ZEF ? window.ZEF.url || '' : 'assets/testing/';
    const location = window.ZEF ? window.ZEF.root || window.ZEF.url || '' : 'assets/testing/';

    this.http.get(`${location || release}settings.json?${revision}`).subscribe((data: PlayerData) => {
      if (this.queryParams.get('lang')) {
        this.lm.paramsLanguage = this.queryParams.get('lang');
      }

      this.lm.autodetectLanguage = !!data.design?.sidebar?.selectLanguage;
      this.lm.init(data.languages, data.survey?.language, location);

      this.ss.design.next(data.design);
      this.ss.languages.next(data.languages);
    });
  }
}
