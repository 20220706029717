import { Observable, of, BehaviorSubject, timer } from 'rxjs';
import { timeoutWith, map, tap, debounce } from 'rxjs/operators';

import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';

import { UntypedFormControl, AbstractControl, ValidationErrors, UntypedFormGroup } from '@angular/forms';

import { PlayerAnswer } from '@player/shared/models/player.model';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { PlayerApi } from '@player/shared/services/player-api.service';

import { QuestionData } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';

import { isUrl, isEmail } from '@shared/utilities/validation.utilities';
import { Country, CountryData } from '@shared/enums/countries.enum';

import { ClientCountry } from '@shared/tokens/client-country.token';

@Component({
  selector: 'a-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleInput implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() answerValue: string = '';

  private readonly answerChange$ = new EventEmitter<PlayerAnswer>();

  @Output()
  readonly answerChanged = this.answerChange$.pipe(
    debounce(() => timer(this.data?.type === Questions.FREE_TEXT ? 700 : 0)),
  );

  readonly Questions = Questions;
  readonly country = Country;
  readonly countries: CountryData[] = Object.keys(this.country).map((code) => ({
    code,
    ...this.country[code],
  }));
  public validatingOnProgress = new BehaviorSubject<boolean>(false);
  public isValidPhone = new BehaviorSubject<boolean>(true);

  public answer: string = '';
  public phoneCountry: string = '';

  public emailFormControl = new UntypedFormControl('', {
    validators: this.validateEmail,
    updateOn: 'blur',
  });

  public urlFormControl = new UntypedFormControl('', {
    validators: this.validateUrl,
    updateOn: 'blur',
  });

  public phoneFormGroup = new UntypedFormGroup(
    {
      country: new UntypedFormControl('', {
        updateOn: 'blur',
      }),
      number: new UntypedFormControl('', {
        updateOn: 'blur',
      }),
    },
    {
      asyncValidators: (
        control: AbstractControl,
      ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if (!control.pristine && control.get('country').value && control.get('number').value) {
          if ((control.get('number').value.match(/\d/g) || []).length > 4) {
            return this.pa.validatePhone(control.get('country').value, control.get('number').value).pipe(
              timeoutWith(3000, of({ phone: true })),
              map((value) => ({ phone: !value })),
              tap((value) =>
                setTimeout(() => {
                  this.cdRef.detectChanges();
                  if (!value?.phone) {
                    this.onValueChanged([control.get('country').value, control.get('number').value].join(';'));
                  }
                }, 0),
              ),
            );
          } else {
            return of({ phone: true });
          }
        } else {
          return of(null);
        }
      },
      updateOn: 'blur',
    },
  );

  private validateUrl(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && !isUrl(control.value)) {
      return { url: true };
    }
    return null;
  }

  private validateEmail(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && !isEmail(control.value)) {
      return { email: true };
    }
    return null;
  }

  get data(): QuestionData {
    return this.questionData || new QuestionData();
  }

  constructor(
    private ss: SurveyStore,
    private pa: PlayerApi,
    private cdRef: ChangeDetectorRef,
    @Inject(ClientCountry) private clientCountry: string,
  ) {}

  ngOnChanges() {
    this.answer = this.answerValue || '';

    if (this.questionData && this.questionData.type === Questions.INPUT_EMAIL) {
      this.emailFormControl.setValue(this.answerValue);
    }

    if (this.questionData && this.questionData.type === Questions.INPUT_URL) {
      this.urlFormControl.setValue(this.answerValue);
    }

    if (
      this.answerValue &&
      this.questionData &&
      this.questionData.type === Questions.INPUT_PHONE &&
      this.answerValue.split(';')[1]
    ) {
      this.phoneFormGroup.setValue({
        country: this.answerValue.split(';')[0],
        number: this.answerValue.split(';')[1],
      });
      this.phoneCountry = this.answerValue.split(';')[0].toUpperCase();
    } else {
      this.phoneFormGroup.setValue({
        country: this.clientCountry.toUpperCase(),
        number: '',
      });
      this.phoneCountry = this.clientCountry.toUpperCase();
    }
  }

  onValueChanged($event) {
    this.answer = $event;
    this.answerChange$.emit({
      value: this.answer,
      item: this.questionData,
    });
  }

  onEmailValueChanged() {
    if (this.emailFormControl.valid && this.emailFormControl.value) {
      this.answer = this.emailFormControl.value;
      this.answerChange$.emit({
        value: this.answer,
        item: this.questionData,
      });
    }
  }

  onUrlValueChanged() {
    if (this.urlFormControl.valid && this.urlFormControl.value) {
      this.answer = this.urlFormControl.value;
      this.answerChange$.emit({
        value: this.answer,
        item: this.questionData,
      });
    }
  }

  getCharsLimit() {
    return (this.questionData && this.questionData.charsLimit) || 1500000;
  }
}
