<!--
  Sharing view for showing share status.

  @stable
-->

<div class="z-container-box z-fx-col z-fx-between-center z-fx-fill">
  <div class="z-header-box z-fx-col z-fx-center-center z-fx-gap-32" [class.error]="error">
    <ng-container *ngIf="!error; else errorTemplate">
      <div class="z-header-box-logo z-fx-col z-fx-center-center z-fx-flex-center">
        <zef-logo-spinner></zef-logo-spinner>
      </div>

      <span class="z-title-text">Creating your share</span>
    </ng-container>
    <ng-template #errorTemplate>
      <div class="z-header-box-logo z-fx-col z-fx-center-center z-fx-flex-center">
        <mat-icon fontSet="zefplayer">bullet_alert</mat-icon>
      </div>

      <span class="z-title-text">Something went wrong with your share :(</span>
    </ng-template>
  </div>

  <div class="z-content-box z-fx-col z-fx-end-center z-fx-gap-24">
    <a class="z-zef-logo z-fx z-fx-center-center" href="http://zef.fi" target="_blank">
      <mat-icon fontSet="zefplayer">zeffi_wordmark</mat-icon>
    </a>
  </div>
</div>
