<!--
  Question card view with header and content elements.

  @unstable
-->

<ng-container *ngIf="questionData">
  <media-cta *ngIf="hasMediaCta()" [data]="questionData"></media-cta>

  <div
    *ngIf="{
      showTitle:
        questionData.showTitle &&
        !!(questionData.title | translateText: 'title' : questionData | formatText) &&
        questionData.type !== Questions.AI_INTERVIEWER &&
        questionData.type !== Questions.ESKO_WHY_FINDER,
      isRequired: questionData.required,
    } as conditions"
    class="z-title-text z-texts-question"
    [class.z-visible]="conditions.showTitle || conditions.isRequired"
    [style.color]="(ss.colors | async).text"
    [labelOverflow]="Questions.slider(questionData) ? 'header' : ''"
  >
    <span *ngIf="conditions.showTitle || conditions.isRequired" class="z-question-title">
      <span>
        <span>
          <span *ngIf="conditions.showTitle" class="z-question-title-number">{{ !!no ? no + '. ' : '' }}</span>
          <span
            *ngIf="conditions.showTitle"
            class="z-question-title-text"
            style="white-space: pre-line"
            [innerHtml]="questionData.title | translateText: 'title' : questionData | formatText"
          ></span>

          <mat-icon *ngIf="conditions.isRequired" fontSet="zefplayer" class="z-mandatory-icon">mandatory</mat-icon>
        </span>
      </span>
    </span>
  </div>

  <div class="z-widget-box z-fx" [ngSwitch]="questionData.type">
    <slider-2d
      *ngSwitchCase="Questions.SLIDER_2D"
      class="z-fx-col z-fx-start-center z-fx-auto"
      [ngClass]="'z-size-' + (ss.viewSize | async)"
      [no]="no"
      [questionData]="questionData"
      [groupData]="groupData | async"
      [answerValue]="answerValue | sliderAnswer"
      [isActive]="active"
      (answerValueReady)="onAnswerReady()"
      (answerValueChange)="onAnswerChange($event ? $event.join(';') : null)"
    ></slider-2d>

    <slider-1d
      *ngSwitchCase="Questions.SLIDER_1D"
      class="z-fx-col z-fx-start-center z-fx-auto"
      [ngClass]="'z-size-' + (ss.viewSize | async)"
      [no]="no"
      [questionData]="questionData"
      [groupData]="groupData | async"
      [answerValue]="answerValue | sliderAnswer"
      (dragChange)="dragChange.emit($event)"
      (answerReady)="onAnswerReady()"
      (answerChange)="onAnswerChange($event ? $event.toString() : null)"
    ></slider-1d>

    <slider-1d
      *ngSwitchCase="Questions.SLIDER_1V"
      class="z-fx-col z-fx-start-center z-fx-auto"
      [ngClass]="'z-size-' + (ss.viewSize | async)"
      [no]="no"
      [questionData]="questionData"
      [groupData]="groupData | async"
      [answerValue]="answerValue | sliderAnswer"
      (dragChange)="dragChange.emit($event)"
      (answerReady)="onAnswerReady()"
      (answerChange)="onAnswerChange($event ? $event.toString() : null)"
    ></slider-1d>

    <slider-nps
      *ngSwitchCase="Questions.SLIDER_NPS"
      class="z-fx-col z-fx-start-center z-fx-auto"
      [ngClass]="'z-size-' + (ss.viewSize | async)"
      [no]="no"
      [questionData]="questionData"
      [groupData]="groupData | async"
      [answerValue]="answerValue | sliderAnswer"
      (dragChange)="dragChange.emit($event)"
      (answerReady)="onAnswerReady()"
      (answerChange)="onAnswerChange($event ? $event.toString() : null)"
    ></slider-nps>

    <slider-e-nps
      *ngSwitchCase="Questions.SLIDER_E_NPS"
      class="z-fx-col z-fx-start-center z-fx-auto"
      [ngClass]="'z-size-' + (ss.viewSize | async)"
      [no]="no"
      [questionData]="questionData"
      [groupData]="groupData | async"
      [answerValue]="answerValue | sliderAnswer"
      (dragChange)="dragChange.emit($event)"
      (answerReady)="onAnswerReady()"
      (answerChange)="onAnswerChange($event ? $event.toString() : null)"
    ></slider-e-nps>

    <slider-1r
      *ngSwitchCase="Questions.SLIDER_1R"
      class="z-fx-col z-fx-start-center z-fx-auto"
      [ngClass]="'z-size-' + (ss.viewSize | async)"
      [questionData]="questionData"
      [answerValue]="answerValue | sliderAnswer"
      (dragChange)="dragChange.emit($event)"
      (answerReady)="onAnswerReady()"
      (answerChange)="onAnswerChange($event ? $event.join(';') : null)"
    ></slider-1r>

    <input-info
      *ngSwitchCase="Questions.INFO_TEXT"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [answer]="!!answerValue"
      [questionData]="questionData"
      (answerChange)="onAnswerChange($event)"
    ></input-info>

    <input-info
      *ngSwitchCase="Questions.GROUP_CARDS"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [answer]="!!answerValue"
      [questionData]="questionData"
      (answerChange)="onAnswerChange($event)"
    ></input-info>

    <input-info
      *ngSwitchCase="Questions.GROUP_SCORED"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [answer]="!!answerValue"
      [questionData]="questionData"
      (answerChange)="onAnswerChange($event)"
    ></input-info>

    <input-dropdown
      *ngSwitchCase="Questions.INPUT_DROPDOWN"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [answer]="answerValue"
      [questionData]="questionData"
      (answerChange)="onAnswerChange($event)"
      (answerReady)="onAnswerReady()"
    ></input-dropdown>

    <input-dropdown
      *ngSwitchCase="Questions.INPUT_NUMERIC"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [answer]="answerValue"
      [questionData]="questionData"
      (answerChange)="onAnswerChange($event)"
      (answerReady)="onAnswerReady()"
    ></input-dropdown>

    <ng-container *ngSwitchCase="Questions.CHOICE_TEXT" [ngTemplateOutlet]="inputChoice"></ng-container>
    <ng-container *ngSwitchCase="Questions.CHOICE_MULTI" [ngTemplateOutlet]="inputChoice"></ng-container>
    <ng-container *ngSwitchCase="Questions.CHOICE_SINGLE" [ngTemplateOutlet]="inputChoice"></ng-container>

    <ng-template #inputChoice>
      <input-choice
        [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
        [isActive]="active"
        [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
        [answer]="answerValue"
        [questionData]="questionData"
        (answerChange)="onAnswerChange($event)"
        (answerReady)="onAnswerReady()"
      ></input-choice>
    </ng-template>

    <input-choice-picture
      *ngSwitchCase="Questions.CHOICE_PICTURE"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [answer]="answerValue"
      [questionData]="questionData"
      [isActive]="active"
      (answerChange)="onAnswerChange($event)"
      (answerReady)="onAnswerReady()"
    ></input-choice-picture>

    <input-checkbox
      *ngSwitchCase="Questions.INPUT_CHECKBOX"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [answer]="answerValue"
      [questionData]="questionData"
      [defaultAnswer]="questionData.inputField?.value === 'true'"
      (answerChange)="onAnswerChange($event)"
    ></input-checkbox>

    <input-phone
      *ngSwitchCase="Questions.INPUT_PHONE"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [questionData]="questionData"
      [answer]="answerValue != null ? answerValue.toString() : null"
      (waitNext)="waitNext.next($event)"
      (answerReady)="onAnswerReady()"
      (answerChange)="onAnswerChange($event)"
    ></input-phone>

    <file-upload
      *ngSwitchCase="Questions.FILE_UPLOAD"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [answer]="answerValue"
      [questionData]="questionData"
      (answerChange)="onAnswerChange($event)"
    ></file-upload>

    <ai-interviewer
      *ngSwitchCase="Questions.AI_INTERVIEWER"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [questionData]="questionData"
      [answerValue]="answerValue"
      (answerChange)="onAnswerChange($event)"
      (saveSummaries)="saveSummaries($event)"
    ></ai-interviewer>

    <why-finder
      *ngSwitchCase="Questions.ESKO_WHY_FINDER"
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [isLast]="isLast"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [questionData]="questionData"
      [answerValue]="answerValue"
      (answerChange)="onAnswerChange($event)"
      (saveSummaries)="saveSummaries($event)"
      (nextQuestion)="nextQuestion.emit()"
    ></why-finder>

    <input-simple
      *ngSwitchDefault
      [ngClass]="'z-input-wrapper z-size-' + (ss.viewSize | async)"
      [isActive]="active"
      [placeholder]="questionData.inputField?.hint | translateText: 'inputFieldHint' : questionData"
      [questionData]="questionData"
      [answer]="answerValue != null ? answerValue.toString() : null"
      (answerChange)="onAnswerChange($event)"
    ></input-simple>
  </div>
</ng-container>
