/**
 * Player app view with data loading.
 *
 * @stable
 */

import { first, map } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { SurveyView } from '@player/+survey/survey.component';
import { PlayerInit } from '@player/shared/services/player-init.service';
import { CommunicationManager } from '@player/shared/services/communication-manager.service';

import { TranslateText } from '@player/shared/pipes/translate-text.pipe';

import { getOS, PlayerAnswer, PlayerOutcome, PlayerState } from '@player/shared/models/player.model';

import { PropertyStore } from '@shared/services/property-store.service';
import { AccessibleView } from './+accessible/accessible.component';

@Component({
  selector: 'player-app',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  providers: [PlayerInit, CommunicationManager],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerApp implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SurveyView) surveyView: SurveyView;
  @ViewChild(AccessibleView, { static: false }) accessibleView: AccessibleView;

  public accessibilityBannerVisible: boolean = false;

  readonly accessibilityActive$ = this.pi.accessibilityActive$.pipe(
    map((active: boolean, i) => {
      // sometimes you need to improvise..
      if (i) {
        setTimeout(() => this.surveyView?.init(true));
      }

      return active;
    }),
  );

  constructor(
    readonly ps: PropertyStore,
    readonly tt: TranslateText,
    public pi: PlayerInit,
    public route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router,
  ) {
    console.time('create');

    console.timeEnd('init');

    if (getOS() === 'ios') {
      document.addEventListener('mousemove', (event: MouseEvent) => {
        event.preventDefault();
      });
    }
  }

  ngOnInit(): void {
    this.route.data.pipe(first()).subscribe((data) => {
      if (!data.accessible && location.search && location.search.indexOf('accessibility') >= 0) {
        this.router.navigate(['accessible'], { relativeTo: this.route }).then(() => {
          this.pi.initPlayer().subscribe();
        });
      } else {
        this.pi.initPlayer().subscribe(() => {
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();

          window.setTimeout(() => {
            if (this.surveyView) {
              this.surveyView.init();

              this.ps.outcomes.next(this.pi.data?.outcomes || []);
              this.ps.survey.next(this.pi.data?.survey);
              this.ps.team.next(this.pi.data?.team);
            }
          }, 0);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.pi.reset();
  }

  ngAfterViewInit(): void {
    console.timeEnd('render');
  }

  onPlayerStateChange(state: PlayerState): void {
    this.pi.onPlayerStateChange(state);
  }

  public onShowResults(): void {
    this.pi.onShowResults();
  }

  public onResetAnswers(): void {
    this.pi.onResetAnswers();
  }

  public onActiveChanged(active: PlayerAnswer): void {
    this.pi.onActiveChanged(active);
  }

  public onAnswerChanged(answer: PlayerAnswer): void {
    this.pi.onAnswerChanged(answer);
  }

  public onResultsChanged(results: PlayerOutcome[]): void {
    this.pi.onResultsChanged(results);
  }

  public showAccessibilityBanner() {
    this.accessibilityBannerVisible = true;
  }

  public hideAccessibilityBanner() {
    this.accessibilityBannerVisible = false;
  }

  public accessibilityText() {
    return this.tt.transform('Enter Accessibility Mode', 'enterAccessibilityMode');
  }
}
