/**
 * Player menu component with restart, language and sharing actions.
 *
 * @unstable
 */

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, HostListener } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';

@Component({
  selector: 'drawer-menu',
  templateUrl: './drawer-menu.component.html',
  styleUrls: ['./drawer-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerMenu {
  public selectLanguage: boolean = false;

  @Input() isAnonymous = false;

  @Output() restart: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('window:keydown.esc')
  onEscape(): void {
    this.close.emit();
  }

  constructor(
    readonly lm: LanguageManager,
    readonly ss: SurveyStore,
  ) {}

  onLanguagesToggle(value?: boolean): void {
    this.selectLanguage = value != null ? value : !this.selectLanguage;
  }

  flagClass(locale: string) {
    return locale && locale.length !== 2 ? 'zef-lang-other' : `zef-lang-${locale}`;
  }
}
