<!--
  Slider 1D state and widget elements.

  @unstable
-->

<div
  class="z-container-box z-fx-col"
  [class.z-zero]="data?.sliderValues?.initial === 0"
  [class.z-answered]="answered"
  [class.z-dragging]="dragging"
>
  <div class="z-answer-area z-fx-col z-fx-gap-16">
    <div [style.padding-left.px]="thumbRadius" [style.padding-right.px]="thumbRadius">
      <div class="z-smiley-icons">
        <ng-container *ngIf="smileys.length > 0">
          <ng-container *ngFor="let knob of knobs">
            <div
              *ngIf="currentSmiley[knob] && (dragging || thumbHover[knob])"
              [style.left.%]="percentLeft[knob]"
              [style.width.px]="thumbRadius * 2"
              ngClass="z-smiley-icon-{{ knob + 1 }}"
              class="z-smiley-icon"
            >
              <img [src]="currentSmiley[knob]" alt="" />
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div [style.padding-left.px]="thumbRadius" [style.padding-right.px]="thumbRadius">
      <div class="z-values-area z-fx z-fx-between-center" [style.color]="(ss.colors | async).text">
        <div
          class="z-limit-text z-min"
          [style.visibility]="data?.sliderValues?.visible !== false ? 'visible' : 'hidden'"
        >
          0
        </div>

        <div *ngIf="data?.sliderAnswer !== false" class="z-value-text">
          <ng-container *ngFor="let knob of knobs">
            <div
              #valueText
              *ngIf="dragging || thumbHover[knob]"
              class="z-label-text"
              ngClass="z-label-text-{{ knob + 1 }}"
              [style.left.%]="percentLeft[knob]"
              [style.min-width.px]="thumbRadius * 2"
            >
              <span
                [style.background-color]="(ss.colors | async).button"
                [style.color]="(ss.colors | async).buttonContrast"
              >
                {{ getCurrentValue(currentValue[knob]) }}
              </span>
            </div>
          </ng-container>
        </div>

        <div
          class="z-limit-text z-max"
          [style.visibility]="data?.sliderValues?.visible !== false ? 'visible' : 'hidden'"
        >
          10
        </div>
      </div>
    </div>

    <div #sliderArea class="z-slider-area" (touchmove.n)="onTouchMove($event)">
      <div class="z-background-box"></div>

      <div [style.padding.px]="thumbRadius">
        <div #trackInner class="z-track-inner">
          <div class="z-track-bar" [style.border-color]="(ss.colors | async).primary50"></div>

          <div
            class="z-cursor-line"
            [ngStyle]="getCursorLineStyle()"
            [style.background-color]="startPercent ? 'transparent' : (ss.colors | async).primary"
          ></div>

          <div
            *ngIf="isSlider"
            [style.border-color]="(ss.colors | async).primary50"
            class="z-start-dot"
            [style.left.%]="startPercent"
          ></div>

          <ng-container *ngIf="isRange">
            <div class="z-start-dot z-start-dot-1" [style.left.%]="0"></div>
            <div class="z-start-dot z-start-dot-2" [style.right.%]="0"></div>
          </ng-container>

          <slider-thumb
            *ngFor="let answer of groupAnswers | async"
            class="z-answer-thumb"
            [style.left.%]="getLeftFromAnswer(answer.val)"
            [style.background-color]="(ss.colors | async).text50"
            [style.color]="(ss.colors | async).textContrast"
            [questionNumber]="answer.no"
          ></slider-thumb>

          <slider-thumb
            *ngFor="let knob of knobs"
            class="z-thumb-knob"
            [ngClass]="'z-thumb-knob-' + (knob + 1)"
            [style.left.%]="percentLeft[knob]"
            [checked]="(hasTouched[knob] || answered) && !disableCheck"
            [color]="(ss.colors | async).primary"
            (mouseover)="thumbHover[knob] = true"
            (mouseleave)="thumbHover[knob] = false"
            #thumbKnob
          ></slider-thumb>
        </div>
      </div>
    </div>

    <slider-label
      *ngIf="
        data?.sliderLabels?.min?.length > 0 ||
        data?.sliderLabels?.max?.length > 0 ||
        data?.sliderLabels?.axis?.length > 0
      "
      [data]="data"
      [sliderLabels]="data?.sliderLabels"
      [color]="(ss.colors | async).text"
      [view]="view"
    ></slider-label>
  </div>
</div>

<slider-images [images]="smileys"></slider-images>
