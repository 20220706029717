<!--
  Player component view with survey view.

  @stable
-->
<ng-container *ngIf="(accessibilityActive$ | async) !== true; else routerOutlet">
  <div
    *ngIf="(pi.showAccessibilityBanner | async) || accessibilityBannerVisible"
    class="z-fx z-fx-center-center accessibility-banner"
    [class.not-visible]="!pi.data?.survey?.accessibilityBanner && !accessibilityBannerVisible"
  >
    <a class="link" routerLink="accessible">{{ accessibilityText() }}</a>
  </div>

  <survey-view
    [class.accessibility-banner-visible]="
      (pi.data?.survey?.accessibilityBanner &&
        !(route.data | async)?.accessible &&
        (pi.showAccessibilityBanner | async)) ||
      accessibilityBannerVisible
    "
    [teamKey]="pi.team"
    [teamData]="pi.data?.team"
    [answerData]="pi.data?.answers"
    [surveyKey]="pi.survey"
    [surveyData]="pi.data?.survey"
    [designData]="pi.data?.design"
    [sharingData]="pi.data?.sharing"
    [releaseData]="pi.data?.release"
    [scoringData]="pi.data?.scoring"
    [outcomesData]="pi.data?.outcomes"
    [questionsData]="pi.data?.questions"
    [triggerData]="pi.data?.triggers"
    [languagesData]="pi.data?.languages"
    [releaseUrl]="pi.release"
    [releaseLocation]="pi.location"
    [isAnonymous]="pi.isAnonymous"
    [queryLanguage]="pi.language"
    [playerState]="pi.playerState"
    [surveyStartConfig]="pi.surveyStartConfig$ | async"
    [selectedLanguage]="pi.selectedLanguage$ | async"
    (playerStateChange)="onPlayerStateChange($event)"
    (showResults)="onShowResults()"
    (resetAnswers)="onResetAnswers()"
    (activeChanged)="onActiveChanged($event)"
    (answerChanged)="onAnswerChanged($event)"
    (resultsChanged)="onResultsChanged($event)"
    (showAccessibilityBanner)="showAccessibilityBanner()"
    (hideAccessibilityBanner)="hideAccessibilityBanner()"
  ></survey-view>
</ng-container>

<ng-template #routerOutlet>
  <router-outlet></router-outlet>
</ng-template>
