<div class="z-fx-col container">
  <ng-container *ngIf="questionData.choiceLimit !== 1; else single">
    <div *ngIf="questionData?.showStatus !== false && questionData?.choiceLimit > 1" class="status-text">
      {{
        'Select up to ' + questionData?.choiceLimit | translateText: 'choiceSelectUpTo' : [questionData?.choiceLimit]
      }}
    </div>
    <div class="choices">
      <div *ngFor="let option of options; trackBy: trackByChoice" class="checkbox">
        <mat-checkbox
          *ngIf="option.$key !== 'other'"
          [(ngModel)]="multiSelections[option.$key]"
          (ngModelChange)="onMultiSelectionValueChanged()"
          [disabled]="limitReached && !multiSelections[option.$key]"
        >
          <div class="choice-image-wrapper" [attr.aria-label]="option.hideContent ? option.content : ''" role="img">
            <div
              *ngIf="(null | translateText: 'imageStyle' : option) || option.imageStyle?.url as image"
              class="choice-image"
              [ngStyle]="backgroundImageStyles(option.imageStyle)"
              [unsplashAttribute]="image"
              [style.background-image]="image | encodeURI: 'url' | safeStyle"
              [style.height]="'calc(100vh / 3)'"
            ></div>
          </div>

          <span *ngIf="option.content && !option.hideContent" class="z-dropdown-option">
            {{ option.content | translateText: 'choice' : questionData : option }}
          </span>
        </mat-checkbox>
      </div>
    </div>
  </ng-container>

  <ng-template #single>
    <mat-radio-group
      class="radios"
      [(ngModel)]="radioAnswer"
      [attr.aria-label]="data?.title | translateText: 'title' : data"
      (change)="onSingleSelectionValueChanged($event)"
    >
      <ng-container *ngFor="let option of options; trackBy: trackByChoice">
        <mat-radio-button *ngIf="option.$key !== 'other'" class="radio" value="after" [value]="option.$key">
          <div class="choice-image-wrapper" [attr.aria-label]="option.hideContent ? option.content : ''" role="img">
            <div
              *ngIf="(null | translateText: 'imageStyle' : option) || option.imageStyle?.url as image"
              class="choice-image"
              [ngStyle]="backgroundImageStyles(option.imageStyle)"
              [unsplashAttribute]="image"
              [style.background-image]="image | encodeURI: 'url' | safeStyle"
              [style.height]="'calc(100vh / 3)'"
            ></div>
          </div>
          <span *ngIf="option.content && !option.hideContent" class="z-dropdown-option">
            {{ option.content | translateText: 'choice' : questionData : option }}
          </span>
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </ng-template>
</div>
