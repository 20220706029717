<div
  class="z-fx"
  *ngIf="{ step: (step$ | async), colors: (ss.colors | async), isMobile: (ss.isMobile | async) } as asyncData"
  [ngSwitch]="asyncData.step"
>
  <ng-container *ngSwitchCase="Step.RATING">
    <div class="z-fx z-fx-grow z-fx-gap-16 z-fx-center-center">
      <div class="z-texts-paragraph" [style.color]="asyncData.colors.text">
        {{ ratingQuestionText | translateText: 'zefSurveyUserRatingQuestion' }}
      </div>
      <div class="z-fx">
        <button
          class="button-small"
          [style.background-color]="asyncData.colors.primary"
          [style.color]="asyncData.colors.primaryParity"
          (click)="rateDown()"
        >
          <mat-icon class="zef-icon-large">player_thumbs_down</mat-icon>
        </button>
        <button
          class="button-small"
          [style.background-color]="asyncData.colors.primary"
          [style.color]="asyncData.colors.primaryParity"
          (click)="rateUp()"
        >
          <mat-icon class="zef-icon-large">player_thumbs_up</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="Step.WAIT_UP">
    <button
      class="button-large thumbs-up"
      [style.background-color]="asyncData.colors.primary50"
      (click)="step$.next(Step.RATING)"
    >
      <div class="button-foreground" [style.background-color]="asyncData.colors.primary"></div>
      <mat-icon class="zef-icon-large" [style.color]="asyncData.colors.primaryParity">player_thumbs_up</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="Step.WAIT_DOWN">
    <button
      class="button-large thumbs-down"
      [style.background-color]="asyncData.colors.primary50"
      (click)="step$.next(Step.RATING)"
    >
      <div class="button-foreground" [style.background-color]="asyncData.colors.primary"></div>
      <mat-icon class="zef-icon-large" [style.color]="asyncData.colors.primaryParity">player_thumbs_down</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="Step.SIGNUP_QUESTION">
    <div class="z-fx z-fx-grow z-fx-gap-16 z-fx-center-center">
      <div class="z-texts-paragraph" [style.color]="asyncData.colors.text">
        {{ signupQuestionText1 | translateText: 'zefSurveyUserRatingSignup1' }}
        <a [href]="wwwAddress" target="_blank" [style.color]="asyncData.colors.button">Zeffi</a>
        {{ signupQuestionText2 | translateText: 'zefSurveyUserRatingSignup2' }}
      </div>
      <div class="z-fx">
        <button
          class="button-small"
          [style.background-color]="asyncData.colors.button"
          [style.color]="asyncData.colors.buttonContrast"
          (click)="declineSignup()"
        >
          <mat-icon class="zef-icon-large">close</mat-icon>
        </button>
        <button
          class="button-small"
          [style.background-color]="asyncData.colors.button"
          [style.color]="asyncData.colors.buttonContrast"
          (click)="acceptSignup()"
        >
          <mat-icon class="zef-icon-large">check</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</div>
