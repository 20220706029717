<!--
  Offline view with static content.

  @stable
-->

<div class="z-container-box z-fx-col z-fx-between-center z-fx-fill">
  <div
    class="z-cover"
    [style.filter]="(ss.background | async)?.filter | safeStyle"
    [ngStyle]="(ss.background | async)?.styles"
    [style.background-image]="(ss.background | async)?.image | safeStyle"
  ></div>

  <div class="z-cover" [style.background-color]="(ss.background | async)?.brightness"></div>
  <div class="z-cover" [style.background-color]="(ss.colors | async)?.moodOpacity"></div>
  <div class="z-cover" [style.background-color]="(ss.colors | async)?.moodOpacity"></div>

  <div class="z-header-box z-fx-col z-fx-center-center z-fx-gap-32">
    <div *ngIf="icon" class="z-header-box-logo z-fx-col z-fx-center-center z-fx-flex-center">
      <mat-icon fontSet="zefplayer" [style.color]="(ss.colors | async).text" class="z-fx-none">{{ icon }}</mat-icon>
    </div>

    <span class="z-title-text" [style.color]="(ss.colors | async).text">
      {{ fallbackText | translateText: translationKey }}
    </span>
  </div>

  <div class="z-content-box z-fx-col z-fx-end-center z-fx-gap-24">
    <a class="z-zef-logo z-fx z-fx-center-center" href="http://zef.fi" target="_blank">
      <mat-icon fontSet="zefplayer" [style.color]="(ss.colors | async).text50">zeffi_wordmark</mat-icon>
    </a>
  </div>
</div>
