/**
 * Storage location strategy.
 *
 * @unstable
 */

import { APP_BASE_HREF, Location, LocationStrategy } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

@Injectable()
export class StorageLocation extends LocationStrategy {
  private baseHref: string = '';

  private currentPath: string = window.location.pathname;

  // We could do history if we want to use back/forward navigation
  // private pathFuture: string[] = [];
  // private pathHistory: string[] = [];

  constructor(@Optional() @Inject(APP_BASE_HREF) baseHref?: string) {
    super();

    if (baseHref !== undefined) {
      this.baseHref = baseHref;
    }
  }

  getState(): unknown {
    return undefined;
  }

  public onPopState(): void {
    // Uncomment if we want to follow the browser url changes...
    // this._platformLocation.onPopState(fn);
    // this._platformLocation.onHashChange(fn);
  }

  public getBaseHref(): string {
    return this.baseHref;
  }

  public path(): string {
    // This returns the initial and current path, should be read and synced into localstorage
    return this.currentPath;
  }

  public prepareExternalUrl(internal: string): string {
    const url = Location.joinWithSlash(this.baseHref, internal);

    return url;
  }

  public pushState() {
    // Here we should push the new state into history
    // this.pathHistory.push(path);
  }

  public replaceState() {
    // Here we should replace state in the history
  }

  public back(): void {
    // this.pathFuture.push(this.pathHistory.pop());
  }

  public forward(): void {
    // this.pathHistory.push(this.pathFuture.pop());
  }
}
