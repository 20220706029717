import 'hammerjs';
import '@angular/localize/init';

import { environment } from '@env/environment';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { disableConsole } from '@shared/utilities/console.utilities';
import { ZefModule } from './app/zef.module';

if (environment.production) {
  enableProdMode();
  disableConsole();
}

platformBrowserDynamic().bootstrapModule(ZefModule);
