/**
 * Player app public views module.
 *
 * @stable
 */

import { Observable } from 'rxjs';

import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { PlayerApp } from '@player/player.component';

import { SurveyModule } from '@player/+survey/survey.module';

import { DeletedModule } from '@player/+deleted/deleted.module';
import { SharingModule } from '@player/+sharing/sharing.module';

import { PropertyStore } from '@shared/services/property-store.service';
import { ClientCountry } from '@shared/tokens/client-country.token';
import { DATE_TIME_PROVIDER } from '@shared/tokens/date-time-provider.token';

import { OnlineState } from '@player/shared/guards/online-state.guard';
import { DeletedState } from '@player/shared/guards/deleted-state.guard';

import { PlayerApi } from '@player/shared/services/player-api.service';
import { PlayerInit } from '@player/shared/services/player-init.service';
import { PlayerStore } from '@player/shared/services/player-store.service';
import { OfflineView } from '@player/+offline/offline.component';
import { ReleaseLoader } from '@player/shared/services/release-loader.service';
import { DateTimeProvider } from '@player/shared/services/date-time-provider.service';
import { IdentityRequiredView } from '@player/+identity-required/identity-required-view.component';

@NgModule({
  declarations: [PlayerApp],
  imports: [RouterModule, CommonModule, SurveyModule, DeletedModule, OfflineView, IdentityRequiredView, SharingModule],
  providers: [
    OnlineState,
    DeletedState,

    PlayerStore,
    PlayerInit,

    {
      provide: 'LocaleLoader',
      useClass: ReleaseLoader,
    },
    {
      provide: PropertyStore,
      useClass: PlayerStore,
    },
    {
      provide: ClientCountry,
      useFactory: publicClientCountry,
      deps: [PlayerApi],
    },
    {
      provide: DATE_TIME_PROVIDER,
      useFactory: dateTimeProviderFactory,
      deps: [DateTimeProvider],
    },
  ],
})
export class PublicModule {}

export function publicClientCountry(pa: PlayerApi): string {
  return pa.clientCountry;
}

export function dateTimeProviderFactory(dt: DateTimeProvider): Observable<Date> {
  return dt.date$;
}
