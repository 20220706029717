<div class="z-fx-col container">
  <div class="z-fx-col labels">
    <div class="axis-label">{{ data.sliderLabels.axis | translateText: 'axis' : data }}</div>
    <div class="z-fx z-fx-between-center axis-ends">
      <div class="min">{{ data.sliderLabels.min | translateText: 'min' : data }} ({{ data.sliderValues.min }})</div>
      <div class="max">{{ data.sliderLabels.max | translateText: 'max' : data }} ({{ data.sliderValues.max }})</div>
    </div>
  </div>
  <div class="slider-container">
    <mat-slider
      #xAxis
      class="slider-1r-min"
      [style.width]="
        ((answer[1] - data.sliderValues.min) / (data.sliderValues.max - data.sliderValues.min)) * 100 + '%'
      "
      [min]="data.sliderValues.min"
      [max]="answer[1]"
      [step]="data.sliderValues.step"
      [value]="answer[0]"
      thumbLabel
      [attr.aria-label]="(questionData?.title | translateText: 'title' : questionData) + ': min'"
      (change)="onValueXChanged($event)"
    ></mat-slider>
    <mat-slider
      #yAxis
      class="slider-1r-max"
      [style.width]="
        (1 - (xAxis.value - data.sliderValues.min) / (data.sliderValues.max - data.sliderValues.min)) * 100 + '%'
      "
      [style.marginLeft]="
        ((xAxis.value - data.sliderValues.min) / (data.sliderValues.max - data.sliderValues.min)) * 100 + '%'
      "
      [min]="xAxis.value"
      [max]="data.sliderValues.max"
      [step]="data.sliderValues.step"
      [value]="answer[1]"
      thumbLabel
      [attr.aria-label]="(questionData?.title | translateText: 'title' : questionData) + ': max'"
      (change)="onValueYChanged($event)"
    ></mat-slider>
  </div>
</div>
