/**
 * Player app deleted view module.
 *
 * @stable
 */

import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { BasicModule } from '@shared/modules/basic.module';

import { DeletedView } from '@player/+deleted/deleted.component';

@NgModule({
  bootstrap: [],
  declarations: [DeletedView],
  exports: [],
  imports: [BasicModule, MatIconModule],
  providers: [],
})
export class DeletedModule {}
