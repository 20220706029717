import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';

import { QuestionData, ChoiceItemData } from '@shared/models/survey.model';

@Component({
  selector: 'a-choice-picture',
  templateUrl: './choice-picture.component.html',
  styleUrls: ['./choice-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleChoicePicture implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() answerValue: string = '';

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  public answer: string = '';
  public radioAnswer: string = '';
  public multiSelections: { [s: string]: boolean } = {};
  public limitReached: boolean = false;

  get data(): QuestionData {
    return this.questionData || new QuestionData();
  }

  public options: ChoiceItemData[];

  constructor() {}

  ngOnChanges() {
    const choices = (this.questionData && this.questionData.choiceList) || [];
    this.options = choices;
    this.answer = this.answerValue || '';
    this.radioAnswer = this.answer.toString().split('=')[0];

    if (this.questionData.choiceLimit > 1) {
      for (const option of this.options) {
        this.multiSelections[option.$key] = this.answer.indexOf(option.$key) >= 0;
      }
      this.updateLimitReach();
    }
  }

  public onSingleSelectionValueChanged($event) {
    if ($event.value) {
      this.answer = $event.value;

      this.answerChanged.emit({
        value: this.answer,
        item: this.questionData,
      });
    }
  }

  public onMultiSelectionValueChanged() {
    let answer: string = '';
    for (const option in this.multiSelections) {
      if (this.multiSelections[option]) {
        if (answer) {
          answer += ';';
        }
        answer += option;
      }
    }

    this.updateLimitReach();

    this.answer = answer;
    this.answerChanged.emit({
      value: this.answer,
      item: this.questionData,
    });
  }

  private updateLimitReach() {
    let count: number = 0;

    for (const option in this.multiSelections) {
      if (this.multiSelections[option]) {
        count++;
      }
    }

    if (this.questionData.choiceLimit > 1 && count >= this.questionData.choiceLimit) {
      this.limitReached = true;
    } else if (this.questionData.choiceLimit > 1 && count < this.questionData.choiceLimit) {
      this.limitReached = false;
    }
  }

  public trackByChoice(index: number, item: ChoiceItemData): string {
    return item.$key;
  }

  public backgroundImageStyles(image) {
    return image?.getStyles() || {};
  }
}
