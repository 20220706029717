import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';

import { QuestionData, SliderValuesData } from '@shared/models/survey.model';

@Component({
  selector: 'a-slider-2d',
  templateUrl: './slider-2d.component.html',
  styleUrls: ['./slider-2d.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleSlider2d implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() groupData?: QuestionData;
  @Input() answerValue: string = null;

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  public answer: number[] = [null, null];

  get data(): QuestionData {
    return this.groupData || this.questionData || new QuestionData();
  }

  get ariaLabelX(): string {
    return `${this.tt.transform(this.questionData?.title, `title-${this.questionData?.$key}`)}: ${this.tt.transform(
      this.data.sliderLabelsX.axis,
      `axis-X-${this.data?.$key}`,
    )}: ${this.data.sliderValuesX.min} = ${this.tt.transform(
      this.data.sliderLabelsX.min,
      `min-X-${this.data?.$key}`,
    )}, ${this.data.sliderValuesX.max} = ${this.tt.transform(this.data.sliderLabelsX.max, `max-X-${this.data?.$key}`)}`;
  }

  get ariaLabelY(): string {
    return `${this.tt.transform(this.questionData?.title, `title-${this.questionData?.$key}`)}: ${this.tt.transform(
      this.data.sliderLabelsY.axis,
      `axis-Y-${this.data?.$key}`,
    )}: ${this.data.sliderValuesY.min} = ${this.tt.transform(
      this.data.sliderLabelsY.min,
      `min-Y-${this.data?.$key}`,
    )}, ${this.data.sliderValuesY.max} = ${this.tt.transform(this.data.sliderLabelsY.max, `max-Y-${this.data?.$key}`)}`;
  }

  constructor(
    private ss: SurveyStore,
    private tt: TranslateText,
  ) {}

  ngOnChanges() {
    this.answer = this.answerValue
      ? this.answerValue.split(';').map((item) => Number(item))
      : [
          this.data.sliderValuesX.min +
            (this.data.sliderValuesX.max - this.data.sliderValuesX.min) * this.data.sliderValuesX.initial,
          this.data.sliderValuesY.min +
            (this.data.sliderValuesY.max - this.data.sliderValuesY.min) * this.data.sliderValuesY.initial,
        ];
  }

  onValueXChanged($event) {
    this.answer[0] = $event.value;
    this.answerChanged.emit({
      value: this.answer.join(';'),
      item: this.questionData,
    });
  }

  onValueYChanged($event) {
    this.answer[1] = $event.value;
    this.answerChanged.emit({
      value: this.answer.join(';'),
      item: this.questionData,
    });
  }

  public smiley(val: number, sliderValues: SliderValuesData): string {
    return this.data.sliderSmileys
      ? 'smiley-' +
          (val !== sliderValues.min
            ? Math.ceil((val - sliderValues.min) / ((sliderValues.max - sliderValues.min) / 5))
            : 1)
      : '';
  }
}
