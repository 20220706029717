import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges } from '@angular/core';

import { ChoiceItemData } from '@shared/models/survey.model';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Question } from '@player/+survey/question/question-base';
import { Questions } from '@shared/enums/questions.enum';

@Component({
  selector: 'input-choice',
  templateUrl: './input-choice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputChoice extends Question implements OnChanges {
  public hovering: any = null;
  public selectedChoices: ChoiceItemData[] = [];
  public showOtherInput = false;
  public waitOtherFocus = 200;

  choices: ChoiceItemData[] = [];

  isMultiple(): boolean {
    if (!this.questionData) {
      return false;
    }

    const limit = this.questionData.choiceLimit;
    const type = this.questionData.type;

    if (type === Questions.CHOICE_MULTI) {
      return true;
    }

    if (type === Questions.CHOICE_SINGLE) {
      return false;
    }

    return limit != null && limit !== 1;
  }

  constructor(ss: SurveyStore) {
    super(ss);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.questionData) {
      const answer = this.answer || '';

      let choices = [...(this.questionData?.choiceList || [])];

      this.selectedChoices = answer
        .split(';')
        .filter(Boolean)
        .map((key) => (key.startsWith('other') ? 'other' : key))
        .map((key) => choices.find((choice) => choice['$key'] === key));

      this.showOtherInput = answer.includes('other=');

      const otherIdx = choices.findIndex((choice) => choice.$key === 'other');

      if (otherIdx > -1 && otherIdx < choices.length - 1) {
        const other = choices.splice(otherIdx, 1);
        choices = [...choices, ...other];
      }

      this.choices = choices;
    }
  }

  showChoice(choice: ChoiceItemData) {
    if (!choice.$key && choice.comment) {
      return false;
    }

    return choice.$key !== 'other' || !this.showOtherInput;
  }

  isSelected(choice: ChoiceItemData) {
    return this.selectedChoices.includes(choice);
  }

  onMouseOver(choice: ChoiceItemData): void {
    const limit = this.questionData.choiceLimit || 1;

    this.hovering = limit > 1 && this.selectedChoices.length >= limit ? null : choice;
  }

  selectChoice(choice: ChoiceItemData) {
    const isMultiple = this.isMultiple();
    const limit = (isMultiple && this.questionData.choiceLimit) || 1;
    let skipOther = false;

    if (!isMultiple) {
      this.selectedChoices = this.selectedChoices.includes(choice) ? [] : [choice];

      this.showOtherInput = false;
    } else {
      if (this.selectedChoices.includes(choice)) {
        this.selectedChoices = this.selectedChoices.filter((c) => c != null && c !== choice);
      } else if (limit == null || this.selectedChoices.length < limit || limit <= 1) {
        this.selectedChoices.push(choice);
      } else {
        skipOther = true;
      }
    }

    if (!skipOther && choice.$key === 'other') {
      this.waitOtherFocus = 0;
      this.showOtherInput = true;
      return;
    }

    const answer = this.preparedAnswer;
    this.selectAnswer(answer);

    if (choice.$key !== 'other' && !isMultiple && answer != null) {
      this.answerReady.emit();
    }
  }

  changeOther(other: string | null) {
    const choices = this.selectedChoices.filter(Boolean);

    if (other === null) {
      this.showOtherInput = false;
      this.selectedChoices = choices.filter((choice) => choice.$key !== 'other');
    } else {
      this.selectedChoices = choices.map((choice) => (choice.$key !== 'other' ? choice : { ...choice, other }));
    }

    this.selectAnswer(this.preparedAnswer);
  }

  selectAnswer(value: string) {
    this.answer = value;
    this.isAnswered = true;

    this.submitAnswer(value);
  }

  trackByChoice(index: number, item: ChoiceItemData): string {
    return item.$key;
  }

  get otherAnswer() {
    return typeof this.answer !== 'string' || !this.answer.includes('other=') ? '' : this.answer.split('other=')[1];
  }

  get preparedAnswer() {
    const choices = this.selectedChoices
      .filter(Boolean)
      .map((choice: any) => (choice.$key !== 'other' ? choice.$key : choice.other || 'other='))
      .filter((choice) => choice != null)
      // "other" choice last
      .sort((a, b) => a.localeCompare(b));

    return choices.length ? choices.join(';') : null;
  }
}
