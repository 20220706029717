/**
 * Sharing view for showing share status.
 *
 * @stable
 */

import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'sharing-view',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharingView {
  public error: boolean = false;

  constructor(readonly cdRef: ChangeDetectorRef) {
    window.addEventListener('message', (event) => {
      if (event.data && event.data.error) {
        this.showError();
      }
    });

    window.setTimeout(() => this.showError(), 30000);
  }

  private showError(): void {
    this.error = true;

    this.cdRef.markForCheck();
  }
}
