export function getLocalStorage() {
  return getStorage('localStorage');
}

export function getSessionStorage() {
  return getStorage('sessionStorage');
}

export function getStorage(storageType: string): any {
  try {
    return window[storageType];
  } catch {
    let storage = {};

    return {
      clear: () => (storage = {}),
      getItem: (key: string) => storage[key] ?? null,
      removeItem: (key: string) => delete storage[key],
      setItem: (key: string, value: string) => (storage[key] = value?.toString()),
    };
  }
}
