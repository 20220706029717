/**
 * Directive for label overflow detection.
 *
 * @unstable
 */

import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, Optional } from '@angular/core';

import { SliderLabel } from '@player/shared/services/slider-label.service';

@Directive({
  selector: '[labelOverflow]',
})
export class LabelOverflow implements AfterViewInit, OnDestroy {
  @Input()
  labelOverflow?: 'axis' | 'title' | 'header' | '';

  private ro: ResizeObserver;

  constructor(
    readonly er: ElementRef<HTMLElement>,
    readonly nz: NgZone,
    @Optional() readonly sl?: SliderLabel,
  ) {}

  ngAfterViewInit(): void {
    this.nz.runOutsideAngular(() => {
      setTimeout(() => this.initOverflow());
    });
  }

  ngOnDestroy(): void {
    if (this.ro) {
      this.ro.disconnect();
    }
  }

  initOverflow(): void {
    if (this.labelOverflow) {
      if (this.sl) {
        this.sl.getSync(this.labelOverflow).subscribe((size) => {
          this.setToSize(size);
        });
      }

      this.ro = new ResizeObserver(() => this.checkOverflow());
      this.ro.observe(this.er.nativeElement);

      this.checkOverflow();

      setTimeout(() => this.checkOverflow(), 1000);
    }
  }

  checkOverflow(): void {
    this.er.nativeElement.style.fontSize = null;
    this.er.nativeElement.style.lineHeight = null;
    let size = parseFloat(window.getComputedStyle(this.er.nativeElement).fontSize);

    while (this.isOverflowing() && size > 1) {
      size--;
      this.setToSize(size);
    }

    if (this.sl) {
      this.sl.update(size, this.labelOverflow);
    }
  }

  private isOverflowing(): boolean {
    const el = this.er.nativeElement;
    const child = el.firstElementChild;

    return (
      el.scrollHeight > el.offsetHeight + 2 ||
      el.scrollWidth > el.clientWidth + 2 ||
      (child instanceof HTMLElement && el.offsetHeight < child.offsetHeight)
    );
  }

  private setToSize(size: number): void {
    const el = this.er.nativeElement;
    el.style.fontSize = `${size}px`;
    el.style.lineHeight = `${size + 1}px`;
  }
}
