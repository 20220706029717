import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { QuestionData } from '@shared/models/survey.model';

@Component({
  selector: 'a-slider-1r',
  templateUrl: './slider-1r.component.html',
  styleUrls: ['./slider-1r.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleSlider1r implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() answerValue: string = null;

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  public answer: number[] = [null, null];

  get data(): QuestionData {
    return this.questionData || new QuestionData();
  }

  constructor(private ss: SurveyStore) {}

  ngOnChanges() {
    this.answer = this.answerValue
      ? this.answerValue.split(';').map((item) => Number(item))
      : [
          this.data.sliderValues.min + (this.data.sliderValues.max - this.data.sliderValues.min) * 0.25,
          this.data.sliderValues.min + (this.data.sliderValues.max - this.data.sliderValues.min) * 0.75,
        ];
  }

  onValueXChanged($event) {
    this.answer[0] = $event.value;
    this.answerChanged.emit({
      value: this.answer.join(';'),
      item: this.questionData,
    });
  }

  onValueYChanged($event) {
    this.answer[1] = $event.value;
    this.answerChanged.emit({
      value: this.answer.join(';'),
      item: this.questionData,
    });
  }
}
