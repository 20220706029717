/**
 * Pipe for fetching & updating translations.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

import { LanguageManager } from '@player/shared/services/language-manager.service';

import { Translatable } from '@shared/models/locale.model';

@Pipe({
  name: 'translateText',
})
export class TranslateText implements PipeTransform {
  constructor(readonly translator: LanguageManager) {}

  transform(original: string, ...args: any[]): any {
    if (!args || args.length === 0) {
      return original;
    }

    const id = this.getTranslationId(args);

    let value = this.translator.get(id) || this.translator.get(this.getTranslationId(args, true)) || original;

    args.forEach((arg) => {
      if (Array.isArray(arg)) {
        arg.forEach((replace, i) => (value = value.replace(`{${i}}`, replace)));
      }
    });

    return value;
  }

  private getTranslationId(
    args: (any | Translatable)[],
    template: boolean = false,
    identifier: keyof Translatable = '$key',
  ): string {
    const hasKey = (arg) => arg.hasOwnProperty(identifier);

    const value = args
      .filter((arg) => !Array.isArray(arg))
      .map((arg) => {
        if (arg && typeof arg !== 'string' && hasKey(arg)) {
          // For now this is needed for the template translations
          const type = arg.type === 'goodbye' || arg.type === 'outcome' ? 'outcome' : 'question';

          arg = template && arg.type ? type + '-' + arg[identifier] : arg[identifier];
        }

        return arg;
      })
      .join('-');

    if (/choice-\d+-\d+/.test(value)) {
      const vs = value.split('-');
      return `choice-question-${vs[1]}-choice-${vs[2]}`;
    }

    return value;
  }
}
