import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';

import { QuestionData } from '@shared/models/survey.model';

@Component({
  selector: 'a-slider-nps',
  templateUrl: './slider-nps.component.html',
  styleUrls: ['./slider-nps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleSliderNPS implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() groupData?: QuestionData;
  @Input() answerValue: string = null;

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  public answer: number = null;

  get data(): QuestionData {
    return this.questionData || new QuestionData();
  }

  get ariaLabel(): string {
    return `${this.tt.transform(this.questionData?.title, `title-${this.questionData?.$key}`)}: ${this.tt.transform(
      this.data.sliderLabels.axis,
      `axis-${this.data?.$key}`,
    )}: ${0} = ${this.tt.transform(this.data.sliderLabels.min, `min-${this.data?.$key}`)}, ${10} = ${this.tt.transform(
      this.data.sliderLabels.max,
      `max-${this.data?.$key}`,
    )}`;
  }

  constructor(
    private ss: SurveyStore,
    private tt: TranslateText,
  ) {}

  ngOnChanges() {
    this.answer = this.answerValue ? Number(this.answerValue) : 5;
  }

  onValueChanged($event) {
    this.answer = $event.value;
    this.answerChanged.emit({
      value: this.answer.toString(),
      item: this.questionData,
    });
  }

  public smiley(val: number): string {
    return this.data.sliderSmileys ? 'smiley-' + (val !== 0 ? Math.ceil(val / 2) : 1) : '';
  }
}
