/**
 * Player app sharing view module.
 *
 * @stable
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { BasicModule } from '@shared/modules/basic.module';

import { SharingView } from '@player/+sharing/sharing.component';

@NgModule({
  bootstrap: [],
  declarations: [SharingView],
  exports: [],
  imports: [BasicModule, CommonModule, MatIconModule],
  providers: [],
})
export class SharingModule {}
