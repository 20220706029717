import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { AnswersManager } from '@player/shared/services/answers-manager.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { BehaviorSubject } from 'rxjs';

enum Step {
  NONE,
  RATING,
  WAIT_UP,
  WAIT_DOWN,
  SIGNUP_QUESTION,
}

@Component({
  selector: 'zef-funnel-two',
  templateUrl: './funnel-two.component.html',
  styleUrls: ['./funnel-two.component.scss'],
})
export class FunnelTwo implements OnInit {
  readonly Step = Step;
  readonly TIMEOUT_DOWN = 2250;
  readonly TIMEOUT_UP = 1250;
  readonly wwwAddress = environment.wwwAddress;

  ratingQuestionText = `Did you like answering this survey?`;
  signupQuestionText1 = `Would you like to create your own`;
  signupQuestionText2 = `survey?`;
  private timeout?: any;
  step$ = new BehaviorSubject(Step.NONE);

  @Input() answers;

  @Output() submit = new EventEmitter();

  constructor(
    readonly ss: SurveyStore,
    private am: AnswersManager,
    private lm: LanguageManager,
  ) {}

  ngOnInit(): void {
    if (this.answers?.zefSurveyUserRating === '1') {
      if (this.ss.funnelData.value.signupSubmitted) {
        this.submit.next(null);
      } else {
        this.step$.next(Step.SIGNUP_QUESTION);
      }
    } else if (this.answers?.zefSurveyUserRating === '0') {
      this.submit.next(null);
    } else {
      this.step$.next(Step.RATING);
    }
  }

  declineSignup() {
    this.ss.updateFunnel({ signupSubmitted: true });
    this.submit.next(null);
  }

  acceptSignup() {
    this.ss.updateFunnel({ signupSubmitted: true });
    const language = this.lm.selectedLanguage;
    window.open(language === 'fi' ? `https://www.zef.fi/fi/` : `https://www.zef.fi/`, '_blank');
  }

  rateDown() {
    this.step$.next(Step.WAIT_DOWN);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.step$.value === Step.WAIT_DOWN) {
        this.submit.next(false);
      }
    }, this.TIMEOUT_DOWN);
  }

  rateUp() {
    this.step$.next(Step.WAIT_UP);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.step$.value === Step.WAIT_UP) {
        this.step$.next(Step.SIGNUP_QUESTION);
        this.submit.next(true);
      }
    }, this.TIMEOUT_UP);
  }
}
