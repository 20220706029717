import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';

import { QuestionData, SliderValuesData } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';

@Component({
  selector: 'a-slider-1d',
  templateUrl: './slider-1d.component.html',
  styleUrls: ['./slider-1d.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleSlider1d implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() groupData?: QuestionData;
  @Input() answerValue: string = null;

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  public answer: number = null;

  get data(): QuestionData {
    return this.groupData || this.questionData || new QuestionData();
  }

  get ariaLabel(): string {
    return `${this.tt.transform(this.questionData?.title, `title-${this.questionData?.$key}`)}: ${this.tt.transform(
      this.data.sliderLabels.axis,
      `axis-${this.data?.$key}`,
    )}: ${this.data.sliderValues.min} = ${this.tt.transform(this.data.sliderLabels.min, `min-${this.data?.$key}`)}, ${
      this.data.sliderValues.max
    } = ${this.tt.transform(this.data.sliderLabels.max, `max-${this.data?.$key}`)}`;
  }

  constructor(private tt: TranslateText) {}

  ngOnChanges() {
    this.answer = this.answerValue
      ? Number(this.answerValue)
      : this.data.type === Questions.SLIDER_1V
        ? this.data.sliderValues.min
        : this.data.sliderValues.min +
          (this.data.sliderValues.max - this.data.sliderValues.min) * this.data.sliderValues.initial;
  }

  onValueChanged($event) {
    this.answer = $event.value;
    this.answerChanged.emit({
      value: this.answer.toString(),
      item: this.questionData,
    });
  }

  public smiley(val: number, sliderValues: SliderValuesData): string {
    return this.data.sliderSmileys
      ? 'smiley-' +
          (val !== sliderValues.min
            ? Math.ceil((val - sliderValues.min) / ((sliderValues.max - sliderValues.min) / 5))
            : 1)
      : '';
  }
}
