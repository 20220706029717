<!--
  Deleted view with static content.

  @stable
-->

<div class="z-container-box z-fx-col z-fx-between-center z-fx-fill">
  <div class="z-header-box z-fx-col z-fx-center-center z-fx-gap-32">
    <div class="z-header-box-logo z-fx-col z-fx-center-center z-fx-flex-center">
      <mat-icon fontSet="zefplayer">placeholder</mat-icon>
    </div>

    <span class="z-title-text">Survey not found</span>
  </div>

  <div class="z-content-box z-fx-col z-fx-end-center z-fx-gap-24">
    <a class="z-zef-logo z-fx z-fx-center-center" href="http://zef.fi" target="_blank">
      <mat-icon fontSet="zefplayer">zeffi_wordmark</mat-icon>
    </a>
  </div>
</div>
