import { BehaviorSubject, catchError, map, throwError } from 'rxjs';

import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InterviewMessageData } from '@player/shared/models/player.model';

import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { QuestionData } from '@shared/models/survey.model';

import { getLastValue } from '@shared/operators/share-ref.operator';

@Directive()
export class WhyFinderBase<T = string> implements OnInit {
  @Input() answer: T = null;
  @Input() errorText = 'Error...';
  @Input() isActive = false;
  @Input() isLast = false;
  @Input() placeholder = '';
  @Input() questionData: QuestionData = null;

  @Input()
  set answerValue(value: T | null) {
    this._answerValue = value;

    this.messages =
      value
        ?.toString()
        .split(this.separator)
        .map((message) => {
          if (message === 'isCompleted:true') {
            this.interviewDone = true;
            return null;
          }
          return message;
        })
        .filter(
          (message) =>
            message?.length > 12 &&
            (message.substring(0, 12) === 'Interviewee:' || message.substring(0, 12) === 'Interviewer:'),
        )
        .map((message) => {
          return {
            role: message.substring(0, 12) === 'Interviewee:' ? 'user' : 'assistant',
            message: message.slice(12),
          };
        }) || [];

    this.showInput =
      getLastValue(this.targetQuestionAnswer) != null &&
      ((!this.interviewDone &&
        (this.questionData?.whyFinder?.maxQuestions || 5) -
          this.messages.filter((m) => m?.role === 'assistant').length >=
          0) ||
        this.messages.slice(-1)?.[0]?.role === 'assistant');

    this.cdRef.detectChanges();
  }

  get answerValue(): T | null {
    return this._answerValue;
  }

  @Output() answerChange: EventEmitter<string> = new EventEmitter();
  @Output() answering = new EventEmitter<boolean>();
  @Output() answerReady = new EventEmitter<void>();
  @Output() saveSummaries = new EventEmitter<any>();
  @Output() nextQuestion = new EventEmitter<void>();

  private _answerValue: T | null = null;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public messages: InterviewMessageData[] = [];
  public showInput: boolean = true;
  public interviewDone: boolean = false;
  public showAllMessages: boolean = false;

  readonly targetQuestionAnswer = this.ss.answers.pipe(
    map((answers) => answers?.[this.questionData?.whyFinder?.target]),
  );
  readonly targetQuestion = this.ss.questions.pipe(
    map((questions) => questions.find((q) => q.$key === this.questionData?.whyFinder?.target)),
  );
  readonly separator: string = '\u001D';

  constructor(
    readonly sa: SurveyAssistant,
    readonly ss: SurveyStore,
    readonly lm: LanguageManager,
    readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if ((this.showInput || !this.interviewDone) && !this.sa.whyFinders?.[this.questionData.$key]) {
      this.initInterviewer(this.messages[this.messages.length - 1]?.role !== 'assistant');
    } else if (
      (this.showInput || !this.interviewDone) &&
      this.sa.whyFinderTargetAnswers?.hasOwnProperty(this.questionData.$key) &&
      this.sa.whyFinderTargetAnswers[this.questionData.$key] !== getLastValue(this.targetQuestionAnswer)
    ) {
      this.initInterviewer(true, true);
    }
  }

  trackByMessage(index: number, message: InterviewMessageData): string {
    return message?.message;
  }

  initInterviewer(ask: boolean, reset: boolean = false): void {
    const language: string = this.lm.languages?.[this.lm.currentLanguage]?.name || 'English';

    if (reset) {
      this.messages = [];
    }

    this.loading$.next(true);
    this.ss.disableScroll.next(true);
    this.sa
      .initWhyFinder(
        this.questionData,
        getLastValue(this.targetQuestion),
        getLastValue(this.targetQuestionAnswer),
        language,
        this.messages,
        ask,
      )
      .pipe(
        catchError((error: any) => {
          this.loading$.next(false);
          this.ss.disableScroll.next(false);
          return throwError(error);
        }),
      )
      .subscribe((result) => {
        const message = result?.[0]?.message;

        if (message) {
          this.messages.push({
            role: message.role,
            message: message.content,
          });
          this.answerChange.emit(
            (!reset ? (this.answerValue || '') + (this.answerValue ? this.separator : '') : '') +
              'Interviewer:' +
              message.content,
          );
        }

        this.loading$.next(false);
        this.ss.disableScroll.next(false);
        this.cdRef.detectChanges();
      });
  }

  getCharsLimit(): number {
    return (this.questionData && this.questionData.charsLimit) || 15000;
  }

  addComment(comment: string): void {
    const actualComment = comment.trim();

    if (actualComment) {
      this.answerChange.emit(
        (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'Interviewee:' + actualComment,
      );

      if (
        (this.questionData?.whyFinder?.maxQuestions || 5) -
          this.messages.filter((m) => m?.role === 'assistant').length >=
        0
      ) {
        this.loading$.next(true);
        this.ss.disableScroll.next(true);

        this.sa
          .nextWhyFinderQuestion(this.questionData, { role: 'user', message: comment })
          .pipe(
            catchError((error: any) => {
              this.loading$.next(false);
              this.ss.disableScroll.next(false);
              return throwError(error);
            }),
          )
          .subscribe((result) => {
            const message = result?.[0]?.message;

            if (message) {
              this.messages.push({
                role: message.role,
                message: message.content,
              });
              this.answerChange.emit(
                (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'Interviewer:' + message.content,
              );
            }

            if (
              this.messages.filter((m) => m?.role === 'assistant').length >= 0 &&
              this.messages.filter((m) => m?.role === 'user').length >= 0
            ) {
              const defaulLanguage: string = this.lm.languages?.[this.lm.defaultLanguage]?.name || 'English';
              this.sa.getWhyFinderSummary(this.questionData, defaulLanguage).subscribe((content) => {
                const isJSON: (string) => boolean = (str) => {
                  try {
                    JSON.parse(str);
                  } catch (e) {
                    return false;
                  }
                  return true;
                };
                const parsedContent = isJSON(content?.[0]?.message?.function_call?.arguments)
                  ? JSON.parse(content[0].message.function_call.arguments)
                  : null;

                if (parsedContent) {
                  for (const key in parsedContent) {
                    parsedContent[key] = Array.isArray(parsedContent[key])
                      ? parsedContent[key].join(';')
                      : parsedContent[key];
                  }
                  if (!this.interviewDone && parsedContent?.interviewDone) {
                    this.interviewDone = parsedContent?.interviewDone;

                    this.answerChange.emit(
                      (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'isCompleted:true',
                    );
                  }
                  this.saveSummaries.emit(parsedContent);
                }
              });
            }

            this.loading$.next(false);
            this.ss.disableScroll.next(false);
            this.cdRef.detectChanges();
          });
      }
    }
  }

  onValueChanged(): void {
    /* This seems to trigger the needed change detection for input value in template */
  }
}
