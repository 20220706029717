/**
 * Manages answer data for the player.
 *
 * @unstable
 */

import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';

import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { LocalStorage, SessionStorage } from 'ngx-webstorage';

import {
  Answer,
  Answerer,
  Answers,
  AnswerSession,
  DataType,
  PlayerAnswer,
  PlayerAnswers,
  PlayerData,
  PlayerOutcome,
  PlayerStorageData,
  PlayerStorageItem,
  PlayerSurveyData,
  Results,
  SessionData,
} from '@player/shared/models/player.model';
import { PlayerApi } from '@player/shared/services/player-api.service';
import { HistoryState } from '@player/shared/services/history-state.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';

import { Questions } from '@shared/enums/questions.enum';
import { QuestionData, SurveyData } from '@shared/models/survey.model';
import { DateTimeProvider } from '@player/shared/services/date-time-provider.service';

@Injectable({
  providedIn: 'root',
})
export class AnswersManager implements OnDestroy {
  readonly debounceTime = 100;
  readonly throttleTime = 1000;

  private readonly demo = 'demo';
  readonly sessionTimeout = 5 * 60 * 1000;
  readonly storageTimeout = 24 * 60 * 60 * 1000;

  private time: number = 0;
  private stime: number = 0;

  private unloading?: boolean;

  private linkKey: string = null;
  private pollSession: string = null;
  private teamKey: string = null;
  private surveyKey: string = null;

  private linkHashTags: string[] = [];

  private requestSync = new Subject<void>();
  private syncing = new BehaviorSubject<boolean>(false);

  private syncSub = this.requestSync
    .pipe(
      debounceTime(this.debounceTime),
      throttleTime(this.throttleTime, undefined, { leading: true, trailing: true }),
      switchMap(() =>
        this.syncing.pipe(
          filter((syncing) => !syncing),
          take(1),
        ),
      ),
      debounceTime(10),
      concatMap(() => this.nz.run(() => this.syncData())),
    )
    .subscribe();

  private readonly destroy$ = new Subject<void>();

  @LocalStorage('answers') answersData?: PlayerSurveyData<Answers>;
  @LocalStorage('results') resultsData?: PlayerSurveyData<Results>;
  @LocalStorage('answerer') answererData?: PlayerSurveyData<Answerer>;
  @SessionStorage('session') sessionData?: SessionData<AnswerSession>;

  private getTimestamp = (): number => this.stime + (Date.now() - this.time);

  private getNewSessionId = (): string => Math.random().toString(36).substr(2, 9);

  respondentFields?: Record<string, string>;

  constructor(
    readonly pa: PlayerApi,
    readonly hs: HistoryState,
    readonly nz: NgZone,
    readonly lm: LanguageManager,
    private dt: DateTimeProvider,
  ) {
    this.pa.uploadTrigger$
      .pipe(
        mergeMap((key) => this.saveUpload(key)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.pa.deleteTrigger$
      .pipe(
        mergeMap((key) => this.deleteUpload(key)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public init(
    teamKey: string,
    surveyKey: string,
    linkKey: string,
    linkHashTags: string[],
    pollSession: string,
  ): Observable<void> {
    if (!surveyKey) {
      return throwError('No survey key');
    }

    this.linkKey = linkKey;
    this.teamKey = teamKey;
    this.surveyKey = surveyKey;
    this.pollSession = pollSession;
    this.linkHashTags = linkHashTags;

    this.initData();
    this.time = Date.now();

    return this.getAnswerSession().pipe(
      tap(() => this.cleanUp()),
      concatMap(() => this.syncData()),
      map(() => void 0),
    );
  }

  public saveData(): Observable<unknown> {
    const sync = this.syncData();
    sync.subscribe();

    return sync.pipe(shareReplay(1));
  }

  public hasPendingUploads(): boolean {
    return !!this.pa.getPendingUploads().length;
  }

  public clearAnswers(): void {
    this.syncData().subscribe();

    let session = this.getSession();

    session = {
      session: this.getNewSessionId(),
      answerer: null,
      identity: (session && session.identity) || '',
    };

    this.setSession(session);

    this.setAnswers({});
    this.setAnswerer({
      identity: session.identity,
    } as Answerer);

    this.getAnswerSession().subscribe();
  }

  public shutdown(unsubscribe?: boolean): void {
    if (!this.unloading) {
      this.unloading = true;
      this.syncData().subscribe();
    }

    if (unsubscribe) {
      this.syncSub.unsubscribe();
    }
  }

  public resume(): void {
    this.unloading = false;
  }

  public setAnswer(newAnswer: PlayerAnswer): void {
    const { $key, type: questionType } = newAnswer.item;
    const type = Questions.AnswerTypes[questionType];

    const session = this.getSession()?.session;
    const answers = this.getAnswers() || {};
    const started = Object.keys(answers).length > 0;
    const answer = (answers[$key] = answers[$key] || {
      timestamp: this.time,
      activated: Date.now() - this.time,
      session,
      survey: this.surveyKey,
      question: $key,
      questionType,
      started: null,
      finished: null,
      answered: null,
      synced: null,
      value: null,
      type,
    });

    let newData: Partial<Answer>;

    if (newAnswer.value !== undefined && newAnswer.value !== answer.value) {
      newData = {
        survey: this.surveyKey,
        question: $key,
        started: answer.activated,
        finished: null,
        answered: Date.now() - this.time,
        session,
        synced: null,
        value: newAnswer.value,
        type,
      };
    } else if (answer.finished < answer.started && answer.started === answer.activated) {
      newData = {
        finished: Date.now() - this.time,
        synced: null,
      };
    } else {
      newData = {
        activated: Date.now() - this.time,
      };
    }

    if (!answer.questionType) {
      // Backend doesn't return questionType
      newData.questionType = questionType;
    }

    const isNps = Questions.isNps({ type: questionType });

    if (isNps && newData.value !== undefined) {
      newData.scaled =
        newData.value !== null && newData.value.toString() ? Math.round(+newData.value).toString() : null;
    } else if (!isNps && answer.scaled != null) {
      // Scaled value appears when loading answers from localstorage
      // Scaled value should be saved only to NPS question from player
      delete answer.scaled;
    }

    Object.assign(answer, newData);

    const answerer = this.getAnswerer();

    if (answerer) {
      answerer.activated = this.stime;
      answerer.finished = this.getTimestamp();

      if (!started) {
        answerer.started = this.getTimestamp();
      }

      if (newAnswer.progress != null) {
        answerer.progress = newAnswer.progress;
      }
    }

    this.setAnswers(answers);
    this.setAnswerer(answerer);

    this.nz.runOutsideAngular(() => this.requestSync.next());
  }

  public setPlayerResults(results: PlayerOutcome[]) {
    if (!this.surveyKey) {
      return;
    }

    this.setResults(
      results
        .slice(0, 10)
        .map((result, order) => ({
          synced: null,
          order,
          score: result.score,
          correct: result.correct?.correct,
          maxCorrect: result.correct?.max,
          timestamp: this.time,
          outcome: result.item.$key,
        }))
        .reduce((a, b, index) => {
          a[index] = b;
          return a;
        }, {} as Results),
    );

    this.nz.runOutsideAngular(() => this.requestSync.next());
  }

  public getFunnelAnswers(survey: SurveyData): PlayerAnswers {
    const answerData = this.getAnswers();

    if (!this.surveyKey || !answerData || !survey) {
      return {};
    }

    const { funnel } = survey;

    if (funnel && 'zefSurveyUserRating' in answerData) {
      return { zefSurveyUserRating: answerData.zefSurveyUserRating?.value || null };
    }
  }

  public getQuestionAnswers(questions: QuestionData[] = []): PlayerAnswers {
    const answerData = this.getAnswers();

    if (!this.surveyKey || !answerData || !questions.length) {
      return {};
    }

    const answerKeys = Object.keys(answerData);

    return questions
      .filter(({ $key, type }) => answerKeys.includes($key) && answerData[$key].type === Questions.AnswerTypes[type])
      .reduce((answers, { $key }) => {
        answers[$key] = answerData[$key].value != null ? answerData[$key].value : null;
        return answers;
      }, {} as PlayerAnswers);
  }

  public getAllAnswers(data: PlayerData) {
    const { questions, survey } = data;

    return {
      ...this.getQuestionAnswers(questions),
      ...this.getFunnelAnswers(survey),
    };
  }

  public getCurrentAnswererId(): string | number {
    const data = this.getAnswerer();
    return (data && data.id) || 0;
  }

  getSurveyKey(): string {
    return this.surveyKey;
  }

  getLinkKey(survey?: string, session?: string): string {
    const answerer = this.getAnswerer(survey, session);
    const isSameSurvey = !survey || this.surveyKey === survey;

    return answerer ? answerer.linkKey : isSameSurvey ? this.linkKey : '';
  }

  getTeamKey(survey?: string, session?: string): string {
    const answerer = this.getAnswerer(survey, session);
    const isSameSurvey = !survey || this.surveyKey === survey;

    return answerer ? answerer.teamKey : isSameSurvey ? this.teamKey : '';
  }

  sendFunnelInvite(email: string, funnelId: string) {
    const answerer = this.getAnswerer();
    const team = this.getTeamKey();

    return this.pa.post('trigger/funnel_invite', { email, answerer, team, funnelId });
  }

  public isAnonymous() {
    const { identity } = this.getSession() || ({} as AnswerSession);
    return this.linkHashTags.length === 0 && (!identity || identity === 'null');
  }

  public fileUploadRaw(question: QuestionData, file: File): Observable<any> {
    const session = this.getSession()?.session;
    const answerer = this.getAnswerer();
    const params = {
      question: question.$key,
      answerer,
      team: this.getTeamKey(),
      survey: this.surveyKey,
      link: this.getLinkKey(),
      session,
    };

    return this.pa.uploadFileRaw(params, file);
  }

  private getSurveySession(
    survey?: string,
    session?: string,
  ): { survey: string; session: string; identity: string | null } {
    const { session: sessionId, identity } = this.getSession(survey) || { session, identity: '' };

    return {
      survey: survey || this.surveyKey,
      session: session || sessionId,
      identity,
    };
  }

  private syncData(): Observable<void[]> {
    let sync$: Observable<void[]> = of(void 0).pipe(tap(() => this.syncing.next(true)));

    const outOfSyncAnswers = this.getOutOfSync(DataType.Answers);
    const outOfSyncResults = this.getOutOfSync(DataType.Results);

    if (outOfSyncAnswers.length || outOfSyncResults.length) {
      sync$ = sync$.pipe(
        concatMap(() => {
          const surveySessions = outOfSyncAnswers.concat(outOfSyncResults).reduce(
            (all, { survey, session }) => {
              if (!all[survey]) {
                all[survey] = [session];
              } else if (!all[survey].includes(session)) {
                all[survey].push(session);
              }

              return all;
            },
            {} as { [survey: string]: string[] },
          );

          return forkJoin(
            Object.entries(surveySessions).reduce((preps, [survey, sessions]) => {
              preps.push(...sessions.map((session) => this.prepareSync(survey, session)));

              return preps;
            }, []),
          );
        }),
        concatMap(() =>
          forkJoin([
            ...outOfSyncAnswers.map(({ survey, session }) =>
              this.saveAnswers(survey, session).pipe(catchError((e) => this.handleSaveError(e, 'answers'))),
            ),
            ...outOfSyncResults.map(({ survey, session }) =>
              this.saveResults(survey, session).pipe(catchError((e) => this.handleSaveError(e, 'results'))),
            ),
          ]),
        ),
      );
    }

    return sync$.pipe(tap(() => this.syncing.next(false)));
  }

  private getOutOfSync(type: DataType): { survey: string; session: string }[] {
    const answerSession: AnswerSession = this.getSession();

    if (!answerSession) {
      return [];
    }

    const sessionId = answerSession?.session;

    const data = this.getDataSource(type);

    return Object.keys(data || {})
      .filter((survey) => !!survey)
      .map((survey) =>
        Object.keys(data[survey])
          .filter((session) => {
            if (!session) {
              return false;
            }

            const surveyData = this.getData<Answers | Results>(type, survey, session);
            const isCurrent = survey === this.surveyKey && session === sessionId;

            return Object.values(surveyData || {}).some(
              (item, _, items) =>
                item &&
                !item.synced &&
                (type !== DataType.Answers ||
                  (item.answered != null && items.some((q) => !Questions.info({ type: q.questionType })))) &&
                (isCurrent || this.time - item.timestamp > this.sessionTimeout),
            );
          })
          .map((session) => ({ survey, session })),
      )
      .reduce((a, b) => a.concat(b), []);
  }

  private prepareSync(survey: string, session: string): Observable<void> {
    return this.getAnswerer(survey, session)?.id ? of(void 0) : this.getAnswerSession(survey, session);
  }

  private getAnswerSession(surveyKey?: string, sessionKey?: string): Observable<void> {
    const { survey, session, identity: surveyIdentity } = this.getSurveySession(surveyKey, sessionKey);
    const { session: sessionId, identity } = this.getSession(surveyKey) || {
      session: sessionKey || session,
      identity: surveyIdentity,
    };
    const isDemo = survey === this.demo;

    const answerer: Answerer = isDemo ? null : this.getAnswerer(survey, session);

    const params = {
      link: this.getLinkKey(survey, session),
      team: this.getTeamKey(survey, session),
      identity: answerer?.identity || identity,
      id: answerer?.id,
      persist: 'true',
    };

    const isCurrentSurvey = this.surveyKey === survey;
    const isCurrentSession = sessionId === session;

    return this.pa.getAnswererId(survey, session, params, isDemo).pipe(
      map((response) => {
        if (isCurrentSurvey) {
          if (response?.status === 'closed') {
            return window.location.assign('/offline');
          }

          if (response?.status === 'identity-required') {
            return window.location.assign('/identity-required');
          }
        }

        this.setAnswerer(
          {
            ...response.answerer,
            identity: answerer?.identity || identity,
          },
          survey,
          session,
        );

        if (isCurrentSurvey && isCurrentSession) {
          this.stime = response.timestamp || this.time;
          this.dt.date$.next(new Date(this.stime));
          this.loadAnswers(response.answers);
        }
      }),
      catchError((e) => this.handleServerError(e)),
    );
  }

  private loadAnswers(answersData?: Answers): void {
    if (answersData) {
      const answers = this.getAnswers() || {};

      Object.values(answersData).forEach((answer) => {
        answers[answer.question] = {
          timestamp: this.time,
          session: this.getSession()?.session,
          synced: Date.now(),
          ...answer,
        };
      });

      this.setData(DataType.Answers, answers);
    }
  }

  private saveUpload(key: string): Observable<void> {
    const session = this.getSession()?.session;
    const answerer = this.getAnswerer();

    if (answerer) {
      this.syncAnswerer(answerer);
    }

    return this.pa
      .uploadFile({
        question: key,
        answerer,
        team: this.getTeamKey(),
        survey: this.surveyKey,
        link: this.getLinkKey(),
        session,
      })
      .pipe(
        map((response) => {
          if (response) {
            if (response.answerer && typeof response.answerer.time === 'string') {
              answerer.timestamp = new Date(response.answerer.time).getTime();
            }

            this.setAnswerer({ ...answerer, ...response.answerer }, this.surveyKey, session);
          }
        }),
      );
  }

  private deleteUpload(key: string): Observable<void> {
    const session = this.getSession()?.session;
    const answerer = this.getAnswerer();

    return this.pa.deleteUpload({
      question: key,
      respondent: answerer?.id,
      team: this.getTeamKey(),
      survey: this.surveyKey,
      session,
    });
  }

  private saveAnswers(survey: string, session: string): Observable<void> {
    const answers = this.getAnswers(survey, session);
    const answerer = this.getAnswerer(survey, session);
    const notSynced = Object.values(answers).filter(({ synced }) => !synced);

    notSynced.forEach((answer) => (answer.synced = false));

    if (survey === this.demo) {
      notSynced.forEach((answer) => (answer.synced = Date.now()));

      this.setAnswers(answers, survey, session);
      this.setAnswerer({ ...answerer, key: survey, timestamp: Date.now() } as Answerer, survey, session);

      return of(void 0);
    } else {
      const { session: sessionId } = this.getSession();

      if (answerer && sessionId === session && this.surveyKey === survey) {
        this.syncAnswerer(answerer);
      }

      this.setAnswers(answers, survey, session);

      return this.pa
        .post(
          'save/save_answers',
          {
            answerer,
            answers: notSynced,
            link: this.getLinkKey(survey, session),
            team: this.getTeamKey(survey, session),
            session: session || this.getSession(survey)?.session,
            timestamp: this.getTimestamp(),
            ...(this.pollSession ? { pollSession: this.pollSession } : {}),
            ...(this.respondentFields ? { fields: this.respondentFields } : {}),
          },
          this.unloading,
        )
        .pipe(
          map((response) => {
            if (this.unloading) {
              return;
            }

            if (!response || !response.answerer) {
              throw new Error(response);
            }

            const { answerer: syncedAnswerer, answers: responseAnswers } = response;

            const syncedAnswers = this.getAnswers(survey, session);
            const questions = responseAnswers.map(({ question }) => question);

            Object.entries(syncedAnswers || {}).forEach(([question, answer]) => {
              if (questions.includes(question) && answer.synced === false) {
                answer.synced = Date.now();
              }
            });

            if (syncedAnswerer && typeof syncedAnswerer.time === 'string') {
              syncedAnswerer.timestamp = new Date(syncedAnswerer.time).getTime();
            }

            const newAnswerer = this.getAnswerer(survey, session);

            this.setAnswers(syncedAnswers, survey, session);
            this.setAnswerer(
              { ...answerer, ...syncedAnswerer, progress: newAnswerer.progress, finished: newAnswerer.finished },
              survey,
              session,
            );
          }),
        );
    }
  }

  private syncAnswerer(answerer: Answerer): void {
    const { session: sessionId } = this.getSession();

    answerer.location = location.href;
    answerer.language = this.lm.currentLanguage;
    answerer.hashtags = this.linkHashTags;

    this.setAnswerer(answerer, this.surveyKey, sessionId);
  }

  private saveResults(survey: string, session: string): Observable<void> {
    const results = this.getResults(survey, session);
    const answerer = this.getAnswerer(survey, session);

    let saving$: Observable<void> = of(void 0);

    const resultsArray = Object.values(results).map((resultData) => {
      resultData.synced = false;

      return resultData;
    });

    this.setResults(results, survey, session);

    if (survey !== this.demo) {
      saving$ = this.pa
        .post(
          'save/save_results',
          {
            results: resultsArray,
            answerer,
            team: this.getTeamKey(survey, session),
            timestamp: this.getTimestamp(),
            ...(this.pollSession ? { pollSession: this.pollSession } : {}),
          },
          this.unloading,
        )
        .pipe(
          map((response) => {
            if (!this.unloading && (!response || !response.answerer)) {
              throw new Error(response);
            }
          }),
        );
    }

    return saving$.pipe(
      tap(() => {
        Object.values(results).forEach((resultData) => {
          resultData.synced = resultData.synced === false ? Date.now() : resultData.synced;
        });

        this.setResults(results, survey, session);
      }),
    );
  }

  private cleanUp(): void {
    this.cleanUpData(DataType.Answerer, 'finished');
    this.cleanUpData(DataType.Answers, 'timestamp');
    this.cleanUpData(DataType.Results, 'timestamp');
  }

  private cleanUpData(type: DataType, timeProp: 'finished' | 'timestamp'): void {
    const data = { ...this.getDataSource(type) };
    const { session: sessionId } = this.getSession();

    Object.keys(data).forEach((survey) => {
      const surveyData = { ...data[survey] };
      const sessions = Object.keys(surveyData || {});

      if (Array.isArray(surveyData) || typeof surveyData !== 'object' || !sessions.length) {
        delete data[survey];
      } else {
        sessions
          .filter((session) => {
            if (session === sessionId && this.surveyKey === survey) {
              return false;
            }

            const targetData = this.getData(type, survey, session) || {};
            const targetDataItems = type === DataType.Answerer ? [targetData] : Object.values(targetData);

            return targetDataItems.every((item: PlayerStorageItem, _, items: PlayerStorageItem[]) => {
              if (!item?.hasOwnProperty(timeProp)) {
                return true;
              }

              const timeout = this.time - this.storageTimeout;

              if (item[timeProp] && item[timeProp] < timeout) {
                return true;
              }

              if (type !== DataType.Answerer) {
                const answererExists = !!this.getData(DataType.Answerer, survey, session);

                if (!answererExists) {
                  return true;
                }
              }

              if (type !== DataType.Answers) {
                return false;
              }

              if (item.timestamp && item.timestamp < timeout) {
                return !items.some(
                  (otherItem: Answer) => !Questions.info({ type: otherItem.questionType }) && otherItem.value != null,
                );
              }

              return false;
            });
          })
          .forEach((session) => {
            delete data[survey][session];
          });

        if (!Object.keys(data[survey]).length) {
          delete data[survey];
        }
      }
    });

    this.setDataSource(type, data);
  }

  private initData(): void {
    const session = this.getSession() || {};

    session.session = session.session || this.getNewSessionId();
    session.identity = session.identity || this.hs.getParam('zefId', true) || '';

    const hashTags: string[] = (location.hash && decodeURIComponent(location.hash).match(/#[^#]+/g)) || [];
    this.linkHashTags = hashTags.concat(this.linkHashTags);

    this.setSession(session);
    this.setAnswers(this.getAnswers() || {});
    this.setResults(this.getResults() || {});
    this.setAnswerer(
      this.getAnswerer() ||
        ({
          teamKey: this.teamKey,
          linkKey: this.linkKey,
          identity: session.identity,
        } as Answerer),
    );
  }

  private setAnswers(answers: Answers, survey?: string, session?: string): void {
    this.setData(DataType.Answers, answers, survey, session);
  }

  private setResults(results: Results, survey?: string, session?: string): void {
    this.setData(DataType.Results, results, survey, session);
  }

  private setAnswerer(answerer: Answerer, survey?: string, session?: string): void {
    this.setData(DataType.Answerer, answerer, survey, session);
  }

  private setSession(session: AnswerSession, surveyKey?: string): void {
    const { survey } = this.getSurveySession(surveyKey);
    const source = this.sessionData || {};
    source[survey] = session;
    this.sessionData = { ...source };
  }

  private setData<T extends PlayerStorageData>(type: DataType, data: T, surveyKey?: string, sessionKey?: string): void {
    const { survey, session } = this.getSurveySession(surveyKey, sessionKey);
    const source = this.getDataSource(type) || {};

    if (type === DataType.Answerer && this.surveyKey === survey) {
      data ||= {} as T;

      if (!data.teamKey) {
        data.teamKey = this.teamKey;
      }

      if (!data.linkKey) {
        data.linkKey = this.linkKey;
      }
    }

    source[survey] = source[survey] || {};

    // legacy fallback
    if (Array.isArray(source[survey])) {
      source[survey] = (source[survey] as unknown as []).reduce((allData, surveyData, index) => {
        allData[index] = surveyData;
        return allData;
      }, {});
    }

    source[survey][session] = data;

    this.setDataSource(type, source);
  }

  private setDataSource<T extends PlayerStorageData>(type: DataType, data: PlayerSurveyData<T>): void {
    data = { ...data } as PlayerSurveyData<T>;

    switch (type) {
      case DataType.Answers:
        this.answersData = data as PlayerSurveyData<Answers>;
        break;
      case DataType.Results:
        this.resultsData = data as PlayerSurveyData<Results>;
        break;
      case DataType.Answerer:
        this.answererData = data as PlayerSurveyData<Answerer>;
        break;
    }
  }

  private getSession(survey?: string): AnswerSession | undefined {
    const source = this.sessionData;
    survey = survey || this.surveyKey;
    return source && source[survey];
  }

  private getAnswers(survey?: string, session?: string): Answers | undefined {
    return this.getData<Answers>(DataType.Answers, survey, session);
  }

  private getResults(survey?: string, session?: string): Results | undefined {
    return this.getData<Results>(DataType.Results, survey, session);
  }

  private getAnswerer(survey?: string, session?: string): Answerer | undefined {
    return this.getData<Answerer>(DataType.Answerer, survey, session);
  }

  private getData<T extends PlayerStorageData>(type: DataType, surveyKey?: string, sessionKey?: string): T | undefined {
    const { survey, session } = this.getSurveySession(surveyKey, sessionKey);
    const source = this.getDataSource<T>(type);

    return source && source[survey] && (source[survey][session] as T | undefined);
  }

  private getDataSource<T extends PlayerStorageData>(type: DataType): PlayerSurveyData<T> {
    switch (type) {
      case DataType.Answers:
        return this.answersData as PlayerSurveyData<T>;
      case DataType.Results:
        return this.resultsData as PlayerSurveyData<T>;
      case DataType.Answerer:
        return this.answererData as PlayerSurveyData<T>;
    }
  }

  private handleSaveError(error: any, target: string): Observable<void> {
    return this.handleServerError(`Saving ${target} failed`, error);
  }

  private handleServerError(...args): Observable<void> {
    console.error(...args);

    return of(void 0);
  }
}
