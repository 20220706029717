import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';

import { QuestionData, ChoiceItemData } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';

@Component({
  selector: 'a-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleChoice implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() answerValue: string = '';

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  readonly Questions = Questions;

  public answer: string = '';

  public singleCheckbox: boolean = false;
  public radioAnswer: string = '';
  public multiSelections: { [s: string]: boolean } = {};
  public otherOptionAnswer: string = '';
  public limitReached: boolean = false;

  get data(): QuestionData {
    return this.questionData || new QuestionData();
  }

  public options: ChoiceItemData[];

  constructor() {}

  ngOnChanges() {
    const choices = (this.questionData && this.questionData.choiceList) || [];

    const otherIdx = choices.findIndex((choice) => choice.$key === 'other');

    if (otherIdx > -1 && otherIdx < choices.length - 1) {
      choices.push(choices.splice(otherIdx, 1)[0]);
    }

    this.options = choices;
    this.answer = this.answerValue || '';
    this.radioAnswer = this.answer.toString().split('=')[0];

    if (this.answer.indexOf('=') >= 0) {
      const split = this.answer.split(';');

      const newArr = split
        .map((option, index, arr) => {
          let newOption = option;

          if (
            option.indexOf('=') >= 0 &&
            index < arr.length &&
            this.options.findIndex((o) => o.$key === option.split('=')[0])
          ) {
            for (let n = index + 1; n < arr.length; n++) {
              if (this.options.findIndex((o) => o.$key === split[n]) < 0) {
                newOption += ';' + split[n];
              } else {
                break;
              }
            }
          }

          return newOption;
        })
        .filter((option) => this.options.findIndex((o) => o.$key === option.split('=')[0]) >= 0);

      const otheroptionTextArr = newArr.find((o) => o.indexOf('=') >= 0).split('=');
      otheroptionTextArr.shift();
      this.otherOptionAnswer = otheroptionTextArr.join('=');
    }

    if (this.questionData.type === Questions.CHOICE_MULTI || this.questionData.type === Questions.CHOICE_TEXT) {
      for (const option of this.options) {
        this.multiSelections[option.$key] = this.answer.indexOf(option.$key) >= 0;
      }
      this.updateLimitReach();
    }

    if (this.questionData.type === Questions.INPUT_CHECKBOX) {
      if (this.answerValue === undefined) {
        this.singleCheckbox = this.questionData.inputField?.value === 'true' || false;
        this.onSingleCheckboxValueChanged();
      } else {
        this.singleCheckbox = this.answerValue === 'true' || false;
      }
    }
  }

  public onSingleSelectionValueChanged($event) {
    if ($event.value) {
      const otherOption: string =
        this.options.find((op) => op.$key === $event.value)?.comment && this.otherOptionAnswer
          ? `=${this.otherOptionAnswer}`
          : '';
      this.answer = $event.value + otherOption;

      this.answerChanged.emit({
        value: this.answer,
        item: this.questionData,
      });
    }
  }

  public otherOptionValueChanged(option, otherOption: string = '') {
    const choices = this.answer.split(';');

    for (let i = 0, len = choices.length; i < len; i++) {
      if (choices[i].startsWith(option)) {
        choices[i] = `${option}=${otherOption}`;
      }
    }

    this.answerChanged.emit({
      value: choices.join(';'),
      item: this.questionData,
    });
  }

  public onSingleCheckboxValueChanged() {
    this.answer = this.singleCheckbox.toString();
    this.answerChanged.emit({
      value: this.answer,
      item: this.questionData,
    });
  }

  public onMultiSelectionValueChanged() {
    let answer: string = '';
    for (const option in this.multiSelections) {
      if (this.multiSelections[option]) {
        if (answer) {
          answer += ';';
        }
        answer += option;
      }
    }

    this.updateLimitReach();

    this.answer = answer;
    this.answerChanged.emit({
      value: this.answer,
      item: this.questionData,
    });
  }

  private updateLimitReach() {
    let count: number = 0;

    for (const option in this.multiSelections) {
      if (this.multiSelections[option]) {
        count++;
      }
    }

    if (this.questionData.choiceLimit > 1 && count >= this.questionData.choiceLimit) {
      this.limitReached = true;
    } else if (this.questionData.choiceLimit > 1 && count < this.questionData.choiceLimit) {
      this.limitReached = false;
    }
  }

  public trackByChoice(index: number, item: ChoiceItemData): string {
    return item.$key;
  }
}
