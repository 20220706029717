import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SurveyStatusComponent } from '@player/shared/components/survey-status/survey-status.component';

@Component({
  selector: 'offline-view',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SurveyStatusComponent],
})
export class OfflineView {}
