import { Component, EventEmitter, Output, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { isEmail, isEmptyOrEmail } from '@shared/utilities/validation.utilities';
import { AnswersManager } from '@player/shared/services/answers-manager.service';

enum Step {
  NONE,
  RATING,
  WAIT_UP,
  WAIT_DOWN,
  EMAIL_INPUT,
  EMAIL_CONFIRM,
}

@Component({
  selector: 'zef-funnel-one',
  templateUrl: './funnel-one.component.html',
  styleUrls: ['./funnel-one.component.scss'],
})
export class FunnelOne implements OnInit {
  readonly Step = Step;
  readonly TIMEOUT_DOWN = 2250;
  readonly TIMEOUT_UP = 1250;

  ratingQuestionText = `Did you like answering this survey?`;
  emailQuestionText = `Would you be interested in creating your own survey easily? Try it free now:`;
  emailErrorText = `Hmm… The Email doesn't look valid.`;
  emailButtonText = `Ok`;
  emailConfirmationText = `You've got an invitation in your email to start using Zeffi`;
  placeholderDesktopText = 'Enter your work email and you are in...';
  placeholderMobileText = 'Enter your work email...';
  private timeout?: any;
  step$ = new BehaviorSubject(Step.NONE);

  public isValid = true;
  public isValidEmail = false;
  @Input() email = null;
  @Input() answers;

  @Output() submit = new EventEmitter();

  @ViewChild('input')
  emailInput: ElementRef<HTMLInputElement>;

  constructor(
    readonly ss: SurveyStore,
    private am: AnswersManager,
  ) {}

  ngOnInit(): void {
    this.isValidEmail = isEmail(this.email);

    if (this.answers?.zefSurveyUserRating === '1') {
      if (this.ss.funnelData.value.emailSubmitted) {
        this.submit.next(null);
      } else {
        this.step$.next(Step.EMAIL_INPUT);
      }
    } else if (this.answers?.zefSurveyUserRating === '0') {
      this.submit.next(null);
    } else {
      this.step$.next(Step.RATING);
    }
  }

  rateDown() {
    this.step$.next(Step.WAIT_DOWN);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.step$.value === Step.WAIT_DOWN) {
        this.submit.next(false);
      }
    }, this.TIMEOUT_DOWN);
  }

  rateUp() {
    this.step$.next(Step.WAIT_UP);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.step$.value === Step.WAIT_UP) {
        this.step$.next(Step.EMAIL_INPUT);
        this.ss.updateFunnel({ emailShown: true });
        this.submit.next(true);
        setTimeout(() => this.emailInput?.nativeElement.focus());
      }
    }, this.TIMEOUT_UP);
  }

  updateValidity(value: string) {
    this.isValidEmail = isEmail(value);
  }

  checkValidity(value: string) {
    this.isValidEmail = isEmail(value);
    this.isValid = isEmptyOrEmail(value);
  }

  resetValidity(value: string) {
    this.isValid = true;
    this.isValidEmail = isEmail(value);
  }

  submitEmail(email: string) {
    if (this.isValidEmail) {
      const funnelId = 'zef-funnel-one';

      this.am.sendFunnelInvite(email, funnelId).subscribe(
        () => {
          this.step$.next(Step.EMAIL_CONFIRM);
          this.ss.updateFunnel({ emailSubmitted: true });
        },
        () => {
          this.submit.next(null);
        },
      );
    }
  }

  alignmentClass(node: HTMLElement) {
    const isMultiline = node?.offsetHeight > 32;
    return isMultiline ? 'z-fx-between-start' : 'z-fx-between-center';
  }
}
