import { combineLatest, fromEvent, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  skip,
  take,
  takeUntil,
} from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';

import { ChangeDetectionStrategy, Component, OnInit, Optional } from '@angular/core';

import { PlayerInit } from '@player/shared/services/player-init.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SharesManager } from '@player/shared/services/shares-manager.service';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { QuestionData } from '@shared/models/survey.model';
import { PlayerAnswer, PlayerAnswers, PlayerOutcome } from '@player/shared/models/player.model';

import { CommunicationManager } from '@player/shared/services/communication-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';

import { PropertyStore } from '@shared/services/property-store.service';

import { Questions } from '@shared/enums/questions.enum';
import { Outcomes } from '@shared/enums/outcomes.enum';

@Component({
  selector: 'accessible-view',
  templateUrl: './accessible.component.html',
  styleUrls: ['./accessible.component.scss'],
  providers: [LifecycleHooks, SurveyStore, SharesManager, SurveyAssistant],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleView implements OnInit {
  readonly Questions = Questions;
  readonly Outcomes = Outcomes;
  readonly requiredUnanswered = combineLatest([this.ss.normalQuestions, this.ss.allAnswers]).pipe(
    map(([questions, answers]) =>
      questions.filter(({ required, $key }) => required && (answers[$key] == null || answers[$key] === '')),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public selectedLanguage: string = 'en';

  public loaded: boolean = false;
  public reviewModeOn: boolean = false;
  public showOutcomes: boolean = false;
  public touchDevice: boolean = false;

  private firstInit?: boolean;

  constructor(
    readonly hooks: LifecycleHooks,
    readonly ps: PropertyStore,
    readonly sm: SharesManager,
    readonly ss: SurveyStore,
    public lm: LanguageManager,
    private pi: PlayerInit,
    private route: ActivatedRoute,
    private router: Router,
    @Optional() private cm?: CommunicationManager,
  ) {
    fromEvent(window, 'touchstart')
      .pipe(
        catchError(() => of(null)),
        first(),
      )
      .subscribe(() => {
        this.touchDevice = true;
      });
  }

  ngOnInit() {
    document
      .getElementById('viewport')
      ?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=10.0');

    this.pi.initializationDone.pipe(takeUntil(this.hooks.destroy)).subscribe((done) => {
      if (done) {
        this.ss.onPlayerInactivity(() => this.onResetAnswers());

        if (this.pi.language) {
          this.lm.paramsLanguage = this.pi.language;
        }

        this.lm.autodetectLanguage = !!this.pi.data.design.sidebar?.selectLanguage;
        this.lm.init(this.pi.data.languages, this.pi.data.survey.language, this.pi.location);
        this.sm.init(this.pi.team, this.pi.survey, this.pi.release);
        this.ps.init();

        this.ss.languages.next(this.pi.data.languages);
        this.ss.survey.next(this.pi.data.survey);
        this.ss.design.next(this.pi.data.design);
        this.ss.release.next(this.pi.data.release);
        this.ss.sharing.next(this.pi.data.sharing);
        this.ss.scoring.next(this.pi.data.scoring);
        this.ss.outcomes.next(this.pi.data.outcomes);
        this.ss.questions.next(this.pi.data.questions);
        this.ss.triggers.next(this.pi.data.triggers);
        this.ss.answers.next(this.pi.data.answers);
        this.ss.team.next(this.pi.data.team);

        this.loaded = true;

        if (!this.firstInit) {
          this.firstInit = true;

          combineLatest([
            this.ss.languages,
            this.ss.survey.pipe(
              map((survey) => survey && survey.language),
              distinctUntilChanged(),
            ),
          ])
            .pipe(skip(1), takeUntil(this.hooks.destroy))
            .subscribe(([languages, surveyLanguage]) => {
              this.lm.updateLocales(languages, surveyLanguage);
            });

          this.cm?.emitStartEvent();
        }
      }
    });

    this.ss.scoredOutcomes.pipe(skip(1), takeUntil(this.hooks.destroy)).subscribe((results: PlayerOutcome[]) => {
      this.pi.onResultsChanged(results);
      this.cm?.emitOutcomeEvent(results);
    });
  }

  trackBy$Key = (idx: number, question: QuestionData): string => question.$key;

  readonly groupData = (question) =>
    this.ss.questions.pipe(
      map((questions) => Questions.getGroup(question, questions)),
      filter((group) => group && group.type === Questions.GROUP_SCORED),
    );

  private getProgress(answers: PlayerAnswers, questions: QuestionData[]): number {
    const progress = Object.keys(answers).length > 0 ? questions.map(({ $key }) => answers[$key] != null) : [];
    const answered = progress.filter(Boolean);

    return answered.length / progress.length;
  }

  public onLanguageChanged($event): void {
    this.lm.setLanguage($event.value);
  }

  public onAnswerChanged(rawAnswer: PlayerAnswer | string, interviewerQuestion?: QuestionData): void {
    combineLatest([this.ss.answers, this.ss.cardQuestions])
      .pipe(take(1))
      .subscribe(([answers, questions]) => {
        const answer: PlayerAnswer = !interviewerQuestion
          ? (rawAnswer as PlayerAnswer)
          : ({ value: rawAnswer, item: interviewerQuestion } as PlayerAnswer);
        answers = { ...answers, [answer.item.$key]: answer.value };
        answer.progress = this.getProgress(answers, questions);

        this.ss.answers.next(answers || {});
        this.pi.onAnswerChanged(answer);
        this.cm?.emitAnswerEvent(answer);
      });
  }

  public questionNumber(items, item): string {
    const index = (items || []).findIndex(({ $key }) => $key === item.$key);

    let prefix = '';

    if (index !== -1) {
      const question = item as QuestionData;
      const major = items.slice(0, index + 1).filter((q) => !q.group || Questions.group(q)).length;
      let minor = 0;

      if (question.group && !Questions.group(question)) {
        const groupIndex = items.findIndex(({ $key }) => $key === question.group);

        if (groupIndex !== -1) {
          minor = index - groupIndex;
        }
      }

      prefix = `${major}.${minor ? minor : ''}`;
    }

    return prefix;
  }

  public flagClass(locale: string): string {
    return locale && locale.length !== 2 ? 'zef-lang-other' : `zef-lang-${locale}`;
  }

  public trackByScoredOutcome(idx: number, scored: PlayerOutcome): string {
    return scored.item.$key;
  }

  public onChangeAnswers(): void {
    this.showOutcomes = false;
    this.ss.restartInterval?.unsubscribe();
  }

  public onResetAnswers(): void {
    this.ss.answers.next({});
    this.ss.hiddenAnswers.next({});
    this.ss.initSeen.next();

    this.pi.onResetAnswers();
    this.showOutcomes = false;
    window.scrollTo(0, 0);

    this.cm?.emitRestartEvent();
  }

  public showResults(): void {
    this.ss.setRestartDelay(window.ZEF?.restartDelay, () => this.onResetAnswers());
    this.requiredUnanswered.pipe(first()).subscribe((unAnswered) => {
      if (unAnswered?.length > 0) {
        this.reviewModeOn = true;
      } else {
        this.reviewModeOn = false;
        this.pi.onShowResults();
        this.showOutcomes = true;
        window.scrollTo(0, 0);

        combineLatest([this.ss.answers, this.ss.cardQuestions, this.ss.scoredOutcomes])
          .pipe(take(1))
          .subscribe(([answers, questions, outcomes]) => {
            const progress = this.getProgress(answers, questions);

            this.cm?.emitCompleteEvent(progress, questions, answers, outcomes);
          });
      }
    });
  }

  public exitAccessibility(): void {
    if (location.search && location.search.indexOf('accessibility') >= 0) {
      const path: string =
        window.location.origin + window.location.pathname + this.removeParam('accessibility') + location.hash;
      window.history.pushState(null, null, path);
    }

    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private removeParam(key): string {
    const params = new Map(
      (location.search || '')
        .slice(1)
        .split('&')
        .map((p): any => p.split(/=(.*)/)),
    );
    params.delete(key);
    const search = Array.from(params.entries())
      .map((v) => v[0] + (v[1] ? '=' + v[1] : ''))
      .join('&');

    return search ? '?' + search : '';
  }

  saveSummaries($event, question): void {
    for (const prop in $event || {}) {
      const modifiedQuestionData = JSON.parse(JSON.stringify(question));
      modifiedQuestionData.$key = modifiedQuestionData?.$key + `:${prop}`;

      this.onAnswerChanged({
        value: $event[prop],
        item: modifiedQuestionData,
      });
    }
  }
}
