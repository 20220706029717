import { BehaviorSubject, combineLatest, concat, defer, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';

import { ChangeDetectionStrategy, Component, HostListener, OnChanges, SimpleChanges } from '@angular/core';

import { Question } from '@player/+survey/question/question-base';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { catchError, map, pairwise, shareReplay, startWith, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { PlayerApi } from '@player/shared/services/player-api.service';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';
import { shareRef } from '@shared/operators/share-ref.operator';

enum FileUploadStatus {
  Empty = 1,
  Loading,
  Error,
  Complete,
}

enum FileUploadError {
  Extension = 1,
  MaxSize,
  UploadError,
}

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUpload extends Question implements OnChanges {
  readonly progressWidth = 4;

  readonly progressRadius = 14;

  readonly progressCircumference = this.progressRadius * 2 * Math.PI;

  readonly uploadError = FileUploadError;

  readonly uploadStatus = FileUploadStatus;

  readonly file$ = new BehaviorSubject<File | undefined>(void 0);

  private readonly dragActive$ = new BehaviorSubject<boolean>(false);

  private readonly answer$ = new ReplaySubject<string | null>(1);

  private readonly updateErrorStatus$ = new Subject<FileUploadError>();

  readonly errorStatus$: Observable<FileUploadError | undefined> = merge(
    this.file$.pipe(map((file) => this.validateFile(file))),
    this.updateErrorStatus$,
  ).pipe(shareRef(1));

  readonly uploadProgress$: Observable<number | undefined> = merge(
    defer(() => this.pa.getFileUploadProgress(this.questionData.$key)).pipe(
      tap((progress) => {
        if (progress === -1) {
          this.updateErrorStatus$.next(FileUploadError.UploadError);
        }
      }),
    ),
    this.file$.pipe(
      throttleTime(100),
      switchMap((file) =>
        this.errorStatus$.pipe(
          map((error) => ({ file, error })),
          take(1),
        ),
      ),
      pairwise(),
      switchMap(([previous, next]) => {
        if (!next.file || next.error) {
          this.tryRemoveFile(previous.file);
        }

        return next.file && !next.error ? this.processFile(next.file) : of(void 0);
      }),
      startWith(undefined),
    ),
  ).pipe(shareRef());

  readonly dashOffset$: Observable<number> = this.uploadProgress$.pipe(
    map((progress) => {
      progress = progress || 0;
      progress = progress >= 1 ? 0.25 : progress;

      return this.progressCircumference - progress * this.progressCircumference;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly status$ = combineLatest([this.errorStatus$, this.uploadProgress$, this.answer$]).pipe(
    map(([error, progress, answer]) =>
      error
        ? FileUploadStatus.Error
        : progress != null
          ? FileUploadStatus.Loading
          : answer
            ? FileUploadStatus.Complete
            : FileUploadStatus.Empty,
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly fileName$ = combineLatest([this.answer$, this.file$]).pipe(
    map(([answer, file]) => (file && file.name) || answer || ''),
    map((name) => {
      if (name) {
        return name;
      }

      const upload = this.pa.getFileUpload(this.questionData.$key);

      return (upload && upload.file.name) || '';
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly areaStyle$ = combineLatest([this.dragActive$, this.status$, this.ss.colors]).pipe(
    map(([drag, status, colors]) => ({
      backgroundColor: drag ? colors.primary15 : status === FileUploadStatus.Complete ? colors.primary : colors.mood20,
      color: status === FileUploadStatus.Complete ? colors.primaryParity : colors.text,
    })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private dragLeaveTimer?: number;

  @HostListener('document:drop')
  @HostListener('document:dragend')
  onDrop = () => this.dragActive$.next(false);

  constructor(
    ss: SurveyStore,
    private pa: PlayerApi,
    private tt: TranslateText,
  ) {
    super(ss);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.answer) {
      this.answer$.next(this.answer);
    }
  }

  getMaxSize(): number {
    return this.questionData.fileUpload?.maxSize || 20;
  }

  onDragOver(): void {
    this.dragActive$.next(true);
    clearTimeout(this.dragLeaveTimer);
  }

  onDragLeave(): void {
    clearTimeout(this.dragLeaveTimer);
    this.dragLeaveTimer = window.setTimeout(() => this.dragActive$.next(false), 100);
  }

  onDragExit(): void {
    this.dragActive$.next(false);
  }

  onFileChange(event: Event): void {
    this.dragActive$.next(false);

    const input = event.target as HTMLInputElement;

    this.file$.next(input.files?.[0]);
    input.value = '';
  }

  onClickClose(): void {
    this.file$.next(void 0);
  }

  parseAllowedFormats(): string {
    const formats = this.getAllowedFormats();

    if (formats.includes('application/pdf')) {
      formats.splice(formats.indexOf('application/pdf'), 1);
    }

    return formats
      .map((format) => {
        if (format.endsWith('*')) {
          format = format.replace('/*', '');

          const translation = `file${format[0].toUpperCase() + format.slice(1)}Type`;

          return this.tt.transform(format, translation);
        }

        if (format.includes('/')) {
          return format.substr(format.indexOf('/') + 1);
        }

        return format;
      })
      .sort((a, b) => (a.startsWith('.') ? 1 : -1) - (b.startsWith('.') ? 1 : -1))
      .join(', ');
  }

  private getAllowedFormats(): string[] {
    const formats = this.questionData && this.questionData.fileUpload && this.questionData.fileUpload.allowedFormats;

    return (formats || '')
      .split(',')
      .map((possible) => (possible || '').trim())
      .filter((possible) => !!possible);
  }

  private validateFile(file: File): FileUploadError | undefined {
    if (!file) {
      return;
    }

    if (file.size / 1e6 > this.getMaxSize()) {
      return FileUploadError.MaxSize;
    }

    const formats = this.getAllowedFormats();

    if (!formats.length) {
      return;
    }

    const match = this.getAllowedFormats().some((format) => {
      if (format.endsWith('*')) {
        return file.type.startsWith(format.replace('/*', '/'));
      }

      if (format.includes('/')) {
        return file.type === format;
      }

      if (format.startsWith('.')) {
        return file.name.endsWith(format);
      }

      return false;
    });

    return match ? void 0 : FileUploadError.Extension;
  }

  private processFile(file: File): Observable<number | undefined> {
    const key = (this.questionData && this.questionData.$key) || '';

    return concat(
      this.pa.uploadFileProgress(key, file).pipe(
        tap((progress) => {
          if (progress === 2) {
            this.answerChange.emit(file.name);
          }
        }),
      ),
      of(void 0),
    ).pipe(
      catchError(() => {
        this.updateErrorStatus$.next(FileUploadError.UploadError);
        return of(null);
      }),
    );
  }

  private tryRemoveFile(file: File): void {
    const key = this.questionData && this.questionData.$key;

    if ((!file && !this.answer) || !key) {
      return;
    }

    this.answerChange.emit(null);
    this.pa.triggerDeleteUpload(key);
  }
}
