import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerApi } from '@player/shared/services/player-api.service';
import { AnswersManager } from '@player/shared/services/answers-manager.service';

import { PlayerAnswer } from '@player/shared/models/player.model';

import { QuestionData } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';

@Component({
  selector: 'a-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleFileUpload implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() answerValue: string = '';

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  readonly Questions = Questions;

  public answer: string = '';
  public onProgress: boolean = false;

  get data(): QuestionData {
    return this.questionData || new QuestionData();
  }

  constructor(
    private pa: PlayerApi,
    private am: AnswersManager,
  ) {}

  ngOnChanges() {
    this.answer = this.answerValue || '';
  }

  public onFileChange($event) {
    const input = $event.target as HTMLInputElement;

    if (input && input.files && input.files[0]) {
      this.onProgress = true;
      this.am.fileUploadRaw(this.questionData, input.files && input.files[0]).subscribe((response) => {
        if (response) {
          this.answer = input.files[0].name;
          this.answerChanged.emit({
            value: this.answer,
            item: this.questionData,
          });
        }
        this.onProgress = false;
      });
    }
  }

  public getMaxSize(): number {
    return (this.questionData.fileUpload && this.questionData.fileUpload.maxSize) || 20;
  }
}
