/**
 * Deleted view component with static content.
 *
 * @stable
 */

import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'deleted-view',
  templateUrl: './deleted.component.html',
  styleUrls: ['./deleted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeletedView {
  constructor() {}
}
