<ng-container *ngIf="countryCode$ | async as code">
  <div
    class="z-input-phone"
    [style.border-color]="this.isValid ? (ss.colors | async).primary : invalidColor"
    #inputPhone
  >
    <div
      class="z-input-phone-button z-texts-label"
      [style.background-color]="(ss.colors | async).primaryMood"
      [style.color]="(ss.colors | async).primaryParity"
      (click)="dropdown.toggle()"
    >
      <span class="z-input-phone-flag zef-flag" [ngClass]="'zef-flag-' + code.toLowerCase()"></span>
      <span class="z-input-phone-code">+{{ country[code]?.dial }}</span>
      <mat-icon class="z-input-phone-arrow" fontSet="zefplayer">arrow_down</mat-icon>
    </div>

    <input
      class="z-input"
      type="phone"
      #input
      autocomplete="tel-national"
      [placeholder]="placeholder"
      [value]="phoneNumber$ | async"
      [style.color]="(ss.colors | async).text80"
      (focus)="updateFocus($event)"
      (input)="resetValidity()"
      (change)="updateValidity($event.target.value)"
      (blur)="onPhoneBlur($event)"
    />
  </div>

  <span *ngIf="!isValid" class="z-error-text">
    {{ 'Hmm… The Phone number doesn’t look valid.' | translateText: 'inputPhoneErrorText' }}
  </span>

  <options-dropdown
    [trackByOption]="trackByOption"
    [filterOptions]="filterOptions"
    [attachedTo]="inputPhone"
    [options]="countries"
    [selectedOption]="selectedCountry$ | async"
    (select)="onSelectCountry($event)"
    #dropdown="optionsDropdown"
  >
    <ng-template let-country>
      <span class="z-flag-wrap">
        <span class="z-input-phone-flag zef-flag" [ngClass]="'zef-flag-' + country.code.toLowerCase()"></span>
      </span>
      <span class="z-input-phone-name">{{ country.name }}</span>
      <span class="z-input-phone-code">+{{ country.dial }}</span>
    </ng-template>
  </options-dropdown>
</ng-container>
