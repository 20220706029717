import { delay, take } from 'rxjs/operators';

import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
  SimpleChanges,
  OnChanges,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { NgTemplateOutlet } from '@angular/common';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'options-dropdown',
  exportAs: 'optionsDropdown',
  templateUrl: './options-dropdown.component.html',
  styleUrls: ['./options-dropdown.component.scss'],
})
export class OptionsDropdown<T extends object> implements AfterViewInit, OnChanges, OnDestroy {
  filteredOptions: T[] = [];

  hoverOption?: T;

  isOpen?: boolean;

  private overlayRef?: OverlayRef;

  @ViewChild(CdkPortal)
  picker?: CdkPortal;

  @ViewChild(NgScrollbar)
  sb: NgScrollbar;

  @ViewChild('search')
  search?: ElementRef<HTMLInputElement>;

  @ContentChild(TemplateRef)
  templateRef?: TemplateRef<any>;

  @ViewChildren(NgTemplateOutlet)
  optionOutlets: QueryList<NgTemplateOutlet>;

  @Output()
  readonly open = new EventEmitter<void>();

  @Output()
  readonly close = new EventEmitter<void>();

  @Output()
  readonly select = new EventEmitter<T>();

  @Input()
  selectedOption?: T;

  @Input()
  minShowSearch = 8;

  @Input()
  options: T[] = [];

  @Input()
  attachedTo?: HTMLElement;

  @Input()
  canSearch = true;

  @Input()
  trackByOption: (index: number, option?: T) => string = (index: number) => index.toString();

  @Input()
  filterOptions: (option?: T, term?: string) => boolean = () => true;

  @HostListener('window:resize')
  onWindowResize = (): void => {
    if (this.isOpen && this.overlayRef && this.attachedTo) {
      this.overlayRef.updatePosition();

      this.overlayRef.updateSize({
        width: this.attachedTo.offsetWidth,
      });
    }
  };

  @HostListener('document:keydown.escape')
  onEscape = () => {
    if ((this.isOpen && !this.search) || !this.search.nativeElement.value) {
      this.toggle();
    }
  };

  constructor(
    readonly ss: SurveyStore,
    readonly overlay: Overlay,
  ) {}

  ngAfterViewInit(): void {
    if (!this.attachedTo) {
      return;
    }

    this.overlayRef = this.overlay.create({
      width: this.attachedTo.offsetWidth,
      panelClass: 'zef-survey',
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.attachedTo)
        .withPositions([
          { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
          { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' },
        ])
        .withFlexibleDimensions(true),
    });

    this.overlayRef.backdropClick().subscribe(() => this.toggle());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.onSearchInput((this.search && this.search.nativeElement.value) || '');
    }
  }

  ngOnDestroy(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  onSearchInput(term: string): void {
    this.filteredOptions = term ? this.options.filter((option: T) => this.filterOptions(option, term)) : this.options;
  }

  selectOption(option: T): void {
    this.selectedOption = option;
    this.select.emit(option);
    this.toggle();
  }

  toggle(): void {
    if (!this.overlayRef || !this.attachedTo) {
      return;
    }

    if (this.isOpen) {
      this.closeOptions();
    } else if (this.picker) {
      this.openOptions();
    }
  }

  private closeOptions(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }

    this.close.emit();
    this.isOpen = false;
  }

  private openOptions(): void {
    this.overlayRef.updateSize({
      width: this.attachedTo.offsetWidth,
    });

    this.overlayRef.attach(this.picker);

    this.ss.isMobile.pipe(take(1), delay(1)).subscribe((isMobile) => {
      if (!isMobile && this.search) {
        this.search.nativeElement.focus();
      }
    });

    this.onSearchInput('');

    this.open.emit();
    this.isOpen = true;

    setTimeout(() => {
      if (this.selectedOption && this.sb) {
        const index = this.optionOutlets.toArray().findIndex((outlet) => {
          const ctx = outlet.ngTemplateOutletContext as { $implicit: T };

          return !!ctx && ctx.$implicit === this.selectedOption;
        });

        if (index > -1) {
          const element = this.sb.viewport.nativeElement;
          const item = element.querySelectorAll('.z-option')[index];

          if (item) {
            const scrollRect = element.getBoundingClientRect();
            const contentRect = item.getBoundingClientRect();
            const offset = scrollRect.height / 2;

            const overshoot = Math.max(0, contentRect.bottom - scrollRect.bottom);

            if (overshoot) {
              const top = (this.sb.viewport.scrollTop || 0) + overshoot + offset;
              this.sb.scrollTo({ top, duration: 0 });
            }
          }
        }
      }
    });
  }
}
