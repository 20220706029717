import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { AIInterviewerBase } from '@player/shared/directives/ai-interviewer-base';

@Component({
  selector: 'a-ai-interviewer',
  templateUrl: './ai-interviewer.component.html',
  styleUrls: ['./ai-interviewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleAIInterviewer extends AIInterviewerBase implements OnInit, OnDestroy {
  private changesSub: any;

  constructor(
    readonly sa: SurveyAssistant,
    readonly ss: SurveyStore,
    readonly lm: LanguageManager,
    readonly cdRef: ChangeDetectorRef,
  ) {
    super(sa, ss, lm, cdRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.changesSub = this.rootAnswer.pipe(debounceTime(500), distinctUntilChanged()).subscribe((answer) => {
      if (
        this.sa.rootAnswers?.hasOwnProperty(this.questionData.$key) &&
        this.sa.rootAnswers[this.questionData.$key] !== answer
      ) {
        this.initInterviewer(true, true);
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.changesSub) {
      this.changesSub.unsubscribe();
    }
  }
}
