/**
 * Slider 1D component with labels and values showing.
 *
 * @unstable
 */

import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';

import { Questions } from '@shared/enums/questions.enum';
import { FadeInOut } from '@shared/animations/fade-in-out.anim';
import { SliderLabel } from '@player/shared/services/slider-label.service';
import { SliderNpsBaseComponent } from '@player/+survey/question/slider-nps/slider-nps-base.component';

@Component({
  selector: 'slider-nps',
  templateUrl: './slider-nps-base.component.html',
  styleUrls: ['../slider-abstract/slider-abstract.component.scss', './slider-nps-base.component.scss'],
  providers: [SliderLabel],
  animations: [FadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderNps extends SliderNpsBaseComponent implements OnInit, OnChanges {
  protected readonly questionType: Questions = Questions.SLIDER_NPS;
}
