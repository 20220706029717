<div class="z-fx-col container" [class.smileyMargin]="data.sliderSmileys">
  <div class="z-fx-col labels">
    <div class="axis-label">{{ data.sliderLabelsX.axis | translateText: 'axis-X' : data }}</div>
    <div class="z-fx z-fx-between-center axis-ends">
      <div class="min">{{ data.sliderLabelsX.min | translateText: 'min-X' : data }} ({{ data.sliderValuesX.min }})</div>
      <div class="max">{{ data.sliderLabelsX.max | translateText: 'max-X' : data }} ({{ data.sliderValuesX.max }})</div>
    </div>
  </div>
  <mat-slider
    #xAxis
    class="slider"
    [ngClass]="smiley(xAxis.value, data.sliderValuesX)"
    [min]="data.sliderValuesX.min"
    [max]="data.sliderValuesX.max"
    [step]="data.sliderValuesX.step"
    [value]="answer[0]"
    thumbLabel
    [attr.aria-label]="ariaLabelX"
    (change)="onValueXChanged($event)"
  ></mat-slider>
</div>

<div class="z-fx-col y container" [class.smileyMargin]="data.sliderSmileys">
  <div class="z-fx-col labels">
    <div class="axis-label">{{ data.sliderLabelsY.axis | translateText: 'axis-Y' : data }}</div>
    <div class="z-fx z-fx-between-center axis-ends">
      <div class="min">{{ data.sliderLabelsY.min | translateText: 'min-Y' : data }} ({{ data.sliderValuesY.min }})</div>
      <div class="max">{{ data.sliderLabelsY.max | translateText: 'max-Y' : data }} ({{ data.sliderValuesY.max }})</div>
    </div>
  </div>
  <mat-slider
    #yAxis
    class="slider"
    [ngClass]="smiley(yAxis.value, data.sliderValuesY)"
    [min]="data.sliderValuesY.min"
    [max]="data.sliderValuesY.max"
    [step]="data.sliderValuesY.step"
    [value]="answer[1]"
    thumbLabel
    [attr.aria-label]="ariaLabelY"
    (change)="onValueYChanged($event)"
  ></mat-slider>
</div>
