import { BehaviorSubject, catchError, map, throwError } from 'rxjs';

import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InterviewMessageData } from '@player/shared/models/player.model';

import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { QuestionData } from '@shared/models/survey.model';

import { getLastValue } from '@shared/operators/share-ref.operator';

@Directive()
export class AIInterviewerBase<T = string> implements OnInit {
  @Input() answer: T = null;
  @Input() errorText = 'Error...';
  @Input() isActive = false;
  @Input() placeholder = '';
  @Input() questionData: QuestionData = null;

  @Input()
  set answerValue(value: T | null) {
    this._answerValue = value;

    this.messages =
      value
        ?.toString()
        .split(this.separator)
        .filter(
          (message) =>
            message.length > 12 &&
            (message.substring(0, 12) === 'Interviewee:' || message.substring(0, 12) === 'Interviewer:'),
        )
        .map((message) => {
          return {
            role: message.substring(0, 12) === 'Interviewee:' ? 'user' : 'assistant',
            message: message.slice(12),
          };
        }) || [];

    this.showInput =
      getLastValue(this.rootAnswer) != null &&
      ((this.questionData?.interviewer?.maxQuestions || 5) -
        this.messages.filter((m) => m?.role === 'assistant').length >=
        0 ||
        this.messages.slice(-1)?.[0]?.role === 'assistant');

    this.cdRef.detectChanges();
  }

  get answerValue(): T | null {
    return this._answerValue;
  }

  @Output() answerChange: EventEmitter<string> = new EventEmitter();
  @Output() answering = new EventEmitter<boolean>();
  @Output() answerReady = new EventEmitter<void>();
  @Output() saveSummaries = new EventEmitter<any>();

  private _answerValue: T | null = null;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public messages: InterviewMessageData[] = [];
  public showInput: boolean = true;

  readonly rootAnswer = this.ss.answers.pipe(map((answers) => answers?.[this.questionData?.interviewer?.rootQuestion]));
  readonly rootQuestion = this.ss.questions.pipe(
    map((questions) => questions.find((q) => q.$key === this.questionData?.interviewer?.rootQuestion)),
  );
  readonly separator: string = '\u001D';

  constructor(
    readonly sa: SurveyAssistant,
    readonly ss: SurveyStore,
    readonly lm: LanguageManager,
    readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.sa.interviews?.[this.questionData.$key]) {
      this.initInterviewer(this.messages[this.messages.length - 1]?.role !== 'assistant');
    } else if (
      this.sa.rootAnswers?.hasOwnProperty(this.questionData.$key) &&
      this.sa.rootAnswers[this.questionData.$key] !== getLastValue(this.rootAnswer)
    ) {
      this.initInterviewer(true, true);
    }
  }

  initInterviewer(ask: boolean, reset: boolean = false): void {
    const language: string = this.lm.languages?.[this.lm.currentLanguage]?.name || 'English';

    if (reset) {
      this.messages = [];
    }

    this.loading$.next(true);
    this.ss.disableScroll.next(true);
    this.sa
      .initInterviewer(
        this.questionData,
        getLastValue(this.rootQuestion),
        getLastValue(this.rootAnswer),
        language,
        this.messages,
        ask,
      )
      .pipe(
        catchError((error: any) => {
          this.loading$.next(false);
          this.ss.disableScroll.next(false);
          return throwError(error);
        }),
      )
      .subscribe((result) => {
        const message = result?.[0]?.message;

        if (message) {
          this.messages.push({
            role: message.role,
            message: message.content,
          });
          this.answerChange.emit(
            (!reset ? (this.answerValue || '') + (this.answerValue ? this.separator : '') : '') +
              'Interviewer:' +
              message.content,
          );
        }

        this.loading$.next(false);
        this.ss.disableScroll.next(false);
        this.cdRef.detectChanges();
      });
  }

  getCharsLimit(): number {
    return (this.questionData && this.questionData.charsLimit) || 15000;
  }

  addComment(comment: string): void {
    const actualComment = comment.trim();

    if (actualComment) {
      this.answerChange.emit(
        (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'Interviewee:' + actualComment,
      );

      if (
        (this.questionData?.interviewer?.maxQuestions || 5) -
          this.messages.filter((m) => m?.role === 'assistant').length >=
        0
      ) {
        this.loading$.next(true);
        this.ss.disableScroll.next(true);

        this.sa
          .nextInterviewQuestion(this.questionData, { role: 'user', message: comment })
          .pipe(
            catchError((error: any) => {
              this.loading$.next(false);
              this.ss.disableScroll.next(false);
              return throwError(error);
            }),
          )
          .subscribe((result) => {
            const message = result?.[0]?.message;

            if (message) {
              this.messages.push({
                role: message.role,
                message: message.content,
              });
              this.answerChange.emit(
                (this.answerValue || '') + (this.answerValue ? this.separator : '') + 'Interviewer:' + message.content,
              );
            }

            this.loading$.next(false);
            this.ss.disableScroll.next(false);
            this.cdRef.detectChanges();
          });
      }

      if (
        this.messages.filter((m) => m?.role === 'assistant').length >= 0 &&
        this.messages.filter((m) => m?.role === 'user').length >= 0
      ) {
        const defaulLanguage: string = this.lm.languages?.[this.lm.defaultLanguage]?.name || 'English';
        this.sa.getSummary(this.questionData, defaulLanguage).subscribe((content) => {
          const isJSON: (string) => boolean = (str) => {
            try {
              JSON.parse(str);
            } catch (e) {
              return false;
            }
            return true;
          };
          const parsedContent = isJSON(content?.[0]?.message?.content)
            ? JSON.parse(content?.[0]?.message?.content)
            : null;

          if (parsedContent) {
            this.saveSummaries.emit(parsedContent);
          }
        });
      }
    }
  }
}
