import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { WhyFinderBase } from '@player/shared/directives/why-finder-base';

@Component({
  selector: 'why-finder',
  templateUrl: './why-finder.component.html',
  styleUrls: ['./why-finder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhyFinder extends WhyFinderBase {
  constructor(
    readonly sa: SurveyAssistant,
    readonly ss: SurveyStore,
    readonly lm: LanguageManager,
    readonly cdRef: ChangeDetectorRef,
  ) {
    super(sa, ss, lm, cdRef);
  }
}
