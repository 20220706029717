/**
 * Guard for the online state.
 *
 * @stable
 */

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class OnlineState {
  constructor(
    readonly router: Router,
    readonly route: ActivatedRoute,
  ) {}

  canActivate(): boolean {
    if (window.ZEF && window.ZEF.status !== 'online') {
      this.router.navigate(['/offline']);

      return false;
    }

    return true;
  }
}
