import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, shareReplay, take, takeUntil, timeoutWith } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { PlayerApi } from '@player/shared/services/player-api.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { QuestionInput } from '@player/+survey/question/question-input-base';

import { ClientCountry } from '@shared/tokens/client-country.token';
import { Country, CountryData } from '@shared/enums/countries.enum';

@Component({
  selector: 'input-phone',
  templateUrl: './input-phone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPhone extends QuestionInput implements OnDestroy, OnInit, OnChanges {
  readonly country = Country;
  readonly countries: CountryData[] = Object.keys(this.country).map((code) => ({
    code,
    ...this.country[code],
  }));

  private readonly phoneNumber = new BehaviorSubject<string>('');
  private readonly countryCode = new BehaviorSubject<string>(this.clientCountry);

  readonly phoneNumber$: Observable<string> = this.phoneNumber.pipe(
    map((phoneNumber) => phoneNumber || ''),
    distinctUntilChanged(),
  );

  readonly countryCode$: Observable<string> = this.countryCode.pipe(
    map((countryCode) => (countryCode || this.clientCountry).toUpperCase()),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly selectedCountry$ = this.countryCode$.pipe(
    map((code) => this.countries.find((country) => country.code === code)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<CountryData>;

  @Output()
  readonly waitNext = new EventEmitter<number>();

  private lastAnswer?: string;

  constructor(
    @Inject(ClientCountry) private clientCountry: string,
    private pa: PlayerApi,
    ss: SurveyStore,
  ) {
    super(ss);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(value: string) {
    return this.isValid;
  }

  ngOnInit(): void {
    this.ss.buttonClick
      .pipe(
        mergeMap(() => combineLatest([this.countryCode$, this.phoneNumber$]).pipe(take(1))),
        takeUntil(this.destroy),
      )
      .subscribe(([countryCode, phoneNumber]) => {
        this.onPhoneCheck(countryCode.toLowerCase(), phoneNumber, true, !!(countryCode && phoneNumber));
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.answer) {
      const answer = changes.answer.currentValue;

      let phoneNumber = '';
      let countryCode = '';

      if (answer != null) {
        [countryCode, phoneNumber] = answer.split(';');

        if (!this.country[countryCode.toUpperCase()]) {
          countryCode = '';
          phoneNumber = phoneNumber || countryCode || '';
        }
      }

      this.phoneNumber.next(phoneNumber);
      this.countryCode.next(countryCode);

      this.onPhoneCheck(countryCode, phoneNumber, false);
    }
  }

  ngOnDestroy(): void {
    if (this.isActive && this.isFocused) {
      const value = this.inputField && this.inputField.nativeElement.value;
      this.phoneNumber.next(value);

      this.checkPhone(true);
    }
  }

  resetInput(value: string = null) {
    if (!this.inputField) {
      return null;
    }
    const [countryCode, phoneNumber] = (value || '').split(';');

    this.isAnswered = value != null;

    this.phoneNumber.next(phoneNumber);
    this.countryCode.next(countryCode);
    this.focusInput();
  }

  onPhoneCheck(countryCode: string, phoneNumber: string, submit: boolean = true, ready: boolean = false): void {
    const matches = phoneNumber.match(/\d/g) || [];

    if (countryCode && matches.length > 4) {
      this.waitNext.next(-1);

      this.pa
        .validatePhone(countryCode, phoneNumber)
        .pipe(timeoutWith(3000, of(null)))
        .subscribe((isValid) => {
          this.isValid = isValid == null || isValid;

          if (submit) {
            this.submitAnswer(`${countryCode};${phoneNumber}`, ready);
          }

          this.waitNext.next(this.isValid ? 0 : 1);
        });
    } else {
      this.isValid = !phoneNumber;
      this.waitNext.next(this.isValid ? 0 : 1);

      if (submit) {
        this.submitAnswer(`${countryCode};${phoneNumber}`);
      }
    }
  }

  updateValidity(value: string): void {
    this.phoneNumber.next(value);
  }

  submitAnswer(value: string, ready: boolean = false) {
    if (this.isValid) {
      this.isAnswered = true;
    }

    value = (value || '').trim();

    this.lastAnswer = value;

    this.answerChange.emit(value.endsWith(';') ? null : value);

    if (ready) {
      this.answerReady.next();
    }
  }

  private checkPhone(skipWait?: boolean): void {
    combineLatest([this.countryCode$, this.phoneNumber$])
      .pipe(take(1))
      .subscribe(([countryCode, phoneNumber]) => {
        const newAnswer = `${countryCode};${phoneNumber}`;

        if (newAnswer !== this.lastAnswer) {
          this.submitAnswer(newAnswer);

          if (skipWait) {
            this.pa.validatePhone(countryCode, phoneNumber).subscribe();
          } else {
            this.onPhoneCheck(countryCode, phoneNumber);
          }
        }
      });
  }

  onPhoneBlur(event: FocusEvent): void {
    if (event.relatedTarget instanceof HTMLElement && !event.relatedTarget.classList.contains('z-basic-button')) {
      this.checkPhone();
    }
  }

  onSelectCountry(country: CountryData): void {
    this.countryCode.next(country.code);
    this.checkPhone();
  }

  trackByOption = (idx: number, country: CountryData) => country.code;

  filterOptions = (option: CountryData, query: string) => {
    const fullText = `${option.name} ${option.code} +${option.dial}`.toLocaleLowerCase().split(' ');
    query = query.toLocaleLowerCase();

    return fullText.some((text) => text.includes(query));
  };
}
