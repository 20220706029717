import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';

import { Question } from '@player/+survey/question/question-base';
import { SurveyStore } from '@player/shared/services/survey-store.service';

@Component({
  selector: 'input-info',
  templateUrl: './input-info.component.html',
  styleUrls: ['./input-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputInfo extends Question<any> implements OnChanges {
  constructor(ss: SurveyStore) {
    super(ss);
  }

  ngOnChanges() {
    if (this.isActive && !this.answer) {
      setTimeout(() => this.submitAnswer(true), Question.swiperDelay);
    }
  }

  submitAnswer(value: boolean) {
    if (!this.answer) {
      super.submitAnswer(value);
    }
  }
}
