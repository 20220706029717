/**
 * App routing for the player.
 *
 * @unstable
 */

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PlayerApp } from '@player/player.component';

import { DeletedView } from '@player/+deleted/deleted.component';
import { OfflineView } from '@player/+offline/offline.component';
import { SharingView } from '@player/+sharing/sharing.component';

import { OnlineState } from '@player/shared/guards/online-state.guard';
import { DeletedState } from '@player/shared/guards/deleted-state.guard';
import { IdentityRequiredView } from '@player/+identity-required/identity-required-view.component';

export const ZefRoutes: Routes = [
  { path: 'deleted', component: DeletedView },

  { path: 'offline', component: OfflineView },

  { path: 'identity-required', component: IdentityRequiredView },

  { path: 'sharing', component: SharingView },

  {
    path: ':public',
    canActivate: [DeletedState, OnlineState],
    component: PlayerApp,
    children: [
      {
        path: 'accessible',
        data: { accessible: true },
        loadChildren: () => import('@player/+accessible/accessible.module').then((c) => c.AccessibleModule),
      },
    ],
  },

  // { path: ':public/', redirectTo: ':public' },

  { path: '**', redirectTo: 'demo' },
];

export const ZefRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(ZefRoutes);
