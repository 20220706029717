<!--
  Survey view with different layout modes.

  @unstable
-->

<div
  class="z-container-box z-cover zef-survey"
  ngClass="zef-survey-{{ surveyKey }} z-size-{{ ss.viewSize | async }}"
  [class.z-has-multiple]="hasMultiple | async"
  [ngStyle]="(ss.font | async)?.styles"
  [@surveyState]="ss.viewState | async"
  (@surveyState.start)="disableAnimation.next(true)"
  (@surveyState.done)="onSurveyAnimationDone()"
  (mediaChange)="ss.viewSize.next($event)"
  (elementResize)="onViewResize($event)"
  (dragstart.ncds)="noop()"
  #surveyView
>
  <div
    class="z-cover"
    [style.filter]="(ss.background | async)?.filter | safeStyle"
    [ngStyle]="(ss.background | async)?.styles"
    [style.background-image]="(ss.background | async)?.image | safeStyle"
  ></div>

  <div class="z-cover" [style.background-color]="(ss.background | async)?.brightness"></div>
  <div class="z-cover" [style.background-color]="(ss.colors | async)?.moodOpacity"></div>

  <div
    *ngIf="(hasMultiple | async) === false"
    class="z-cover"
    [style.background-color]="(ss.colors | async)?.moodOpacity"
  ></div>
  <mat-icon
    *ngIf="(ss.hideContent$ | async) === false && (sizeError | async) as error; else loadingOrMain"
    class="z-size-error"
    fontSet="zefplayer"
    [style.color]="(ss.colors | async)?.primary"
  >
    {{ error }}
  </mat-icon>

  <ng-template #loadingOrMain>
    <zef-logo-spinner
      *ngIf="(lm.loadingLanguage | async) || (ss.viewState | async) === states.Loading; else mainContainer"
    ></zef-logo-spinner>
  </ng-template>

  <ng-template #mainContainer>
    <ng-scrollbar class="z-perfect-scroll" [class.z-no-cards]="(hasMultiple | async) === false">
      <div
        class="z-cards-scroll z-fx-col"
        [ngClass]="'z-viewstate-' + (ss.viewState | async)"
        surveyScroller
        [disabledInitialFocus]="disabledInitialFocus"
        [disabled]="
          (ss.disableScroll | async) || cardAnimation.getValue() || (ss.viewState | async) !== states.Questions
        "
        [touchScreen]="touchScreen"
        (scrollUp)="changeIdx(-1)"
        (scrollDown)="changeIdx(1)"
      >
        <div
          class="z-main-container z-fx-auto"
          [class.z-card-outer]="(hasMultiple | async) === false"
          [@.disabled]="disableAnimation | async"
        >
          <div
            class="z-cards-container z-fx-auto"
            [style.overflow.hidden]="cardAnimation.getValue()"
            [style.min-height.px]="(hasMultiple | async) ? (ss.viewRect$ | async)?.height : null"
            [class.z-card]="(hasMultiple | async) === false"
            [ngSwitch]="ss.viewState | async"
            #cardContainer
          >
            <welcome-card
              *ngSwitchCase="states.Welcome"
              [isAnonymous]="isAnonymous"
              [showLanguageSelector]="showLanguageSelector | async"
            ></welcome-card>

            <ng-container *ngSwitchCase="states.Questions">
              <ng-container *ngIf="ss.cardQuestions | async as cardQuestions">
                <ng-container *ngIf="cardQuestions?.length; else noQuestions">
                  <ng-container
                    *ngFor="let question of cardQuestions; trackBy: trackBy$Key; let index = index; let last = last"
                  >
                    <card-scroll
                      *ngIf="(checkedIndex | async) === index"
                      class="z-fx-col z-fx-none"
                      ngClass="z-size-{{ ss.viewSize | async }}"
                      [fixed]="isFixedHeight(question)"
                      [first]="!index"
                      [last]="last"
                      [@cardScroll]="{
                        value: ss.scrollDir | async,
                        params: { height: (ss.viewRect$ | async)?.height },
                      }"
                      (@cardScroll.start)="cardAnimation.next(true)"
                      (@cardScroll.done)="onScrollAnimationDone()"
                    >
                      <div
                        #lb
                        *ngIf="index === 0 && (showLanguageSelector | async)"
                        class="z-language-box z-fx-col z-fx-center-center"
                      >
                        <div
                          class="z-dropdown-preview z-fx z-fx-center-center z-fx-gap-4"
                          [style.color]="(ss.colors | async).moodContrast"
                          (click.s)="isLanguageBoxOpen = true"
                        >
                          <figure [ngClass]="flagClass(lm.currentLanguageCode)"></figure>

                          <span class="z-lang-name" [style.color]="(ss.colors | async).text">
                            {{ lm.currentLanguageName }}
                          </span>
                          <mat-icon
                            fontSet="zefplayer"
                            class="z-dropdown-icon"
                            [style.color]="(ss.colors | async).text"
                          >
                            arrow_down
                          </mat-icon>
                        </div>

                        <div class="z-dropdown-holder z-fx-auto">
                          <div *ngIf="isLanguageBoxOpen" class="z-language-overlay">
                            <div class="z-dropdown-container">
                              <language-select
                                [locales]="lm.languages"
                                [selected]="lm.currentLanguageCode"
                                [clickOutsideActive]="isLanguageBoxOpen"
                                (clickOutside)="isLanguageBoxOpen = false"
                                (select)="isLanguageBoxOpen = false; lm.setLanguage($event)"
                              ></language-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="z-card z-card-inner z-fx-auto"
                        [id]="'zef-question-' + question.$key"
                        [style.background-color]="(ss.colors | async)?.moodOpacity"
                        [class.z-card-fixed]="isFixedHeight(question)"
                        [class.z-card-group]="Questions.info(question)"
                        [class.z-card-archived]="question?.archived"
                      >
                        <span *ngIf="question?.archived" class="z-texts-question">This question is archived.</span>

                        <div #qw class="z-question-wrap" [class.z-fixed]="Questions.slider(question)">
                          <question-card
                            [ngClass]="'z-size-' + (ss.viewSize | async)"
                            [style.height.px]="!isFixedHeight(question) ? null : qw.offsetHeight"
                            [class.z-fixed]="isFixedHeight(question)"
                            [no]="(ss.normalQuestions | async)?.indexOf(question) + 1"
                            [active]="(disableAnimation | async) === false"
                            [isLast]="last"
                            [answerValue]="(ss.answers | async)[question.$key]"
                            [questionData]="question"
                            (nextQuestion)="onNextQuestion()"
                            (prevQuestion)="changeIdx(-1)"
                            (activeChanged)="activeChanged.emit($event)"
                            (answerChanged)="onAnswerChanged($event)"
                            (waitNext)="waitNext = $event === -1 ? question.$key : ''"
                          ></question-card>

                          <ng-container
                            [ngTemplateOutlet]="buttonWrapper"
                            [ngTemplateOutletContext]="{ $implicit: question.$key }"
                          ></ng-container>
                        </div>
                      </div>
                    </card-scroll>
                  </ng-container>
                </ng-container>
                <ng-template #noQuestions>
                  <div class="z-no-items z-fx-col z-fx-between-center z-fx-fill">
                    <div class="z-no-items-header z-fx-col z-fx-center-center z-fx-gap-32">
                      <ng-container *ngIf="ss.logo | async as logo">
                        <ng-container *ngIf="(welcomeCardShown | async) === false; else noLogo">
                          <ng-container *ngIf="logo?.url; else noLogo">
                            <div class="z-no-items-header-logo z-fx-col z-fx-center-center z-fx-flex-center">
                              <img class="z-survey-logo" [src]="logo.url" alt="logo" />
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-template #noLogo>
                          <div class="z-no-items-header-no-logo z-fx-col z-fx-center-center z-fx-flex-center">
                            <mat-icon fontSet="zefplayer" [style.color]="(ss.colors | async).text">
                              placeholder
                            </mat-icon>
                          </div>
                        </ng-template>
                      </ng-container>

                      <span class="z-title-text" [style.color]="(ss.colors | async).text">
                        {{
                          'Looks like there are no open questions at the moment' | translateText: 'noQuestionsAvailable'
                        }}
                      </span>
                    </div>

                    <div class="z-no-items-content z-fx-col z-fx-end-center z-fx-gap-24">
                      <a class="z-zef-logo z-fx z-fx-center-center" href="http://zef.fi" target="_blank">
                        <mat-icon fontSet="zefplayer" [style.color]="(ss.colors | async).text50">
                          zeffi_wordmark
                        </mat-icon>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="states.Outcomes">
              <ng-container *ngIf="hasMultiple | async; then outcomesTmpl; else outcomeTmpl"></ng-container>

              <ng-template #outcomeTmpl>
                <ng-container *ngIf="hasThankYouProperties | async; else genericThankYou">
                  <outcome-card
                    *ngIf="
                      (ss.survey | async).results === Outcomes.GOODBYE
                        ? (ss.thanksOutcome | async)
                        : (ss.scoredOutcomes | async)[0]?.item as outcome
                    "
                    [id]="'zef-outcome-' + outcome.$key"
                    [isFirstOutcome]="true"
                    [isLastOutcome]="true"
                    [outcomeData]="outcome"
                    (newAnswerer)="onResetAnswers()"
                    (changeAnswers)="onShowQuestions()"
                  ></outcome-card>
                </ng-container>
                <ng-template #genericThankYou>
                  <div class="z-no-items z-fx-col z-fx-between-center z-fx-fill">
                    <div class="z-no-items-header z-fx-col z-fx-center-center z-fx-gap-32">
                      <ng-container *ngIf="ss.logo | async as logo">
                        <ng-container *ngIf="logo?.url; else noLogo">
                          <div class="z-no-items-header-logo z-fx-col z-fx-center-center z-fx-flex-center">
                            <img class="z-survey-logo" [src]="logo.url" alt="logo" />
                          </div>
                        </ng-container>
                        <ng-template #noLogo>
                          <div class="z-no-items-header-no-logo z-fx-col z-fx-center-center z-fx-flex-center">
                            <mat-icon fontSet="zefplayer" [style.color]="(ss.colors | async).text">reward</mat-icon>
                          </div>
                        </ng-template>
                      </ng-container>

                      <span class="z-title-text" [style.color]="(ss.colors | async).text">
                        {{ 'Thank you for answering' | translateText: 'genericThankYou' }}
                      </span>
                    </div>

                    <div class="z-no-items-content z-fx-col z-fx-end-center z-fx-gap-24">
                      <a class="z-zef-logo z-fx z-fx-center-center" href="http://zef.fi" target="_blank">
                        <mat-icon fontSet="zefplayer" [style.color]="(ss.colors | async).text50">
                          zeffi_wordmark
                        </mat-icon>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </ng-template>

              <ng-template #outcomesTmpl>
                <ng-container
                  *ngFor="
                    let outcome of ss.scoredOutcomes | async;
                    trackBy: trackByScoredOutcome;
                    let index = index;
                    let last = last
                  "
                >
                  <div
                    class="z-card z-card-inner z-card-outcome"
                    [id]="'zef-outcome-' + outcome.item.$key"
                    [style.background-color]="(ss.colors | async)?.moodOpacity"
                    [@cardScroll]="{ value: ss.scrollDir | async, params: { height: (ss.viewRect$ | async)?.height } }"
                    (@cardScroll.start)="cardAnimation.next(true)"
                    (@cardScroll.done)="onScrollAnimationDone()"
                  >
                    <div class="z-outcome-wrap">
                      <outcome-card
                        [id]="'outcome-' + index"
                        [isFirstOutcome]="index === 0"
                        [isLastOutcome]="last"
                        [ngClass]="'z-size-' + (ss.viewSize | async)"
                        [outcomeData]="outcome.item"
                        (newAnswerer)="onResetAnswers()"
                      ></outcome-card>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>

            <ng-container
              *ngIf="
                (hasMultiple | async) === false &&
                ((ss.viewState | async) !== states.Outcomes || (ss.survey | async)?.results === Outcomes.GOODBYE) &&
                !((ss.viewState | async) === states.Questions && !(ss.cardQuestions | async)?.length)
              "
              [ngTemplateOutlet]="buttonWrapper"
            ></ng-container>

            <div
              *ngIf="hasMultiple | async"
              class="z-card-after"
              [class.z-hidden]="
                cardAnimation.getValue() ||
                (ss.activeIdx | async) ===
                  ((ss.viewState | async) === states.Questions
                    ? (ss.cardQuestions | async)
                    : (ss.scoredOutcomes | async)
                  )?.length -
                    1
              "
              [style.background-color]="(ss.colors | async)?.moodOpacity"
            ></div>
          </div>
        </div>
      </div>
    </ng-scrollbar>

    <zef-funnel *ngIf="ss.showFunnels | async" (answerChanged)="onAnswerChanged($event)"></zef-funnel>

    <div *ngIf="hasMultiple | async" class="z-scrollbar-container z-cover">
      <div class="z-scrollbar">
        <ng-container *ngIf="(ss.isMobile | async) === false" [ngTemplateOutlet]="menuButton"></ng-container>

        <ng-container *ngIf="(ss.viewState | async) === states.Questions">
          <div *ngIf="(ss.isMobile | async) === false" class="z-progress-text z-texts-hint">
            {{ ss.percentCompleted | async | percent }}
          </div>
          <questions-scrollbar
            [index]="ss.activeIdx | async"
            [answers]="ss.answers | async"
            (openMenu)="openMenu()"
            [ngClass]="'z-size-' + (ss.viewSize | async)"
            (change)="setActiveIdx($event)"
          ></questions-scrollbar>

          <div *ngIf="(ss.isMobile | async) === false" class="z-prev-next">
            <ng-container [ngTemplateOutlet]="prevButton"></ng-container>
            <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
          </div>
        </ng-container>

        <outcomes-scrollbar
          *ngIf="(ss.viewState | async) === states.Outcomes"
          [index]="ss.activeIdx | async"
          [ngClass]="'z-size-' + (ss.viewSize | async)"
          (change)="changeOutcome($event)"
        ></outcomes-scrollbar>
      </div>
    </div>

    <drawer-menu
      *ngIf="showMenu"
      class="z-menu-container z-cover z-fx-col"
      [isAnonymous]="isAnonymous"
      (restart)="onResetAnswers()"
      (close)="closeMenu()"
    ></drawer-menu>

    <mat-icon
      *ngIf="(ss.viewState | async) === states.Outcomes"
      fontSet="zefplayer"
      class="z-button-top"
      [class.z-jump]="(ss.activeIdx | async) === 0"
      [style.color]="(ss.colors | async).button"
      [style.background-color]="(ss.colors | async).buttonContrast"
      (click)="onOutcomeArrowClick()"
    >
      {{ (ss.activeIdx | async) ? 'arrow_up' : 'jump_top' }}
    </mat-icon>
    <div
      *ngIf="showButtonContainer | async"
      class="z-fx-col z-fx-center-center"
      [class.z-floating-container]="floatingButtonContainer | async"
      [style.background-color]="(showFloatingContainerBackground | async) ? (ss.colors | async)?.mood : 'transparent'"
    >
      <div
        class="z-button-container"
        [class.center]="floatingButtonContainer | async"
        [style.width]="(hasMultiple | async) ? cardRect?.width + 'px' : '100%'"
      >
        <ng-container *ngIf="showFloatingNavigation | async; else ctaButton">
          <ng-container *ngIf="ss.isMobile | async">
            <ng-container [ngTemplateOutlet]="prevButton"></ng-container>
            <ng-container [ngTemplateOutlet]="menuButton"></ng-container>
            <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
          </ng-container>
        </ng-container>
      </div>

      <ng-container [ngTemplateOutlet]="policyText"></ng-container>
    </div>
  </ng-template>
</div>

<ng-template #buttonWrapper let-$key>
  <div
    *ngIf="!$key || (buttonWrapperItems | async)?.includes($key)"
    class="z-button-wrapper z-fx-col z-fx-gap-8"
    [class.not-visible]="floatingCtaButton | async"
    [style.margin-top.px]="(ss.viewState | async) === states.Welcome ? (ss.viewRect$ | async)?.height * 0.08 : null"
  >
    <ng-container [ngTemplateOutlet]="ctaButton" [ngTemplateOutletContext]="{ $implicit: $key }"></ng-container>
    <ng-container [ngTemplateOutlet]="policyText"></ng-container>
  </div>
</ng-template>

<ng-template #ctaButton let-$key>
  <ng-container [ngTemplateOutlet]="requiredAnswer"></ng-container>
  <ng-container *ngIf="(!$key || (buttonItems | async)?.includes($key)) && button | async as buttonTexts">
    <basic-button
      *ngIf="buttonTexts.text"
      class="z-basic-button"
      [class.z-error]="buttonTexts.text === 'Review'"
      [class.welcome-start-button]="(ss.viewState | async) === states.Welcome"
      [waitNext]="waitNext"
      (next)="onCtaButtonClick()"
    >
      {{ buttonTexts.text | translateText: buttonTexts.translation }}
    </basic-button>
  </ng-container>
  <ng-container [ngTemplateOutlet]="requiredLabel"></ng-container>
</ng-template>

<ng-template #prevButton>
  <button
    class="z-player-button"
    [disabled]="(ss.activeIdx | async) <= 0"
    [style.background-color]="(ss.colors | async).mood"
    [style.color]="(ss.colors | async).button"
    (click)="changeIdx(-1)"
  >
    <mat-icon fontSet="zefplayer">arrow_up</mat-icon>
  </button>
</ng-template>

<ng-template #nextButton>
  <button
    class="z-player-button"
    [disabled]="(ss.activeIdx | async) + 1 >= (ss.cardQuestions | async)?.length"
    [style.background-color]="(ss.colors | async).mood"
    [style.color]="(ss.colors | async).button"
    (click)="changeIdx(1)"
  >
    <mat-icon fontSet="zefplayer">arrow_down</mat-icon>
  </button>
</ng-template>

<ng-template #menuButton>
  <button
    class="z-player-button z-menu-button"
    [style.background-color]="(ss.colors | async)?.mood"
    [style.color]="(ss.colors | async)?.button"
    (click)="openMenu()"
  >
    <mat-icon fontSet="zefplayer">zef_menu</mat-icon>
  </button>
</ng-template>

<ng-template #policyText>
  <div *ngIf="showPrivacyPolicy | async" class="z-policy-container" [style.color]="(ss.colors | async).text80" @waitOut>
    <span>{{ 'By continuing, you agree to Zeffi’s' | translateText: 'privacyPolicyText' }}</span>
    <br *ngIf="ss.isMobile | async" />
    <a [href]="'https://zef.fi/security-and-privacy/' | translateText: 'privacyPolicyLink'" target="_blank">
      {{ 'Privacy Policy' | translateText: 'privacyPolicy' }}
    </a>
  </div>
</ng-template>

<ng-template #requiredAnswer>
  <div *ngIf="showIsRequired | async" class="z-required">
    <span>*&nbsp;</span>
    <span>{{ 'This answer is requested' | translateText: 'requiredQuestion' }}</span>
  </div>
</ng-template>

<ng-template #requiredLabel>
  <div *ngIf="(showUnansweredLabel | async) && (requiredUnanswered | async) as unanswered" class="z-error" @waitOut>
    <ng-container *ngIf="unanswered.length === 1">
      1 {{ 'answer needs completing' | translateText: 'reviewSingle' }}
    </ng-container>

    <ng-container *ngIf="unanswered.length > 1">
      {{ unanswered.length }} {{ 'answers need completing' | translateText: 'reviewMulti' }}
    </ng-container>
  </div>
</ng-template>
