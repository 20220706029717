<div class="z-fx-col container">
  <p
    class="description"
    [innerHTML]="
      questionData?.fileUpload?.description || '' | translateText: 'fileUploadDescription' : questionData | formatText
    "
  ></p>
  <div class="max-size-text">{{ 'Max' | translateText: 'fileUploadMax' }}: {{ getMaxSize() }}MB</div>
  <input
    class="file-upload-input"
    type="file"
    [attr.aria-label]="questionData?.title | translateText: 'title' : questionData"
    [accept]="questionData.fileUpload?.allowedFormats || ''"
    (change)="onFileChange($event)"
    #input
  />

  <div *ngIf="onProgress" class="z-fx z-fx-center-center loading" role="alert" aria-busy="true">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="answer" class="file-exists">✓ {{ answer }}</div>
</div>
