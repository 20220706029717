<!--
  Player menu component

  @unstable
-->

<div class="z-container-box z-fx-col z-fx-between-center z-fx-auto" [style.background-color]="(ss.colors | async).mood">
  <button
    *ngIf="(ss.isMobile | async) === false"
    class="z-close-menu top"
    [style.background-color]="(ss.colors | async)?.button"
    [style.color]="(ss.colors | async)?.buttonContrast"
    (click)="close.emit()"
  >
    <mat-icon fontSet="zefplayer">close</mat-icon>
  </button>

  <div class="z-content-area z-fx-col z-fx-auto z-fx-start-center z-fx-no-shrink">
    <div class="z-content-area-top z-fx z-fx-none z-fx-between-center">
      <div class="z-language-select-container z-fx z-fx-auto z-fx-end-center">
        <div *ngIf="lm.showLanguageSelect()" class="z-language-box z-fx-col z-fx-start-end z-fx-gap-4">
          <div
            class="z-dropdown-preview z-fx z-fx-center-center z-fx-gap-8"
            [style.color]="(ss.colors | async).moodContrast"
            (click.s)="onLanguagesToggle(null)"
          >
            <figure [ngClass]="flagClass(lm.currentLanguageCode)"></figure>

            <span class="z-lang-name" [style.color]="(ss.colors | async).text">{{ lm.currentLanguageName }}</span>
            <mat-icon fontSet="zefplayer" class="z-dropdown-icon" [style.color]="(ss.colors | async).text">
              arrow_down
            </mat-icon>
          </div>

          <div class="z-dropdown-holder z-fx-auto">
            <div *ngIf="selectLanguage" class="z-language-overlay">
              <div class="z-dropdown-container">
                <language-select
                  [locales]="lm.languages"
                  [selected]="lm.currentLanguageCode"
                  [clickOutsideActive]="selectLanguage"
                  (clickOutside)="onLanguagesToggle(false)"
                  (select)="onLanguagesToggle(false); lm.setLanguage($event)"
                ></language-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="{
        settings: (ss.sidebar | async),
        logoUrl: (ss.logo | async)?.url || '',
        teamName: (ss.team | async)?.name || '',
      } as sidebar"
    >
      <div class="z-content-area-main-content z-fx-col z-fx-auto">
        <div
          *ngIf="(sidebar.settings?.teamLogo && sidebar.logoUrl) || (sidebar.settings?.teamName && sidebar.teamName)"
          class="z-content-area-team-info z-fx-col z-fx-none z-fx-center-center z-fx-flex-center"
        >
          <img *ngIf="sidebar.logoUrl" class="z-survey-logo" [src]="sidebar.logoUrl" alt="logo" />

          <div
            *ngIf="sidebar.settings?.teamName && sidebar.teamName"
            class="z-team-name"
            [style.color]="(ss.colors | async).text80"
            [class.no-logo]="!(sidebar.settings?.teamLogo && sidebar.logoUrl)"
          >
            {{ sidebar.teamName | shorten: 200 : '...' }}
          </div>
        </div>

        <div
          *ngIf="sidebar.settings?.sharingButtons || !sidebar.settings?.restartButton"
          class="z-content-area-buttons z-fx-col z-fx-none z-fx-start-center"
        >
          <basic-button
            *ngIf="!sidebar.settings?.restartButton"
            class="z-restart-button"
            [ghostCta]="true"
            (click)="restart.emit(); close.emit()"
          >
            {{ 'Restart' | translateText: 'restartSurvey' }}
          </basic-button>
          <div *ngIf="sidebar.settings?.sharingButtons" class="z-share-buttons-container">
            <share-buttons></share-buttons>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="z-footer z-fx-none z-fx-col z-fx-start-center">
    <div class="z-texts-paragraph" [style.color]="(ss.colors | async).text80">
      <span>Zeffi</span>
      <a
        class="z-terms-link"
        [href]="'https://zef.fi/security-and-privacy/' | translateText: 'privacyPolicyLink'"
        [style.color]="(ss.colors | async).text80"
        target="_blank"
      >
        {{ 'Privacy Policy' | translateText: 'privacyPolicy' }}
      </a>
    </div>
    <button
      *ngIf="(ss.isMobile | async) === true"
      class="z-close-menu bottom"
      [style.background-color]="(ss.colors | async)?.button"
      [style.color]="(ss.colors | async)?.buttonContrast"
      (click)="close.emit()"
    >
      <mat-icon fontSet="zefplayer">close</mat-icon>
    </button>
  </div>
</div>
