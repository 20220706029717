/**
 * Outcome card component with header and content support.
 *
 * @unstable
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Outcomes } from '@shared/enums/outcomes.enum';
import { OutcomeData } from '@shared/models/survey.model';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'outcome-card',
  templateUrl: './outcome-card.component.html',
  styleUrls: ['./outcome-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutcomeCard implements AfterViewInit, OnDestroy {
  readonly types = Outcomes;

  readonly scoredOutcomesAndOptions = combineLatest([this.ss.scoredOutcomes, this.ss.outcomesOptions]);

  readonly outcomeScore = this.scoredOutcomesAndOptions.pipe(
    map(([scoredOutcomes, outcomesOptions]) => (outcomesOptions.score === 'percent' ? scoredOutcomes : [])),
    map((scored) => scored.find(({ item }) => item.$key === ((this.outcomeData && this.outcomeData.$key) || ''))),
    map((outcome) => outcome && outcome.score),
  );

  readonly outcomeRank = this.scoredOutcomesAndOptions.pipe(
    map(([scoredOutcomes, outcomesOptions]) => (outcomesOptions.score === 'rank' ? scoredOutcomes : [])),
    map((ranked) => {
      const showRanked = ranked && ranked.length && ranked.length > 1;

      return !showRanked
        ? null
        : ranked.findIndex(({ item }) => item.$key === ((this.outcomeData && this.outcomeData.$key) || '')) + 1;
    }),
  );

  readonly outcomeCorrect = this.scoredOutcomesAndOptions.pipe(
    map(([scoredOutcomes, outcomesOptions]) => (outcomesOptions.score === 'correct' ? scoredOutcomes : [])),
    map((scored) => scored.find(({ item }) => item.$key === ((this.outcomeData && this.outcomeData.$key) || ''))),
    map((item) => {
      return item?.correct ? `${item.correct.correct}/${item.correct.max}` : null;
    }),
  );

  readonly imageHeight = new ReplaySubject<number>(1);
  readonly imageWidth = new ReplaySubject<number>(1);

  @Input() outcomeData?: OutcomeData;
  @Input() isFirstOutcome: boolean = false;
  @Input() isLastOutcome: boolean = false;

  @Output() newAnswerer = new EventEmitter<void>();
  @Output() changeAnswers = new EventEmitter<void>();

  private ro = new ResizeObserver(([{ target, contentRect }]: ResizeObserverEntry[]) => {
    this.imageWidth.next(contentRect.width);

    while ((target = target.parentElement) && !target.classList.contains('z-perfect-scroll')) {}

    if (target && target instanceof HTMLElement) {
      this.imageHeight.next(target.offsetHeight / 3);
    }
  });

  private sub: any;

  constructor(
    readonly ss: SurveyStore,
    readonly el: ElementRef<HTMLElement>,
  ) {}

  ngAfterViewInit(): void {
    this.ro.observe(this.el.nativeElement);
    if (this.isLastOutcome) {
      this.sub = this.ss.funnelData.subscribe(({ containerHeight }) => {
        const funnelPaddingStyles =
          window?.getComputedStyle(this.el.nativeElement, null).getPropertyValue('padding-bottom') || '';
        const funnelPadding = +funnelPaddingStyles.replace(/\D/g, '') || 0;

        const lastOutcomeWrapper = this.el.nativeElement.parentElement.parentElement as HTMLElement;
        const wrapperPaddingStyles =
          window?.getComputedStyle(lastOutcomeWrapper, null).getPropertyValue('padding-top') || '';
        const wrapperPadding = +wrapperPaddingStyles.replace(/\D/g, '') || 0;

        const paddingBottom = containerHeight - funnelPadding + wrapperPadding;
        lastOutcomeWrapper.style.paddingBottom = `${paddingBottom || 0}px`;
      });

      this.ss.setRestartDelay(window.ZEF?.restartDelay, () => this.openCtaLink('restart'));
    }
  }

  ngOnDestroy(): void {
    this.ro.disconnect();
    this.sub?.unsubscribe();
  }

  showCtaButton() {
    return Boolean(
      this.outcomeData.cta && this.outcomeData.cta.text && (this.outcomeData.cta.action || this.outcomeData.cta.url),
    );
  }

  backgroundImageStyles() {
    return (this.outcomeData && this.outcomeData.image && this.outcomeData.image.getStyles()) || {};
  }

  openCtaLink(actionUrl: string) {
    if (window && actionUrl) {
      if (actionUrl === 'restart') {
        this.newAnswerer.emit();
      } else {
        const safeUrl = actionUrl.startsWith('http') || actionUrl.startsWith('//') ? actionUrl : '//' + actionUrl;

        window.open(safeUrl, '_blank');
      }
    }
  }
}
