import { Subject } from 'rxjs';
import { delay, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ElementRef,
  ViewChild,
  SimpleChanges,
  OnDestroy,
  Directive,
} from '@angular/core';

import { Questions } from '@shared/enums/questions.enum';
import { QuestionData } from '@shared/models/survey.model';

import { SurveyStore } from '@player/shared/services/survey-store.service';

@Directive()
export class Question<T = string> implements OnChanges, OnDestroy {
  static swiperDelay = 200;
  readonly Questions = Questions;

  public invalidColor = '#E20046';
  public isAnswered = false;
  public isValid = true;
  public isFocused = false;

  @Input() isActive = false;
  @Input() placeholder = '';
  @Input() errorText = 'Error...';
  @Input() questionData: QuestionData = null;
  @Input() answer: T = null;

  @Output() answerChange: EventEmitter<T> = new EventEmitter();
  @Output() answering = new EventEmitter<boolean>();
  @Output() answerReady = new EventEmitter<void>();

  @ViewChild('input')
  inputField: ElementRef<HTMLInputElement | HTMLTextAreaElement>;

  private focusTimeout: number = 0;
  protected destroy = new Subject<void>();

  constructor(readonly ss: SurveyStore) {}

  ngOnChanges(changes: SimpleChanges) {
    this.isAnswered = this.answer != null;

    if (changes.isActive) {
      if (changes.isActive.currentValue) {
        this.resetInput(this.answer);
      } else {
        this.blurInput();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  updateFocus({ type }) {
    this.isFocused = type === 'focus';
  }

  blurInput() {
    if (!this.inputField) {
      return null;
    }
    clearTimeout(this.focusTimeout);
    setTimeout(() => this.inputField.nativeElement.blur(), 0);
  }

  focusInput() {
    if (!this.inputField) {
      return null;
    }

    this.ss.isMobile
      .pipe(
        delay(1),
        withLatestFrom(this.ss.scrollDir),
        map(([isMobile, scrollDir]) => !isMobile || (scrollDir > 0 && this.answer == null)),
        take(1),
        takeUntil(this.destroy),
      )
      .subscribe((focus) => {
        if (focus) {
          this.isFocused = true;
          clearTimeout(this.focusTimeout);
          this.focusTimeout = window.setTimeout(() => this.inputField.nativeElement.focus(), Question.swiperDelay);
        }
      });
  }

  resetInput(value: any = null) {
    if (!this.inputField) {
      return null;
    }

    this.updateValidity(value);
    this.isAnswered = value != null;

    if (this.inputField.nativeElement.type !== 'file') {
      this.inputField.nativeElement.value = value != null ? value : '';
    }
    this.focusInput();
  }

  resetAnswer() {
    this.resetInput(null);
    this.answerChange.emit(null);
  }

  submitAnswer(value: T) {
    this.isValid = this.validate(value);

    if (this.isValid) {
      this.isAnswered = true;
      this.answerChange.emit(value);
    }
  }

  // submit answer for <input> and <textarea>
  updateValidity(value: T) {
    // this.isAnswered = false;
    this.isFocused = false;
    this.isValid = value == null || (typeof value === 'string' && value === '') || this.validate(value);

    if (this.isValid && this.answer !== value && ((value as unknown as string) !== '' || this.answer != null)) {
      this.isAnswered = true;
      this.answerChange.emit(value);
    }
  }

  resetValidity() {
    this.isAnswered = false;
    this.isValid = true;
  }

  get preparedAnswer(): T {
    return this.answer;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected validate(value?: any) {
    return true;
  }
}
