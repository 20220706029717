import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';

import { QuestionData } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';

interface OptionItem {
  content: any;
  $key: string;
}

@Component({
  selector: 'a-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleDropdown implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() answerValue: string = '';
  @Input() touchDevice: boolean = false;

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  public answer: string = '';

  get data(): QuestionData {
    return this.questionData || new QuestionData();
  }

  public options: OptionItem[];

  private numericOptions(): OptionItem[] {
    const options = [];
    const min = this.questionData.sliderValues.min;
    const max = this.questionData.sliderValues.max;
    const step = this.questionData.sliderValues.step;

    for (let value = min; value <= max; value = value + step) {
      options.push({ content: value, $key: value.toString() });
    }

    return options;
  }

  constructor() {}

  ngOnChanges() {
    this.options =
      this.questionData.type === Questions.INPUT_NUMERIC ? this.numericOptions() : this.questionData.choiceList;
    this.answer = this.answerValue || '';
  }

  onValueChanged($event) {
    this.answer = $event;
    this.answerChanged.emit({
      value: this.answer,
      item: this.questionData,
    });
  }

  public trackByOption(index: number, item: OptionItem): string {
    return item.$key;
  }
}
