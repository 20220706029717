import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { AIInterviewerBase } from '@player/shared/directives/ai-interviewer-base';

@Component({
  selector: 'ai-interviewer',
  templateUrl: './ai-interviewer.component.html',
  styleUrls: ['./ai-interviewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIInterviewer extends AIInterviewerBase {
  constructor(
    readonly sa: SurveyAssistant,
    readonly ss: SurveyStore,
    readonly lm: LanguageManager,
    readonly cdRef: ChangeDetectorRef,
  ) {
    super(sa, ss, lm, cdRef);
  }
}
