import { Component, ChangeDetectionStrategy, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PlayerAnswer } from '@player/shared/models/player.model';

import { QuestionData } from '@shared/models/survey.model';

@Component({
  selector: 'a-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibleInfo implements OnChanges {
  @Input() questionData?: QuestionData;
  @Input() answerValue: string = '';

  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  constructor() {}

  ngOnChanges() {
    if (this.answerValue === undefined) {
      this.onValueChanged();
    }
  }

  onValueChanged() {
    this.answerChanged.emit({
      value: 'true',
      item: this.questionData,
    });
  }
}
