/**
 * Main module for the player.
 *
 * @stable
 */

import { ErrorHandler, NgModule } from '@angular/core';
import { APP_BASE_HREF, LocationStrategy, PlatformLocation } from '@angular/common';

import { MatIconRegistry } from '@angular/material/icon';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';

import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LOCAL_STORAGE, NgxWebstorageModule, SESSION_STORAGE } from 'ngx-webstorage';
import { NgEventOptionsModule } from 'ng-event-options';

import { ZefErrorHandler } from '@shared/error.handler';

import { PublicModule } from '@player/shared/modules/public.module';

import { StorageLocation } from '@shared/strategies/storage-location.strategy';
import { getLocalStorage, getSessionStorage } from '@shared/utilities/storage.utilities';
import { ZefRouting } from './zef.routing';
import { ZefApp } from './zef.component';

@NgModule({
  bootstrap: [ZefApp],
  declarations: [ZefApp],
  exports: [],
  imports: [
    ZefRouting,

    PublicModule,

    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,

    HttpClientModule,
    HttpClientJsonpModule,

    NgEventOptionsModule,

    NgxWebstorageModule.forRoot({
      prefix: 'zef',
      separator: '.',
    }),
  ],
  providers: [
    ZefErrorHandler,

    {
      provide: 'isPublished',
      useValue: true,
    },

    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },

    {
      provide: ErrorHandler,
      useExisting: ZefErrorHandler,
    },

    {
      provide: LocationStrategy,
      useClass: StorageLocation,
      deps: [APP_BASE_HREF, PlatformLocation],
    },

    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false,
    },
    {
      provide: LOCAL_STORAGE,
      useFactory: getLocalStorage,
    },

    {
      provide: SESSION_STORAGE,
      useFactory: getSessionStorage,
    },
  ],
})
export class ZefModule {
  constructor(registry: MatIconRegistry) {
    registry.registerFontClassAlias('zefplayer', 'zef-icons-free');
    registry.setDefaultFontSetClass('zef-icons-free');
  }
}
