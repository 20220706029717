import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { Component, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';

@Component({
  selector: 'outcomes-scrollbar',
  templateUrl: './outcomes-scrollbar.component.html',
  styleUrls: ['./outcomes-scrollbar.component.scss'],
})
export class OutcomesScrollbar implements AfterViewInit {
  readonly index$ = new BehaviorSubject<number>(0);

  readonly scrollData = combineLatest([
    this.index$.pipe(distinctUntilChanged()),
    this.ss.scoredOutcomes,
    this.ss.isMobile,
  ]).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  readonly steps = this.scrollData.pipe(
    map(([index, outcomes, mobile]) => {
      const length = outcomes.length;
      let start = 0;
      let end = length - 1;

      if (!mobile && length > 7) {
        if (index < 4) {
          end = 5;
        } else if (index >= length - 4) {
          start = end - 5;
        } else {
          start = index - 2;
          end = index + 2;
        }
      }

      const steps: number[] = [];

      while (start <= end) {
        steps.push(start++);
      }

      return steps;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showTopArrow = this.scrollData.pipe(map(([index, outcomes]) => outcomes.length > 7 && index >= 4));

  readonly showBottomArrow = this.scrollData.pipe(
    map(([index, outcomes]) => outcomes.length > 7 && index < outcomes.length - 4),
  );

  @Input() set index(index: number) {
    this.index$.next(index);
  }

  @Output() readonly change = new EventEmitter<number>();

  constructor(readonly ss: SurveyStore) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.gotoIndex(this.index$.value));
  }

  gotoIndex(value: number): void {
    this.change.emit(value);
  }
}
