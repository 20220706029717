import { staticProperties } from '@shared/enums/properties.enum';

export const isNumber = (value?: string): boolean => !value || /^[+-]?([0-9]*[.])?[0-9]+$/.test(value.toString());

export const isEmail = (value: string): boolean =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.(?:[a-zA-Z0-9-.]+)+$/.test(value);

export const isEmptyOrEmail = (value: string): boolean => !value || isEmail(value);

export const isUrl = (value: string): boolean => {
  if (!value) {
    return true;
  }

  if (value.length >= 2083 || /[\s<>]/.test(value) || value.indexOf('mailto:') === 0) {
    return false;
  }

  let parts: string[];

  value = value.split('#')[0].split('?')[0];
  parts = value.split('://');

  if (parts[1]) {
    if (!/^https?$/.test(parts[0])) {
      return false;
    }
  }

  value = parts[1] || parts[0];

  if (/@/.test(value)) {
    return false;
  }

  parts = value.split('.');

  if (parts.some((part) => part.length >= 64)) {
    return false;
  }

  const tld = parts.pop();

  if (!parts.length || !/^([a-z\u00a1-\uffff]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)) {
    return false;
  }

  if (/[\s\u2002-\u200B\u202F\u205F\u3000\uFEFF\uDB40\uDC20]/u.test(tld)) {
    return false;
  }

  return !parts.some((part) => {
    part = part.replace(/_/g, '');

    if (!/^[a-z\u00a1-\uffff0-9-]+$/i.test(part)) {
      return false;
    }

    if (/[\uff01-\uff5e]/.test(part)) {
      return false;
    }

    if (part[0] === '-' || part[part.length - 1] === '-') {
      return false;
    }
  });
};

export const isProperty = (value?: string): boolean =>
  value.startsWith('{') &&
  value.endsWith('}') &&
  (staticProperties.includes(value.replace(/[{}]/g, '') as any) ||
    value.startsWith('{contact_custom_') ||
    value.startsWith('{respondent_field_'));
