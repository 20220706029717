<div class="z-fx-col z-fx-start-center z-fx-gap-16 interviewer-container">
  <div class="z-fx-col z-fx-auto z-fx-gap-8 messages">
    <ng-container *ngFor="let message of messages">
      <div
        *ngIf="message.role === 'assistant' || message.role === 'user'"
        class="message"
        [class.interviewee]="message.role === 'user'"
        [class.interviewer]="message.role === 'assistant'"
        [style.color]="message.role === 'user' ? (ss.colors | async).text80 : (ss.colors | async).text"
      >
        {{ message?.message }}
      </div>
    </ng-container>
  </div>
  <div *ngIf="loading$ | async" class="dot-typing" [style.--text-color]="(ss.colors | async).text80"></div>
  <div
    *ngIf="!messages?.length && (loading$ | async) === false"
    class="z-fx z-fx-center-center"
    [style.color]="(ss.colors | async).text80"
  >
    <mat-icon class="error-icon" fontSet="zefplayer">placeholder</mat-icon>
  </div>
  <div *ngIf="showInput" class="z-fx z-fx-none z-fx-gap-4 input-area">
    <textarea
      class="z-input"
      autocomplete="off"
      autoSize
      [autoSizeMinRows]="1"
      name="free-text"
      [maxLength]="getCharsLimit()"
      #input
      [placeholder]="placeholder || ''"
      [value]="''"
      [style.color]="(ss.colors | async).text80"
      [style.background-color]="(ss.colors | async).mood20"
      (keyup.enter)="addComment(input.value); input.value = ''"
    ></textarea>
    <button
      class="send-button"
      [style.border-color]="(ss.colors | async)?.text"
      [style.background-color]="(ss.colors | async)?.textContrast"
      [style.color]="(ss.colors | async)?.text"
      (click)="addComment(input.value); input.value = ''"
    >
      <mat-icon fontSet="zefplayer">send</mat-icon>
    </button>
  </div>
</div>
