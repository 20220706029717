/**
 * Loader to load data from release data.
 *
 * @unstable
 */

import { Observable, of } from 'rxjs';

import { catchError, map, publishReplay, refCount } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoaderInterface } from '@shared/models/loader.model';

@Injectable()
export class ReleaseLoader implements LoaderInterface {
  private rootPath;

  private cacheMap = new Map<string, Observable<any>>();

  constructor(private http: HttpClient) {}

  hasRootPath(): boolean {
    return this.rootPath != null;
  }

  setRootPath(path: string) {
    this.rootPath = path;
  }

  fetchLocale(lcid: string): Observable<any> {
    if (this.cacheMap.has(lcid)) {
      return this.cacheMap.get(lcid);
    } else {
      const prefix = this.rootPath ? this.rootPath.replace(/\/?$/, '/') : '';

      const revision = window.ZEF ? window.ZEF.revision : Math.random().toString();

      const locale = this.http.get<{ app: any }>(`${prefix}${lcid}.json?${revision}`).pipe(
        map((data) => (data ? data.app : {})),
        catchError(() => of({} as any)),
        publishReplay(1),
        refCount(),
      );

      this.cacheMap.set(lcid, locale);

      return locale;
    }
  }
}
